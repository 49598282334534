import React from 'react';
import { Button } from 'antd';
import { Tooltip } from 'antd';
import i18n from 'i18next';
import { voidFunction } from '../../utils';
import { FATLANGUAGES } from '../../constants';
import s from '../../shared/Dataset/index.module.less';

const FatLanguageButton = ({
  className = '',
  text,
  iconLeft,
  iconRight,
  tooltip,
  tooltipPlacement,
  onClick = voidFunction,
  ref,
  handleSpanClick = voidFunction,
  onKeyDown = voidFunction,
  handleFocus = voidFunction,
  handleBlur = voidFunction,
  ...rest
}) => {
  const ButtonContent = (props) => (
    <span
      tabIndex="0"
      aria-label={props?.content || text}
      onKeyDown={onKeyDown}
      onClick={() => handleSpanClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <Button aria-label={props?.content || text} tabIndex="-1" className={`d-flex align-items-center ${className}`} onClick={onClick} {...rest} role="button">
        {iconLeft && iconLeft}
        {props?.content}
        {iconRight && iconRight}
      </Button>
    </span>
  );

  const trimFunction = (text, count) => {
    return text.slice(0, count) + '...';
  };
  const currentLanguage = i18n.language;
  let charCount = text?.length;

  if (FATLANGUAGES.includes(currentLanguage)) {
    charCount = text?.length / 2;
  }

  return (
    <>
      {text?.length > charCount ? (
        <span
          tabIndex="0"
          aria-label={trimFunction(text, charCount)}
          onKeyDown={onKeyDown}
          onClick={() => handleSpanClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <Tooltip
            title={tooltip ? tooltip : text}
            placement={tooltipPlacement ? tooltipPlacement : 'top'}
            destroyTooltipOnHide={{ keepParent: false }}
          // getPopupContainer={(triggerNode) => triggerNode} WIP : enabling this is causing Tooltip Issues, kindly do no enable
          >
            <div>
              <Button
                aria-label={text}
                ref={ref}
                tabIndex="-1"
                className={`fatbtn d-flex align-items-center ${className}`}
                onClick={onClick}
                role="button"
                {...rest}
              >
                {iconLeft && iconLeft}
                <span className={charCount > 7 ? `text-truncate d-block  ${s.width60}` : ''}>
                  {trimFunction(text, charCount)}
                </span>
                {iconRight && iconRight}
              </Button>
            </div>
          </Tooltip>
        </span>
      ) : tooltip ? (
        <span
          tabIndex="0"
          aria-label={text}
          onKeyDown={onKeyDown}
          onClick={() => handleSpanClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <Tooltip
            title={tooltip}
            placement={tooltipPlacement ? tooltipPlacement : 'top'}
            destroyTooltipOnHide={{ keepParent: false }}
            // getPopupContainer={(triggerNode) => triggerNode} WIP : enabling this is causing Tooltip Issues, kindly do no enable
            className="text-truncate d-block"
          >
            <div>
              <Button
                aria-label={text}
                ref={ref}
                tabIndex="-1"
                className={`fatbtn d-flex align-items-center ${className}`}
                onClick={onClick}
                role="button"
                {...rest}
              >
                {iconLeft && iconLeft}
                <span>{text}</span>
                {iconRight && iconRight}
              </Button>
            </div>
          </Tooltip>
        </span>
      ) : (
        <ButtonContent content={text !== '' && <span>{text}</span>} />
      )}
    </>
  );
};

export default FatLanguageButton;
