import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { t } from 'i18next';
import { FatLanguageButton, Row, Col } from '../../../components';
import { ColumnFilterInput } from './column-filters';
import s from './index.module.less';
import { FILTER_INPUTS, TYPE_CONDITION } from '../../../constants';
import CustomIcon from '../../CustomIcon';
import Conditions from '../../Conditions';
import Context from '../../../context';
import Combined from './Combined';
import AccessibilityButton from '../../Buttons/AccessibiltyButton';

const Filters = (props) => {
  const {
    columns = [],
    source = {},
    exclude = [FILTER_INPUTS.OtherDimensionFilter],
    onlyColumns = false,
    applyModalTo = null,
    onCreatePivot,
    onCustomizeFilter = false,
    hiddenDataColumns = false,
    conditionType = TYPE_CONDITION,
    conditions = [],
    isVisualisation,
  } = props;
  const [context] = useContext(Context);
  const sourceID = source.SourceId;
  const [columnsChecked, setColumnsChecked] = useState(hiddenDataColumns || {});
  const createPivotRef = useRef(null);
  useEffect(() => {
    if (isEmpty(columnsChecked) && columns.length) {
      let allColumns = {};

      columns.forEach((data) => {
        if (!data.isPrimary) allColumns[data.key] = true;
      });

      if (!isEmpty(allColumns)) setColumnsChecked(allColumns);
    }

    // eslint-disable-next-line
  }, [columns]);

  const onColumnApply = (columnsApplied) => {
    setColumnsChecked(columnsApplied);
    if (props.onSelectColumn) {
      let selected = Object.keys(columnsApplied).filter((key) => columnsApplied[key]);
      props.onSelectColumn(selected, columnsApplied);
    }
  };
  const editMergeConfRef = useRef(null);
  let filters = (
    <Fragment>
      <Col>
        <Row gutter={[8, 0]}>
          <Col className={s.filterContainer}>
            <Combined {...props} />
          </Col>
          <Col>
            {isVisualisation ? (
              <Conditions
                conditionType={conditionType}
                columnList={conditions}
                sourceID={sourceID}
                applyModalTo={applyModalTo}
              />
            ) : (
              <Conditions
                conditionType={conditionType}
                columnList={source?.Indicators?.Items || []}
                sourceID={sourceID}
                applyModalTo={applyModalTo}
              />
            )}
          </Col>

          {!exclude.includes(FILTER_INPUTS.ShowHideFilter) ? (
            <Col className="joyride-datasetdata-stepThree">
              <ColumnFilterInput
                data={columns.filter((data) => !data.isPrimary)}
                searchKey="display"
                emptySearchValue={[]}
                placeholder={t('ColumnSearchPlaceholder')}
                onColumnApply={onColumnApply}
                selectedValues={columnsChecked}
                buttonProps={{ className: 'joyride-datasetdata-stepThree' }}
                input="eye"
                reverseSelection={true}
              />
            </Col>
          ) : null}

          {onCustomizeFilter && (
            <FatLanguageButton
              text={t('EditMergeConf')}
              iconLeft={<CustomIcon type={'Edit'} height={14} className="mr-n1 ml-n1" />}
              className=" btn-gray rounded "
              disabled={context.screenContext === 'mobile' || context.screenContext === 'tablet'}
              type="dashed"
              onClick={onCustomizeFilter}
              onKeyDown={(e) => {
                if (e.key === 'Enter') 
                  onCustomizeFilter && onCustomizeFilter();
              }}
              handleSpanClick={() => editMergeConfRef.current && editMergeConfRef.current.click()}
              aria-label={t('EditMergeConf')}
              ref={editMergeConfRef}
            />
          )}
        </Row>
      </Col>

      {onCreatePivot
        ? context.screenContext === 'desktop' && (
            <div className="float-right">
              <AccessibilityButton type="link" onClick={onCreatePivot}
               onKeyDown={(e) => {
                if (e.key === 'Enter') 
                  onCreatePivot && onCreatePivot();
              }}
              handleSpanClick={() => createPivotRef.current && createPivotRef.current.click()}
              aria-label={t('CreatePivot')}
              ref={createPivotRef}>
                <CustomIcon type={'PivotTable'} />
                {t('CreatePivot')}
              </AccessibilityButton>
            </div>
          )
        : null}
    </Fragment>
  );

  if (onlyColumns) {
    return filters;
  }

  return <Row justify="space-between">{filters}</Row>;
};

export default Filters;
