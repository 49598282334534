import React, { Fragment, useMemo, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { Typography, Row, Col, Tooltip, Modal } from '../../../components';
import { STANDERED_FUNCTIONS, TYPE_INDICATORS_CHART, WEIGHTED_AVG } from '../../../constants';
import { useAppliedFilterContext, useFilterContext, useFilterActions } from '../../../providers/FilterContextProvider';
import CustomIcon from '../../CustomIcon';
import DatasetIndicator from '../../DatasetIndicaters';
import mergeStyles from './index.module.less';
import { voidFunction } from '../../../utils';
import { t } from 'i18next';
import DimensionIcon from '../../CustomIcon/DimensionIcon';
import isEmpty from 'lodash/isEmpty';
import AccessibilityButton from '../../Buttons/AccessibiltyButton';
/**
 * WIP : Sorting selected items
 * import Sortable from 'devextreme-react/sortable';
 */

const { Title, Text } = Typography;

const FilterCard = ({ item, selected, removeApplied, isDraging = false, loading = false, merge = false }) => {

  const name = (
    <span
      className="text-capitalize"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(
          `${STANDERED_FUNCTIONS[item.AggregationFunction] || WEIGHTED_AVG[item.AggregationFunction]} of ${item.DisplayName
          }`
        ),
      }}
    ></span>
  );

  return (
    <div>
      {(isDraging && (
        <span className="pt-2">
          <CustomIcon type={'DragIcon'} verticalAlign={4} />
        </span>
      )) ||
        null}
      <div
        className={`${mergeStyles.visualBtnWrapper} mb-1 position-relative pr-3 pl-2 pt-2 pb-2 card dx-card cursor-pointer`}
        style={
          isDraging
            ? {
              display: 'inline-block',
              width: '90%',
            }
            : {}
        }
      >
        <Text ellipsis className="text-dark">
          <DimensionIcon
            colorCode={item.colorIndicator - 1}
            merge={merge}
            type={TYPE_INDICATORS_CHART}
            verticalAlign={3}
            className={`mr-1`}
          />
          {isDraging ? name : <Tooltip title={name}>{name}</Tooltip>}
        </Text>

        <span className="position-absolute right-0" style={{ right: 2, top: 1 }}>
          <CustomIcon
            type={'CloseIcon'}
            verticalAlign={8}
            onClick={() => !loading && removeApplied(selected, item)}
            width={15}
            height={15}
          />
        </span>
      </div>
    </div>
  );
};

const SelectedFilterCard = ({
  selected,
  removeApplied = voidFunction,
  isDraging = false,
  dragingIndex = 0,
  title,
  loading = false,
  merge = false,
}) => {
  const replaceAddRef = useRef(null);
  const [isVisible, setVisible] = useState(false);
  const toggleMore = () => setVisible((ps) => !ps);
  if (!selected?.length) return null;

  let sliced = selected.slice(0, 3);
  let more = selected.slice(3, selected.length).length;

  return (
    <>
      {sliced.map((item, index) => (
        <FilterCard
          key={item.ID}
          selected={selected}
          removeApplied={removeApplied}
          item={item}
          isDraging={isDraging && index === dragingIndex}
          loading={loading}
          merge={merge}
        />
      ))}

      {(more && (
        <>
          <Modal
            role="dialog"
            aria-labelledby={title}
            aria-modal="true"
            visible={isVisible}
            title={title}
            footer={false}
            onCancel={toggleMore}
            style={{ top: 20 }}
            bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <div
              className="pl-4 pr-4"
              style={{
                maxHeight: '75vh',
                overflowY: 'scroll',
              }}
            >
              {selected.map((item, index) => (
                <FilterCard
                  key={item.ID}
                  selected={selected}
                  removeApplied={removeApplied}
                  item={item}
                  isDraging={isDraging && index === dragingIndex}
                />
              ))}
            </div>
          </Modal>
          <AccessibilityButton type="link" size="small" onClick={toggleMore}
            onKeyDown={(e) => {
              if (e.key === 'Enter')
                toggleMore && toggleMore();
            }}
            handleSpanClick={() => replaceAddRef.current && replaceAddRef.current.click()}
            ariaLabel={t('ClickToSeeMore', { more })}
            ref={replaceAddRef}>
            {t('ClickToSeeMore', { more })}
          </AccessibilityButton>
        </>
      )) ||
        null}
    </>
  );
};

const ChartIndicators = ({
  title = '',
  buttonText = '',
  details = {},
  applyModalTo = null,
  allow = false,
  effectiveOnly = false,
  type = TYPE_INDICATORS_CHART,
  uniqueKey,
  loading = false,
  merge = false,
  filterSelectedInputs = [],
}) => {
  const { setFilter } = useFilterContext();
  const replaceAddRef = useRef(null);
  const { getSelectedFilters, getSelectedCount } = useAppliedFilterContext();
  const { removeApplied, removeAppliedCondition } = useFilterActions({ sourceID: details.SourceId, type });

  let selected = getSelectedFilters({ sourceID: details.SourceId, type }) || [];

  if (!Array.isArray(selected)) {
    selected = Object.keys(selected).length ? [selected] : [];
  }

  if (allow) {
    selected = selected.slice(0, allow);
  }

  let isDisabledMode = false;
  if (effectiveOnly) {
    let effectiveRule = Object.entries(effectiveOnly);

    for (let [key, allowed] of effectiveRule) {
      let typeCount = getSelectedCount({ sourceID: details.SourceId, type: key });

      if (typeCount > allowed) {
        isDisabledMode = true;
      }

      if (isDisabledMode) {
        break;
      }
    }
  }

  let allIndicaters = useMemo(() => {
    let indicaterList = details?.Indicators?.Items || [];

    if (filterSelectedInputs?.length) {
      let otherSelectedInputs = {};
      filterSelectedInputs.forEach((avoidType) => {
        if (avoidType !== type) {
          getSelectedFilters({ sourceID: details.SourceId, type: avoidType }).forEach((item) => {
            otherSelectedInputs[item.ID] = false;
          });
        }
      });

      if (!isEmpty(otherSelectedInputs)) {
        indicaterList = indicaterList.filter(({ ID }) => otherSelectedInputs[ID] !== false);
      }
    }
    return indicaterList;
    // eslint-disable-next-line
  }, [details?.Indicators?.Items, filterSelectedInputs, details.SourceId]);

  const isReplace = (allow && selected.length === allow) || allIndicaters.length === selected.length;

  return (
    <Fragment>
      <div className={mergeStyles.axisType} id={`${uniqueKey}-${type}`}>
        <Row>
          <Col span={24} className="mt-2">
            <Title className={'font-16 mb-3'}>{t(title)}</Title>
          </Col>
          <Col span={24}>
            <SelectedFilterCard
              removeApplied={(selected, removeItem) => {
                removeApplied(selected, removeItem);
                removeAppliedCondition(removeItem);
              }}
              selected={selected}
              title={title}
              loading={loading}
              merge={merge}
            />
          </Col>
          {
            <Col span={24} className="mt-2">
              <AccessibilityButton
                className={`${mergeStyles.dashedBtn} d-flex font-weight-bold text-left`}
                type="dashed"
                block
                icon={<CustomIcon type={isReplace ? 'ReplaceIcon' : 'AddIcon'} verticalAlign={3} className="ml-n1" />}
                onClick={() => setFilter({ sourceID: details.SourceId, type })}
                disabled={isDisabledMode || loading}
                onKeyDown={(e) => {
                  if (e.key === 'Enter')
                    setFilter({ sourceID: details.SourceId, type })
                }}
                handleSpanClick={() => replaceAddRef.current && replaceAddRef.current.click()}
                ariaLabel={`${isReplace ? t('Replace') : t('Add')} ${t(buttonText)}`}
                ref={replaceAddRef}
              >
                {`${isReplace ? t('Replace') : t('Add')} ${t(buttonText)}`}
              </AccessibilityButton>
            </Col>
          }

          <DatasetIndicator
            sourceID={details.SourceId}
            indicaters={allIndicaters}
            applyModalTo={applyModalTo}
            sourceName={details.SourceName}
            icon={false}
            allow={allow}
            isGraphIndicater={true}
            type={type}
            merge={merge}
            onApplyClick={(data) => {
              removeAppliedCondition(data, {
                includeOnly: true,
              });
            }}
          />
        </Row>
      </div>
    </Fragment>
  );
};

export default ChartIndicators;
