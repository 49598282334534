import React, { Fragment, useEffect, useState } from 'react';
import { Checkbox } from '../../../components';
import {
  CHART_TYPES,
  TYPE_ALL_DIMENSIONS,
  TYPE_BUBBLE_NAME,
  TYPE_BUBBLE_SIZE,
  TYPE_INDICATORS_CHART,
  TYPE_LOCATION,
  TYPE_X_INDICATER,
  TYPE_Y_INDICATER,
  TYPE_Z_AXSIS_DIMENSIONS,
  TYPE_MAP_HEATMAP,
  TYPE_MAP_BUBBLE,
  TYPE_TIME,
  TYPE_PIVOT_COLUMNS,
  TYPE_DIMENSIONS,
} from '../../../constants';
import ChartIndicators from './ChartIndicators';
import ChartDimensions from './ChartDimensions';
import { voidFunction } from '../../../utils';
import { useFilterActions } from '../../../providers/FilterContextProvider';
import { t } from 'i18next';
import ExtraFilters from './ExtraFilters';
import HelpUserForInput from './HelpUserForInput';

const getViewTypes = () => {
  const charts = {};
  CHART_TYPES.forEach((chart) => {
    if (chart.type) {
      charts[chart.type] = chart.type;
      return { [chart.type]: chart.type };
    }
  });
  return charts;
};

const viewTypes = getViewTypes();

const SwapXToY = ({ swap = voidFunction, title = 'SwitchXAndY', from = TYPE_X_INDICATER, to = TYPE_Y_INDICATER }) => {
  const [isSwitched, setSwitched] = useState(false);

  return (
    <Checkbox
      aria-label={t(title)}
      role="checkbox"
      checked={isSwitched}
      onClick={() => {
        setSwitched((psIsSwitched) => !psIsSwitched);
        swap({ from, to });
      }}
    >
      {t(title)}
    </Checkbox>
  );
};

export const typeConfig = {
  [viewTypes.LineChart]: {
    ChartDimensions: {
      title: 'Xaxis',
      buttonText: 'Dimension',
      allow: 1,
      onlyDimensionType: TYPE_TIME,
      type: TYPE_ALL_DIMENSIONS,
      filterType: TYPE_DIMENSIONS,
      required: true,
    },
    ChartIndicators: {
      title: 'Yaxis',
      buttonText: 'Indicator',
      type: TYPE_INDICATORS_CHART,
      filterType: TYPE_INDICATORS_CHART,
      allow: 10,
      required: true,
    },
    'ChartDimensions-1': {
      title: 'LineSeries',
      buttonText: 'Dimension',
      allow: 1,
      otherThenDimensionType: TYPE_TIME,
      type: TYPE_Z_AXSIS_DIMENSIONS,
      filterType: TYPE_DIMENSIONS,
      effectiveOnly: {
        [TYPE_INDICATORS_CHART]: 1,
      },
    },
  },
  [viewTypes.ColumnChart]: {
    ChartDimensions: {
      title: 'Xaxis',
      buttonText: 'Dimension',
      allow: 2,
      type: TYPE_ALL_DIMENSIONS,
      filterType: TYPE_DIMENSIONS,
      required: true,
    },
    ChartIndicators: {
      title: 'Series',
      buttonText: 'Indicator',
      type: TYPE_INDICATORS_CHART,
      filterType: TYPE_INDICATORS_CHART,
      allow: 10,
      required: true,
    },
  },
  [viewTypes.PieChart]: {
    ChartDimensions: {
      title: 'Label',
      buttonText: 'Label',
      allow: 1,
      type: TYPE_ALL_DIMENSIONS,
      filterType: TYPE_DIMENSIONS,
      required: true,
    },
    ChartIndicators: {
      title: 'Series',
      buttonText: 'Indicator',
      allow: 1,
      type: TYPE_INDICATORS_CHART,
      filterType: TYPE_INDICATORS_CHART,
      required: true,
    },
  },
  [viewTypes.PivotTable]: {
    ChartDimensions: {
      title: 'Rows',
      buttonText: 'Dimension',
      allow: 2,
      type: TYPE_ALL_DIMENSIONS,
      filterType: TYPE_DIMENSIONS,
      required: true,
    },
    ChartIndicators: {
      title: 'Values',
      buttonText: 'Indicator',
      type: TYPE_INDICATORS_CHART,
      allow: 3,
      filterType: TYPE_INDICATORS_CHART,
      required: true,
    },
    'ChartDimensions-0': {
      title: 'Columns',
      buttonText: 'Dimension',
      allow: 1,
      type: TYPE_PIVOT_COLUMNS,
      filterType: TYPE_DIMENSIONS,
    },
  },
  [viewTypes.MapView]: {
    ChartDimensions: {
      title: 'Region',
      buttonText: 'Dimension',
      allow: 1,
      onlyDimensionType: TYPE_LOCATION,
      type: TYPE_ALL_DIMENSIONS,
      filterType: TYPE_DIMENSIONS,
      required: true,
    },
    'ChartIndicators-0': {
      title: 'HeatMap',
      buttonText: 'Indicator',
      type: TYPE_MAP_HEATMAP,
      allow: 1,
      filterType: TYPE_INDICATORS_CHART,
      required: true,
    },
    'ChartIndicators-1': {
      title: 'Bubble',
      buttonText: 'Indicator',
      type: TYPE_MAP_BUBBLE,
      allow: 1,
      filterType: TYPE_INDICATORS_CHART,
    },
    Switch: ({ swap }) => <SwapXToY swap={swap} title={'SwitchHAndB'} from={TYPE_MAP_HEATMAP} to={TYPE_MAP_BUBBLE} />,
  },
  [viewTypes.ScatterChart]: {
    'ChartDimensions-0': {
      title: 'DotName',
      buttonText: 'KeyDimensions',
      allow: 1,
      type: TYPE_BUBBLE_NAME,
      filterType: TYPE_DIMENSIONS,
      required: true,
    },
    'ChartIndicators-0': {
      title: 'Xaxis',
      buttonText: 'Indicator',
      allow: 1,
      type: TYPE_X_INDICATER,
      filterType: TYPE_INDICATORS_CHART,
      required: true,
    },
    'ChartIndicators-1': {
      title: 'Yaxis',
      buttonText: 'Indicator',
      allow: 1,
      type: TYPE_Y_INDICATER,
      filterType: TYPE_INDICATORS_CHART,
      required: true,
    },
    Switch: ({ swap }) => <SwapXToY swap={swap} />,
  },
  [viewTypes.BubbleChart]: {
    'ChartDimensions-0': {
      title: 'BubbleName',
      buttonText: 'KeyDimension',
      allow: 1,
      type: TYPE_BUBBLE_NAME,
      filterType: TYPE_DIMENSIONS,
      required: true,
    },
    'ChartIndicators-0': {
      title: 'Xaxis',
      buttonText: 'Indicator',
      allow: 1,
      type: TYPE_X_INDICATER,
      filterType: TYPE_INDICATORS_CHART,
      required: true,
    },
    'ChartIndicators-1': {
      title: 'Yaxis',
      buttonText: 'Indicator',
      allow: 1,
      type: TYPE_Y_INDICATER,
      filterType: TYPE_INDICATORS_CHART,
      required: true,
    },
    'ChartIndicators-2': {
      title: 'BubbleSize',
      buttonText: 'Indicator',
      allow: 1,
      type: TYPE_BUBBLE_SIZE,
      filterType: TYPE_INDICATORS_CHART,
      required: true,
    },
    Switch: ({ swap }) => <SwapXToY swap={swap} />,
  },
  [viewTypes.TreeMap]: {
    ChartDimensions: {
      title: 'GroupDataBy',
      buttonText: 'Dimension',
      type: TYPE_ALL_DIMENSIONS,
      filterType: TYPE_DIMENSIONS,
      required: true,
    },
    ChartIndicators: {
      title: 'Size',
      buttonText: 'Indicator',
      allow: 1,
      type: TYPE_INDICATORS_CHART,
      filterType: TYPE_INDICATORS_CHART,
      required: true,
    },
  },
};

let filterComponents = { ChartDimensions, ChartIndicators };

const Filter = ({ component, ...rest }) => {
  let Component = filterComponents[component.replace(/-\d+$/, '')];
  return <Component uniqueKey={component} {...rest} />;
};

const LeftChartsList = ({
  selectedChart,
  previousChart = {},
  details = {},
  applyModalTo = null,
  selectedFilters = voidFunction,
  onSelectChartType = voidFunction,
  ...rest
}) => {
  const { type } = selectedChart;
  const { swap } = useFilterActions({ sourceID: details?.SourceId });

  useEffect(() => {
    selectedFilters(typeConfig[type]);

    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    selectedFilters(typeConfig[type]);

    // eslint-disable-next-line
  }, [type]);

  let config = typeConfig[type] || {};
  let typeFilters = Object.entries(config);
  let filters = [];
  let inuseIndicaters = [];

  for (let [, props] of typeFilters) {
    if (props?.filterType === TYPE_INDICATORS_CHART) {
      inuseIndicaters.push(props.type);
    }
  }

  for (let [component, props] of typeFilters) {
    let ActiveComponent = typeof props === 'function' ? props : false;

    filters.push(
      ActiveComponent ? (
        <div key={component}>{ActiveComponent({ details, applyModalTo, swap })}</div>
      ) : (
        <Filter
          key={component}
          details={details}
          applyModalTo={applyModalTo}
          component={component}
          filterSelectedInputs={inuseIndicaters}
          {...props}
          {...rest}
        />
      )
    );
  }

  return (
    <Fragment>
      {filters}
      {type && <ExtraFilters sourceID={details?.SourceId} onSelectChartType={onSelectChartType} />}

      <HelpUserForInput
        selected={selectedChart}
        previous={previousChart}
        sourceID={details.SourceId}
        onSelectChartType={onSelectChartType}
      />
    </Fragment>
  );
};

export default LeftChartsList;
