import React from 'react';
import DOMPurify from 'dompurify';

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import * as Sentry from '@sentry/browser';
// import { Typography, Tag, List, Avatar, Collapse, Spin, message } from '../../components';
import { Typography, Tag, Avatar, Spin, Card } from '../../components';
import { SearchOutlined } from '@ant-design/icons';
// import SearchCollections from './SearchCollections';
import SearchIconType from './SearchIconType';
// import { getCollectionsDetails } from '../../services';
// import { RoutePaths } from '../../routePaths';
import s from './index.module.less';
import { t } from 'i18next';

const { Text, Paragraph } = Typography;
// const { Panel } = Collapse;

export const searchFilters = [
  {
    name: t('Indicators'),
    id: 'indicator',
    key: 'Variables',
  },
  {
    name: t('V_Datasets'),
    id: 'dataset',
    key: 'DatasetInfo',
  },
];

const categoryTypes = {
  dataset: 'dataset',
  collection: 'collection',
  indicator: 'indicator',
  dimensions: 'dimensions',
  q: 'q',
  merge: 'merge',
};

const RenderTitle = ({ item }) => {
  const label = item?._source?.label;
  const hilightText = item?.highlight;
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: hilightText ? DOMPurify.sanitize(`${item?.highlight['text_entry.edgengram'][0]}`) : label ? label : '',
      }}
    ></span>
  );
};

const RenderIcon = ({ category, type }) => {
  if (category === categoryTypes.indicator || category === categoryTypes.dimensions) {
    return (
      <Avatar shape="square" size="small" className={`${s.indicatorIcon} mr-3 d-inline-flex align-items-center`}>
        <SearchIconType path={categoryTypes?.indicator} className={24} />
      </Avatar>
    );
  }
  if (category === categoryTypes.dataset) {
    return (
      <Avatar
        size={type === categoryTypes.merge ? 'default' : 'small'}
        className={`${type === categoryTypes.merge ? 'mr-2' : 'mr-3'} d-inline-flex align-items-center bg-white`}
        icon={<SearchIconType path={category} className={24} />}
      ></Avatar>
    );
  }
  if (category === categoryTypes.q) {
    return <SearchOutlined className={s.icon} />;
  }
  if (category === categoryTypes.collection) {
    return (
      <span className={`${s.collectionIcon} mr-2`}>
        <SearchIconType path={category} size={24} />
      </span>
    );
  }
};

const RenderCategory = ({ item, onSelect, type }) => {
  const category = item?._source?.category;

  const handleClick = (item) => {
    onSelect(item);
  };

  return (
    <div className={`${s.meta} ${s.listItem}`}>
      <Text
        tabIndex={0}
        aria-label={item}
        onClick={() => handleClick(item?._source)}
        className={`${s.textTruncate} w-100`}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleClick(item?._source);
          }
        }}
      >
        <RenderIcon category={category} type={type} />
        <RenderTitle item={item} />
      </Text>
    </div>
  );
};

const SearchPopupContent = ({
  data = [],
  onSelect = () => {},
  onFilterChange = () => {},
  category = 'search',
  width = 45,
  height = 50,
  showFilters = false,
  closePopup = () => {},
  showHeader = false,
  isLoading = false,
}) => {
  // const [collection, setCollection] = useState([]);
  // const [activeKey, setActiveKey] = useState(null);
  // const [loadCollection, setLoadCollection] = useState(true);

  // useEffect(() => {
  //   const searchList = document.getElementById('searchList');
  //   searchList?.scrollTop = 0;
  // }, [data]);

  const filterChange = (filter) => {
    onFilterChange(filter);
  };

  const onSelectItem = (item) => {
    onSelect(item);
  };

  // WIP: need in future
  // const onCollapseChange = async (item) => {
  //   if (activeKey !== item.label) {
  //     setActiveKey(item.label);
  //     setLoadCollection(true);
  //     try {
  //       const { data } = await getCollectionsDetails({ collectionid: item?.objectid });
  //       const { records, IsError = false } = data;
  //       if (IsError) {
  //         message.error(t('FailedFetchCollectionsData'));
  //       } else {
  //         const { _result_data } = records[0];
  //         setCollection(_result_data);
  //         setLoadCollection(false);
  //       }
  //     } catch (e) {
  //       setLoadCollection(false);
  //       message.error(t('FailedFetchCollectionsData'));
  //       Sentry.captureException(`Failed to fetch collection by id, ` + e);
  //     }
  //   } else {
  //     setActiveKey(null);
  //   }
  // };

  // const locale = {
  //   emptyText: <Empty className="text-dark" description={t('NoData')} />,
  // };

  return (
    <div
      className={s.searchWrapper}
      style={{
        width: typeof width === 'string' ? width : `${width}vw`,
      }}
    >
      <Spin size="large" spinning={isLoading}>
        {showHeader && showFilters && (
          <div className={s.recent}>
            <Paragraph key="filterHeading" type="secondary" className={s.spacers}>
              {t('ImLookingFor')}
            </Paragraph>
            <Paragraph key="filterIteration" className={`${s.hint} ${s.spacers} mb-2`}>
              {searchFilters?.length > 0 &&
                searchFilters.map((f) => (
                  <Tag
                    tabIndex={0}
                    aria-label={f.name}
                    icon={<SearchIconType path={f.id} className={'mr-2 ml-2'} size={16} />}
                    key={f.id}
                    className={
                      category === 'header'
                        ? `${s.hintItem} font-14 rounded mb-1`
                        : `${s.hintItem} font-14 rounded mb-2`
                    }
                    onClick={() => filterChange(f)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        filterChange(f);
                      }
                    }}
                  >
                    {f.name}
                  </Tag>
                ))}
            </Paragraph>
          </div>
        )}
        <div className="pb-2">
          {data?.length > 0
            ? data?.map((item) => (
                <Card tabIndex="-1" className={s.list}>
                  <RenderCategory item={item} onSelect={onSelectItem} type={category} />
                </Card>
              ))
            : ''}
          {/* <List
            id="searchList"
            className={s.list}
            bordered={false}
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item className="border-0 p-0" key={`${item?._source?.category}_${index}`}>
                <RenderCategory item={item} onSelect={onSelectItem} type={category} />
              </List.Item>

              // WIP: need in future
              // <List.Item className="border-0 p-0" key={`${item?._source?.category}_${index}`}>
              //   {item?._source?.category === 'collection' ? (
              //     <Collapse expandIconPosition={'right'} ghost className={`${s.collapse} p-0`} activeKey={activeKey}>
              //       <Panel
              //         showArrow={false}
              //         className={s.searchPanel}
              //         header={
              //           <div className={`${s.collection} ${s.listItem}`}>
              //             <Text
              //               className={`${s.textTruncate} ${s.listFont}`}
              //               title={item?._source?.label}
              //               onClick={() => onSelectItem(item?._source)}
              //             >
              //               <span className={`${s.collectionIcon} mr-2`}>
              //                 <SearchIconType path={item?._source?.category} size={24} />
              //               </span>
              //               <RenderTitle item={item} />
              //             </Text>
              //             <span
              //               className={`${s.recommendations} d-flex justify-content-end`}
              //               title={`${item?._source?.noofdatasets} datasets`}
              //               onClick={() => {
              //                 onCollapseChange(item?._source);
              //               }}
              //             >
              //               <Tag
              //                 className={`font-14 border-0 rounded d-flex align-items-center mr-0 ${s.recommendationsText}`}
              //               >
              //                 {item?._source?.noofdatasets} datasets{' '}
              //                 <SearchIconType
              //                   path={'DoubleArrowDownOutlined'}
              //                   size={13}
              //                   className={`ml-1 ${s.doubleArrow}`}
              //                 />
              //               </Tag>
              //             </span>
              //           </div>
              //         }
              //         key={item?._source?.label}
              //       >
              //         <Spin size="large" spinning={loadCollection}>
              //           <SearchCollections
              //             collection={collection}
              //             name={item?._source?.label}
              //             id={item?._source?.objectid}
              //             onSelectItem={onSelectItem}
              //             category={category}
              //           />
              //         </Spin>
              //       </Panel>
              //     </Collapse>
              //   ) : (
              //     <>
              //       {item?._source?.category === 'indicator' && (
              //         <div className={`${s.meta} ${s.listItem}`}>
              //           <Text
              //             className={s.textTruncate}
              //             title={item?._source?.label}
              //             onClick={() => onSelectItem(item?._source)}
              //           >
              //             <Avatar
              //               shape="square"
              //               size="small"
              //               className={`${s.indicatorIcon} mr-3 d-inline-flex align-items-center`}
              //             >
              //               <SearchIconType path={item?._source?.category} size={24} />
              //             </Avatar>
              //             <RenderTitle item={item} />
              //           </Text>
              //           {item?._source?.dataset && (
              //             <Link
              //               className={`${s.recommendations} text-right`}
              //               to={`${RoutePaths.DATASET}/${item?._source?.objectid}`}
              //             >
              //               <span title={item?._source?.dataset}>
              //                 <Tag
              //                   icon={<SearchIconType path={'dataset'} size={16} className="mr-1" />}
              //                   className={`font-20 mr-0 ${s.recommendationsText}`}
              //                 >
              //                   {item?._source?.dataset}
              //                 </Tag>
              //               </span>
              //             </Link>
              //           )}
              //         </div>
              //       )}
              //       {item?._source?.category === 'dimensions' && (
              //         <div className={`${s.meta} ${s.listItem}`}>
              //           <Text
              //             className={s.textTruncate}
              //             title={item?._source?.label}
              //             onClick={() => onSelectItem(item?._source)}
              //           >
              //             <Avatar
              //               shape="square"
              //               size="small"
              //               className={`${s.indicatorIcon} mr-3 d-inline-flex align-items-center`}
              //             >
              //               <SearchIconType path={'indicator'} className={24} />
              //             </Avatar>
              //             <RenderTitle item={item} />
              //           </Text>
              //           {item?._source?.dataset && (
              //             <Link
              //               className={`${s.recommendations} text-right`}
              //               to={`${RoutePaths.DATASET}/${item?._source?.objectid}`}
              //             >
              //               <span title={item?._source?.dataset}>
              //                 <Tag
              //                   icon={<SearchIconType path={'dataset'} size={16} className="mr-1" />}
              //                   className={`font-20 mr-0 ${s.recommendationsText}`}
              //                 >
              //                   {item?._source?.dataset}
              //                 </Tag>
              //               </span>
              //             </Link>
              //           )}
              //         </div>
              //       )}
              //       {item?._source?.category === 'q' && item?._source?.label && (
              //         <div className={`${s.meta} ${s.listItem}`} onClick={() => onSelectItem(item?._source)}>
              //           <Text>
              //             <SearchOutlined className={s.icon} />
              //             <RenderTitle item={item} />
              //           </Text>
              //         </div>
              //       )}
              //       {item?._source?.category === 'dataset' && category === 'merge' && (
              //         <div className={`${s.meta} ${s.listItem}`}>
              //           <Text onClick={() => onSelectItem(item?._source)} className={s.textTruncate}>
              //             <Avatar size="default" className="mr-2 d-inline-flex align-items-center bg-white">
              //               <SearchIconType path={item?._source?.category} className={24} />
              //             </Avatar>
              //             <RenderTitle item={item} />
              //           </Text>
              //           {item?._source?.objectid && (
              //             <Link to={`${RoutePaths.DATASET}/${item?._source?.objectid}`}></Link>
              //           )}
              //         </div>
              //       )}
              //       {item?._source?.category === 'dataset' && category !== 'merge' && (
              //         <div className={`${s.meta} ${s.listItem}`}>
              //           <Text onClick={() => onSelectItem(item?._source)} className={`${s.textTruncate} w-100`}>
              //             <Avatar
              //               size="small"
              //               className="mr-3 d-inline-flex align-items-center bg-white"
              //               icon={<SearchIconType path={item?._source?.category} className={24} />}
              //             ></Avatar>
              //             <RenderTitle item={item} />
              //           </Text>
              //         </div>
              //       )}
              //     </>
              //   )}
              // </List.Item>
            )}
            itemLayout="horizontal"
            locale={locale}
          /> */}
        </div>
      </Spin>
    </div>
  );
};

export default SearchPopupContent;
