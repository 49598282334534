import { t } from 'i18next';
import DOMPurify from 'dompurify';
import { Modal, Button } from '../../../components';
import { TYPE_LOCATION } from '../../../constants';
import { voidFunction } from '../../../utils';
import CustomIcon from '../../CustomIcon';

const ChartWarning = ({
  message = t('ServerError'),
  onClick = voidFunction,
  onClose = voidFunction,
  buttonIcon = 'Filter',
  note = 'ReduceFilters',
  isVisible = true,
  showAction = TYPE_LOCATION,
  applyModalTo,
}) => {
  return (
    <Modal
      role="dialog"
      aria-labelledby={t('ChartWarning')}
      aria-modal="true"
      visible={isVisible}
      getContainer={applyModalTo}
      onCancel={onClose}
      footer={false}
      bodyStyle={{ padding: 0 }}
      style={{ top: 30 }}
    >
      <div className="text-center">
        <div className="p-4 border-bottom">
          <div>
            <CustomIcon type={'ExclamationTriangle'} />
          </div>
          <strong>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}></div>
          </strong>
        </div>
        <div className="p-4 bg-color-light-white">
          {t(note)}
          {showAction ? (
            <div className="mt-3">
              <Button aria-label={t('FilterBy', { type: `$t(${showAction})` })} role="button" className="rounded" onClick={() => onClick(showAction)}>
                <CustomIcon type={buttonIcon} className="ml-n1" verticalAlign={-2} height={16} />
                {t('FilterBy', { type: `$t(${showAction})` })}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default ChartWarning;
