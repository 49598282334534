import { useState, useContext, useRef } from 'react';
import { DownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import Context from '../../../context';
import { Spin, Layout, Affix, Typography, Row, Col, Alert, DatasetName } from '../../../components';
import CustomIcon from '../../CustomIcon';
import DatasetProcessingReport from '../../Hoc/DatasetProcessingReport';
import ReportCard from './ReportCard';
import StarMenu from './StartMenu';
import { checkStandarisation, isBoolean, convertKbToMb } from '../../../utils/common';
import { SEO_META_INFO } from '../../MetaTags/MetaDataInfo';
import Meta from '../../MetaTags/MetaTags';
import DownloadModal from '../../DownloadModal';
import s from './index.module.less';
import AccessibilityButton from '../../Buttons/AccessibiltyButton';

const { Content, Sider, Header } = Layout;

const ReportContainer = ({ sourceID, showReport = true, processingReport = {}, loading = false, source = {} }) => {
  let history = useHistory();
  let [downloading, setDownloading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [fileObj, setFileObj] = useState({});
  const [context, ,] = useContext(Context);
  const MetaInfo = SEO_META_INFO();

  const downloadReport = async (download = false) => {
    const element = document.getElementById('report-container');
    if (!element) {
      console.error('Element not found');
      return;
    }
    setDownloading(true);

    const options = {
      margin: 1,
      filename: `Processing-Report-${sourceID}`,
    };

    try {
      if (isBoolean(download) && download) {
        await html2pdf().from(element).set(options).save();
      } else {
        const pdfBlob = await html2pdf().from(element).set(options).outputPdf('blob');

        setVisible(true);
        setFileObj({ format: pdfBlob.type, name: options.filename, size: `${convertKbToMb(pdfBlob.size)} MB` });
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setDownloading(false);
    }
  };


  const isNotStandarised = !checkStandarisation(source) && !loading;
  const exportReportRef = useRef(null);
  return !showReport ? null : (
    <Spin spinning={processingReport.isFetching || loading}>
      <Meta
        title={`${MetaInfo.processingReport.title}-${source?.SourceName}`}
        description={source?.DatasetInformation || MetaInfo.processingReport.description}
        keywords={`${source?.DatasetCollection}, ${source?.SourceName}, ${MetaInfo.processingReport.keywords}`}
      />
      <DownloadModal show={visible} file={fileObj} download={downloadReport} onCancel={(val) => setVisible(val)} />
      <Layout>
        <Header
          className={`${s.fixedHeader} bg-white border-bottom h-auto pt-5 pb-4 ${context.screenContext === 'mobile' ? 'px-4' : ''
            }`}
        >
          <Row align="middle" className="mt-n2">
            <Col xs={12} md={21} xl={21}>
              <Typography.Text className="font-18 font-weight-bold d-flex ">
                <CustomIcon
                  type={'RightChevron'}
                  onClick={() => history.push(`/dataset/${sourceID}`)}
                  className="mr-1 mt-2"
                  title={t('BacktoDataset')}
                />

                {context.screenContext !== 'mobile' && (
                  <span style={{ lineHeight: 1.4, width: '90%' }}>
                    <DatasetName name={source.SourceName} standarization={source.Standardization} />
                  </span>
                )}
              </Typography.Text>
            </Col>
            <Col xs={12} md={3} xl={3}>
              <AccessibilityButton
                disabled={isNotStandarised}
                icon={<DownloadOutlined />}
                size="large"
                type="primary"
                className="float-right rounded-common commonbtn-dark font-16 font-weight-semi-bold"
                onClick={downloadReport}
                onKeyDown={(e) => {
                  if (e.key === 'Enter')
                    downloadReport && downloadReport();
                }}
                handleSpanClick={() => exportReportRef.current && exportReportRef.current.click()}
                ariaLabel={t('ExportReport')}
                ref={exportReportRef}
              >
                {t('ExportReport')}
              </AccessibilityButton>
            </Col>
            {context.screenContext === 'mobile' && (
              <Col>
                <Typography.Text className="font-18 font-weight-bold d-flex align-items-center mt-4">
                  <CustomIcon type={'Dataset'} verticalAlign={3} width={32} height={32} className="mr-1 ml-1" />
                  {source.SourceName}
                </Typography.Text>
              </Col>
            )}
          </Row>
        </Header>
        <Layout style={{ minHeight: 300 }}>
          {isNotStandarised ? (
            <Content className="bg-white p-5 w-100 overflow-auto text-center ">
              <Alert
                role="alert"
                aria-label={t('DisableDueToNotStandarized', {
                  feature: t('ViewProcessingReport'),
                })}
                style={{ maxWidth: 400, margin: 'auto' }}
                icon={<ExclamationCircleFilled />}
                type="error"
                message={t('DisableDueToNotStandarized', {
                  feature: t('ViewProcessingReport'),
                })}
              />
            </Content>
          ) : (
            <>
              {context.screenContext === 'desktop' && (
                <Sider className="bg-white shadow-sm border-right" width={316}>
                  <Affix offsetTop={10}>
                    <div className="px-5 mx-3 py-5 my-4">
                      <StarMenu data={processingReport.report} />
                    </div>
                  </Affix>
                </Sider>
              )}
              <div id="report-container">
                <Content className="bg-white pr-5 pl-4 pt-4 overflow-auto">
                  <ReportCard data={processingReport.report} sourceID={sourceID} downloading={downloading} />
                </Content>
              </div>
            </>
          )}
        </Layout>
      </Layout>
    </Spin>
  );
};

const ProcessingReport = (props) => DatasetProcessingReport(ReportContainer, props)();

export default ProcessingReport;
