import React, { Fragment, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Row, Col } from '../../../components';
import { voidFunction } from '../../../utils';
import DatasetFilters from '../DatasetFilters';
import s from '../index.module.less';
import VirtualTable from '../../Table/VirtualTable';
import TextSelectedCount from '../DatasetUtils/TextSelectedCount';
import { TYPE_LOCATION, YES } from '../../../constants';
import DataViewRowAndIndicators from '../DatasetUtils/DataViewRowAndIndicators';
import CustomIcon from '../../CustomIcon';
import { SimplePagination } from '../../Pagination';

const DataTab = (props) => {
  const {
    data = [],
    columns = [],
    total = 0,
    pageSize = 100,
    onDatasetPageChange = voidFunction,
    allColumns = [],
    details = {},
    NextPagination = 0,
    currentPage = 1,
  } = props;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [data]);

  return (
    <Fragment>
      <div className={`${s.dataTab}`}>
        <DatasetFilters
          {...props}
          columns={allColumns}
          isStandarized={details?.Standardization?.toUpperCase() === YES}
        />
        <TextSelectedCount type={TYPE_LOCATION} dataIndex={'StateName'} sourceID={details.SourceId} />

        <Row className="mt-2 page-break">
          <Col xs={24} md={12} xl={12}>
            <h5 className={s.textGreyed}>
              <CustomIcon type={'PrimaryKey'} verticalAlign={6} className="color-blue" /> {t('Primary_Key_Dataset')}
            </h5>
          </Col>
          <Col xs={24} md={12} xl={12} className="text-right">
            <DataViewRowAndIndicators
              view={2}
              colorCode={0}
              total={total}
              details={details}
              allIndicators={true}
              indicatorCount={columns.filter(({ isPrimary, isIndicator = false }) => !isPrimary && isIndicator).length}
            />
          </Col>
        </Row>

        <div className="mt-2">
          <VirtualTable
            id="dataset-table"
            pagination={{ pageSize: 100, hideOnSinglePage: true }}
            size="small"
            scroll={{ x: '100%', y: 400 }}
            dataSource={data}
            columns={columns}
            loading={loading}
          />
        </div>
      </div>
      <div className={`pb-4`}>
        <SimplePagination
          records={total}
          limit={pageSize}
          NextPagination={NextPagination}
          onClick={onDatasetPageChange}
          currentPage={currentPage}
        />
      </div>
    </Fragment>
  );
};

export default DataTab;
