import { t } from 'i18next';
import React, { useContext } from 'react';
import { Pagination, Button } from '../../components';
import Context from '../../context';
import { scrollTop } from '../../utils';
import s from './index.module.less';

export const PaginationRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return (
      <Button araia-label={t('PreviousPage')} type="link" className="p-0" size="small">
        &#x3c; {t('Prev')}
      </Button>
    );
  }

  if (type === 'next') {
    return (
      <Button role="button" araia-label={t('NextPage')} type="link" className="p-0" size="small">
        {t('Next')} &#x3e;
      </Button>
    );
  }

  return originalElement;
};

const CommonPagination = (props) => {
  const [context] = useContext(Context);
  const onHandleChange = (page) => {
    props.onChange(page);
    if (!props.disableScroll) scrollTop();
  };

  return (
    <>
      <div className={`${context.screenContext === 'mobile' ? s.commonPaginationMobile : ''} `}>
        <Pagination
          {...props}
          itemRender={PaginationRender}
          disabled={typeof props.loading === undefined ? false : props.loading}
          onChange={onHandleChange}
          responsive={true}
        />
      </div>
    </>
  );
};

export * from './SimplePagination';

export default CommonPagination;
