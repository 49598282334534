import React, { useEffect, useMemo, useState, useRef } from 'react';
import get from 'lodash/get';
import { t } from 'i18next';

import { Row, Col, Checkbox, Popover, Typography, Button, Tooltip, Divider } from '../../../components';
import { CHART_GRIDS_AND_LINES, GridFixedSpan, BLUEBROWN } from '../../../constants';
import { useAppliedFilterContext } from '../../../providers/FilterContextProvider';
import CustomIcon from '../../CustomIcon';
import mergeStyles from './index.module.less';
import { voidFunction } from '../../../utils';
import { typeConfig } from './LeftChartsList';
import AccessibilityButton from '../../Buttons/AccessibiltyButton';

const { Text, Title } = Typography;
const plainOptions = CHART_GRIDS_AND_LINES;

const Pallet = ({ colorPallet, selectedPallet, setSelectedPallet, onlyMapPallet = false }) => {
  let [activePallet, setSelected] = useState(selectedPallet);
  let colors = useMemo(() => {
    let ordered = {};

    Object.keys(colorPallet?.Plotly || {})
      .sort()
      .forEach((key) => {
        ordered[key] = colorPallet?.Plotly[key];
      });

    let pallets = Object.entries(ordered);
    let items = [];

    for (let [name, pallet] of pallets) {
      if ((onlyMapPallet && name.includes('map')) || (!onlyMapPallet && !name.includes('map'))) {
        items.push(
          <Row
            onClick={() => {
              setSelectedPallet(name);
              setSelected(name);
            }}
            key={name}
            className={` ${activePallet === name ? 'active-row' : 'cursor-pointer'} pr-3 pl-3 pt-1 pb-1 hoverable-row`}
          >
            <Col {...GridFixedSpan.size.four} className="d-flex align-items-center">
              {pallet.map((color, key) => (
                <div
                  className={'float-left'}
                  width={pallet.length}
                  style={{ width: `${100 / pallet.length}%`, background: color, height: '70%' }}
                  key={key.toString()}
                ></div>
              ))}
            </Col>
            <Col {...GridFixedSpan.size.eight} className="pl-2 text-capitalize">
              {name}
            </Col>
          </Row>
        );
      }
    }

    return items;

    // eslint-disable-next-line
  }, [colorPallet?.Plotly, activePallet, onlyMapPallet]);

  useEffect(() => {
    setSelected(selectedPallet);
  }, [selectedPallet]);

  return (
    <Col span={24} className="overflow-y-only pallet">
      {colors}
    </Col>
  );
};

const ChartContent = ({
  width = '200',
  sourceID,
  filterSetting = {},
  colorPallet = {},
  selectedPallet = BLUEBROWN,
  setSelectedPallet = voidFunction,
  gridAndLine = [],
  setGridAndLines = voidFunction,
  setSortBy = voidFunction,
  sorted = {},
  type = '',
  onlyMapPallet = false,
  onReset = voidFunction,
  onlyPallet = false,
}) => {
  const resetToDefaultRef = useRef(null);
  const { getSelectedFilters } = useAppliedFilterContext();
  let selected = [];
  let filters = Object.entries(typeConfig[type] || {});

  for (let [key, conf] of filters) {
    if (conf.type) {
      let data = getSelectedFilters({ sourceID, type: conf.type }) || [];
      let slice = get(filterSetting, `${key}.allow`, false);

      if (slice) {
        data = data.slice(0, slice);
      }

      selected = [...selected, ...data];
    }
  }

  if (!selected) return null;

  let sorttedColumn = { ...sorted };

  let sortTypes = {
    asc: <CustomIcon type={'SortAscending'} />,
    desc: <CustomIcon type={'SortDescending'} />,
  };

  return (
    <div style={{ width }} className="popover-inside-container">
      <Row>
        <Col span={24} className="pl-3 pr-3 pt-2">
          <Title className={'font-16'}>{t('SortBy')}</Title>
        </Col>

        {selected.map((item, key) => (
          <Col span={24} key={key} className="pl-3 pr-3 hoverable-row">
            <Row
              className="cursor-pointer d-flex align-items-center"
              onClick={() => {
                setSortBy({
                  ID: item.ID,
                  Description: item.Description,
                  mode: item.ID === sorttedColumn?.ID && sorttedColumn?.mode === 'asc' ? 'desc' : 'asc',
                  type,
                });
              }}
            >
              <Col {...GridFixedSpan.size.ten} key={key.toString()}>
                <Tooltip title={item['Description']} placement="left">
                  <Text className={'font-14 mb-1'} ellipsis>
                    {item['Description']}
                  </Text>
                </Tooltip>
              </Col>
              <Col {...GridFixedSpan.size.two} className="text-right">
                {item.ID === sorttedColumn?.ID ? sortTypes[sorttedColumn?.mode] : null}
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      <Divider className="mt-2 mb-2" />
      <Row>
        <Col span={24} className="pl-3 pr-3 ">
          <Title className={'font-16'}>{t('ColorTheme')}</Title>
        </Col>

        <Col span={24}>
          <Pallet
            onlyMapPallet={onlyMapPallet}
            colorPallet={colorPallet}
            selectedPallet={selectedPallet}
            setSelectedPallet={setSelectedPallet}
          />
        </Col>
      </Row>
      <Divider className="mt-2 mb-2" />

      {!onlyPallet ? (
        <>
          <Row className="pl-3 pr-3 ">
            <Col span={24}>
              <Title className={'font-16'}>{t('GridlinesAndTicks')}</Title>
            </Col>
            <Col span={24}>
              <Checkbox.Group
                className={`${mergeStyles.filterCheckbox} d-flex`}
                options={plainOptions}
                onChange={setGridAndLines}
                value={gridAndLine}
              />
            </Col>
          </Row>

          <Divider className="mt-2 mb-0" />
        </>
      ) : null}

      <AccessibilityButton
        type="link"
        onClick={() => onReset()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onReset && onReset();
        }}
        handleSpanClick={() => resetToDefaultRef.current && resetToDefaultRef.current.click()}
        ariaLabel={t('ResetToDefault')}
        ref={resetToDefaultRef}
      >
        {t('ResetToDefault')}
      </AccessibilityButton>
    </div>
  );
};

const ChartOptions = ({ width = 200, ...props }) => {
  return (
    <>
      <Popover content={<ChartContent width={width} {...props} />} trigger="click" placement="bottomRight">
        <Button
          aria-label={t('ChartOptions')}
          role="button"
          size="small"
          type="link"
          icon={<CustomIcon type={'chartOptions'} verticalAlign={4} className="mr-n1" />}
          className="btn-light-blue position-absolute joyride-datasetvisualizationchart-step2"
          style={{ right: 0, bottom: 10 }}
        >
          {t('ChartOptions')}
        </Button>
      </Popover>
    </>
  );
};

export default ChartOptions;
