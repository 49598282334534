import { InfoCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { Fragment, useState, useContext, useRef } from 'react';
import Context from '../../../context';
import { Modal, Table, FatLanguageButton } from '../../../components';
import CloseButton from '../../CloseButton';
import CustomIcon from '../../CustomIcon';

const MatchTypes = ({ downloading = false }) => {
  const [context, ,] = useContext(Context);
  const [isVisible, setIsVisible] = useState(false);
  const toggle = () => setIsVisible((ps) => !ps);
  const knowMoreRef = useRef(null);
  return (
    <Fragment>
      {!downloading ? (
        <FatLanguageButton
          text={t('KnowMore')}
          iconLeft={<InfoCircleOutlined className="mr-n1" />}
          onClick={toggle}
          className="btn-primary-ghost rounded"
          size="small"
          data-html2canvas-ignore="true"
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              toggle && toggle();
          }}
          handleSpanClick={() => knowMoreRef.current && knowMoreRef.current.click()}
          aria-label={t('KnowMore')}
          ref={knowMoreRef}
        />
      ) : null}
      <Modal
        role="dialog"
        aria-labelledby={t('MatchTypes')}
        aria-modal="true"
        wrapClassName="overflow-auto"
        closeIcon={<CloseButton />}
        style={{ top: 40 }}
        visible={isVisible}
        title={t('MatchTypes')}
        onCancel={toggle}
        footer={false}
        width={context.screenContext === 'mobile' ? '95%' : '60%'}
      >
        <Table
          role="table"
          aria-label={t('TableData')}
          pagination={false}
          columns={[
            {
              title: t('Mapping'),
              dataIndex: 'Mapping',
              key: 'Mapping',
              width: 200,
            },
            {
              title: t('Depiction'),
              dataIndex: 'Depiction',
              key: 'Depiction',
              render: (value) => <CustomIcon type={value} width={70} height={40} key={value} />,
            },
            {
              title: t('Description'),
              dataIndex: 'Description',
              key: 'Description',
            },
          ]}
          dataSource={[
            {
              Mapping: t('Mapping-1'),
              Description: t('MatchTypes-Description-1'),
              Depiction: 'DepictionOne',
              key: 'DepictionOne',
            },
            {
              Mapping: t('Mapping-2'),
              Description: t('MatchTypes-Description-2'),
              Depiction: 'DepictionMany',
              key: 'DepictionMany',
            },
            {
              Mapping: t('Mapping-3'),
              Description: t('MatchTypes-Description-3'),
              Depiction: 'DepictionManyToOne',
              key: 'DepictionManyToOne',
            },
            {
              Mapping: t('Mapping-4'),
              Description: t('MatchTypes-Description-4'),
              Depiction: 'DepictionManyToMany',
              key: 'DepictionManyToMany',
            },
          ]}
        />
      </Modal>
    </Fragment>
  );
};

export default MatchTypes;
