import React, { useContext, useRef, useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { Row, Col, Button, Tooltip } from 'antd';
import { LeftOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import startCase from 'lodash/startCase';
import { t } from 'i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Checkbox,
  Dropdown,
  Menu,
  Modal,
  Form,
  Input,
  Select,
  Spin,
  message,
  FatLanguageButton,
  Divider,
  Typography,
} from '../../components';
import Pool from './userPool';
import Context from '../../context';
import EditProfile from '../../pages/Profile/EditProfile';
import * as constants from '../../constants';
import { voidFunction, getObjectEntries } from '../../utils';
import { Captcha, CloseButton } from '../../shared';
import { RoutePaths } from '../../routePaths';
import { postClickStreamEvent } from '../../services';

import s from './index.module.less';
import LoginPrompt from './LoginPrompt';
import AccessibilityButton from '../Buttons/AccessibiltyButton';
import CustomLink from '../Accessibility/Link';
import { AriaLiveTag } from '../../components';

const { Option } = Select;

const Login = ({ avatar = true, closeDrawer }) => {
  const [showEditProfile, setEditProfile] = React.useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState(false);
  const userEmail = useRef('');
  const [flow, setFlow] = useState('login');
  const [context, setContext] = useContext(Context);
  const [loginForm] = Form.useForm();
  const [signupForm] = Form.useForm();
  const [forgotForm] = Form.useForm();
  const [confirmForm] = Form.useForm();
  const initialForgotFormState = { email: '', code: '' };
  const [forgotFormValues, setForgotFormValues] = useState(initialForgotFormState);
  const initialConfirmFormState = { email: '', code: '' };
  const [confirmFormValues, setConfirmFormValues] = useState(initialConfirmFormState);
  const initialForgotFormTitle = 'ForgotPassword';
  const [forgotPasswordTitle, setForgotPasswordTitle] = useState(initialForgotFormTitle);
  const [captchaCode, setCaptchaCode] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [captchaKey, setCaptchaKey] = useState(Date.now());
  const [verificationLink, setVerificationLink] = useState(false);
  const loginRef = useRef(null);
  const signupRef = useRef(null);
  const forgotRef = useRef(null);
  const confirmRef = useRef(null);
  const logoutRef = useRef(null);
  const forgotPasswordTitleRef = useRef(null);
  const verificationCodeRef = useRef(null);
  const [statusMessage, setStatusMessage] = useState('');

  let history = useHistory();

  const handleCancelLogin = () => {
    if (params.q === 'reset') return null;
    setForgotFormValues({ ...initialForgotFormState });
    setForgotPasswordTitle(initialForgotFormTitle);
    forgotForm.setFieldsValue({ ...initialForgotFormState });
    setContext((state) => ({ ...state, loggedInCallback: voidFunction, showLogin: false, afterLoginRedirect: false }));
    setFlow('login');
    setVerificationCode(false);
    setVisible(false);
    setIsDisabled(false);
    loginForm.resetFields();
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = getObjectEntries(urlSearchParams);

  useEffect(() => {
    if (params.q === 'confirm') {
      setConfirmFormValues({ email: params.userName, code: params.confirmation_code });
      setForgotPasswordTitle(t('VerificationCode'));
      setFlow('confirm');
      setVisible(true);
    } else if (params.q === 'reset') {
      setForgotFormValues({ email: params.userName, code: params.confirmation_code });
      setForgotPasswordTitle(t('VerificationCode'));
      setVerificationCode(params.confirmation_code);
      setFlow('forgot');
      setVisible(true);
    } else if (params.q === 'signup') {
      setFlow('signup');
      setVisible(true);
    } else {
      setVisible(context.showLogin);
    }
    // eslint-disable-next-line
  }, [history, context.showLogin]);

  useEffect(() => {
    if (context.afterLoginRedirect && context.isAuth) {
      setContext((state) => ({ ...state, afterLoginRedirect: false, showLogin: false }));
      history.push(context.afterLoginRedirect);
      setVisible(false);
    }
    if (context.isAuth) setVisible(false);
  }, [context.afterLoginRedirect, context.isAuth, setContext, history]);

  const updateStatusMessage = (message) => {
    setStatusMessage('');
    setTimeout(() => setStatusMessage(message), 100);
  };

  const authenticate = async (Username, Password, userAttributes) => {
    setLoading(true);
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username: Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password, ValidationData: userAttributes });
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
          loginForm.setFieldsValue({ password: '' });
          handleResetCaptcha();
        },
        // WIP
        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });
  };

  const authenticateHandler = async (values) => {
    setLoading(true);
    const userAttributes = { captchaResponse: captchaCode };
    try {
      const data = await authenticate(values.username, values.password, userAttributes);

      setContext((state) => {
        const newState = {
          ...state,
          isAuth: true,
          profile: data.idToken.payload,
          token: data.idToken.jwtToken,
          isLoading: false,
        };
        return newState;
      });

      sessionStorage.setItem('token', data.idToken.jwtToken);
      setVisible(false);
      message.success(t('LoginSuccess'));
      updateStatusMessage(t('LoginSuccess'));
      loginForm.resetFields();
      var userback = window.Userback;
      if (userback) {
        userback.name = data.idToken.payload.name;
        userback.email = data.idToken.payload.email;
        var widget_settings = {
          categories: [
            'Incorrect search results',
            'Data not available',
            'In-appropriate visualization',
            'In-appropriate analysis',
            'Data Error',
            'Dataset',
            'Other',
          ],
          main_button_text: t('feedback'),
          main_button_background_colour: '#3E3F3A',
          main_button_text_colour: '#FFFFFF',
          name_field: data.idToken.payload.name,
          email_field: data.idToken.payload.email,
        };
        userback.setName?.(data.idToken.payload.name);
        userback.setEmail?.(data.idToken.payload.email);
        userback.setWidgetSettings?.(widget_settings);
      }

      postClickStreamEvent({
        activity: constants.ACTIVITIES.USER_LOGIN,
        userid: values.username,
        userdetails: values.username,
      });
    } catch (err) {
      console.log('Login Failure: ', err);
      if (err.message.includes('Incorrect username or password')) {
        message.error(`${t('LoginFailure')} ${t('IncorrectUsernameOrPassword')}`);
        updateStatusMessage(`${t('LoginFailure')} ${t('IncorrectUsernameOrPassword')}`);
      } else {
        message.error(`${t('LoginFailure')} ${err}`);
        updateStatusMessage(`${t('LoginFailure')} ${err}`);
      }
      // if (err.message.includes('Incorrect username or password'))
      //   message.error(`${t('LoginFailure')} ${t('IncorrectUsernameOrPassword')}`);
      // else message.error(`${t('LoginFailure')} ${err}`);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    context.logoutFn();
  };
  const splitCaptcha = (captchaCode) => {
    const part1 = captchaCode.slice(0, constants.MAX_CAPTCHA_LENGTH);
    const part2 = captchaCode.slice(constants.MAX_CAPTCHA_LENGTH, constants.MAX_CAPTCHA_LENGTH * 2);

    return [part1, part2];
  };
  const createUser = (values) => {
    setLoading(true);
    // WIP
    const [captchaPart1, captchaPart2] = splitCaptcha(captchaCode);
    const userAttributes = [
      { Name: 'name', Value: values.name },
      { Name: 'email', Value: values.email },
      { Name: 'family_name', Value: ' ' },
      { Name: 'picture', Value: captchaPart2 ? captchaPart2 : ' ' },
      { Name: 'custom:UserType', Value: values.type },
      { Name: 'custom:captchaResponse', Value: captchaPart1 },
    ];

    Pool.signUp(values.email, values.password, userAttributes, [], (err, _) => {
      if (err) {
        if (err.message.toString().includes('Code:2')) {
          message.error(`${t('UserCreationFailure2')}`);
          updateStatusMessage(t('UserCreationFailure2'));
        } else if (err.message.toString().includes('Code:1')) {
          message.error(`${t('UserCreationFailure1')}`);
          updateStatusMessage(t('UserCreationFailure1'));
        }
        if (err.toString().includes('UsernameExistsException')) {
          message.error(`${t('UserCreationFailure')} ${t('AccountExists')}`);
          updateStatusMessage(`${t('UserCreationFailure')} ${t('AccountExists')}`);
        } else {
          message.error(`${t('UserCreationFailure')} ${err}`);
          updateStatusMessage(`${t('UserCreationFailure')} ${err}`);
        }
      } else {
        postClickStreamEvent({
          activity: constants.ACTIVITIES.USER_REGISTRATION,
          userid: values.email,
          userdetails: values.email,
        });
        setVerificationLink(true);
        // message.info(t('VerificationLinkToEmail'));
        updateStatusMessage(t('VerificationLinkToEmail'));
        setTimeout(() => {
          setVisible(false);
        }, 700);
        signupForm.resetFields();
        setFlow('login');
      }
      setLoading(false);
    });
  };

  const forgotPasswordHandler = async (values) => {
    setLoading(true);
    if (!verificationCode) {
      try {
        await forgotPasswordFlow(values.email);
        message.info(t('VerificationCodeSent'));
        userEmail.current = values.email;
        setForgotFormValues({ email: values.email, code: '' });
        forgotForm.setFieldsValue({ email: values.email, code: '' });
        setVerificationCode(true);
        updateStatusMessage(t('VerificationCodeSent'));
      } catch (err) {
        message.error(`${t('VerificationCodeFailure')} ${err}`);
        updateStatusMessage(`${t('VerificationCodeFailure')} ${err}`);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const user = params.userName ? params.userName : userEmail.current;
        await resetPasswordFlow(user, values.code, values.password);
        message.success(t('PasswordReset'));
        updateStatusMessage(t('PasswordReset'));
        setFlow('login');
        setVerificationCode(false);
        forgotForm.resetFields();
        userEmail.current = '';
      } catch (err) {
        message.error(`${t('PasswordResetFail')}`);
        updateStatusMessage(t('PasswordResetFail'));
      } finally {
        setLoading(false);
      }
    }
  };

  const forgotPasswordFlow = async (Username) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      user.forgotPassword(
        {
          onSuccess: (data) => {
            resolve(data);
          },
          onFailure: (err) => {
            console.error('On Forgot Failure: ', err);
            reject(err);
          },
          inputVerificationCode: (data) => {
            resolve(data);
          },
        },
        { captchaResponse: captchaCode }
      );
    });
  };

  const resetPasswordFlow = async (Username, Code, Password) => {
    setLoading(true);
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      user.confirmPassword(Code, Password, {
        onSuccess: (data) => {
          resolve(data);
          setLoading(false);
        },
        onFailure: (err) => {
          console.error('onFailure: ', err);
          reject(err);
          setLoading(false);
        },
      });
    });
  };

  const confirmHandler = async (values) => {
    setLoading(true);
    try {
      await confirmFlow(params.userName, values.code);
      message.success(t('VerificationCodeSuccess'));
      updateStatusMessage(t('VerificationCodeSuccess'));
      setFlow('login');
      setVerificationCode(false);
      forgotForm.resetFields();
    } catch (err) {
      message.error(`${t('CodeMisMatchException')}`);
      updateStatusMessage(t('CodeMisMatchException'));
    } finally {
      setLoading(false);
    }
  };

  const confirmFlow = async (Username, Code) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      user.confirmRegistration(Code, true, (err, result) => {
        if (err) {
          console.error('onFailure: ', err);
          reject(err);
          setLoading(false);
        } else {
          resolve(result);
          setLoading(false);
        }
      });
    });
  };

  const profileMenu = (
    <Menu role="menu">
      <Menu.Item key="profile" role="menuitem" aria-label={t('Profile')}>
        <Link to={`${RoutePaths.PROFILE}`}>
          <UserOutlined /> {t('Profile')}
        </Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={logout} role="menuitem" aria-label={t('Profile')}>
        <LogoutOutlined /> {t('Logout')}
      </Menu.Item>
    </Menu>
  );

  const onChange = (e) => {
    const { value } = e.target;
    const email = constants.InputRegex.Email;
    setIsDisabled(!email.test(value));
  };

  const handleResetCaptcha = () => {
    setCaptchaKey(Date.now());
    setCaptchaCode('');
  };

  return (
    <>
      {context.screenContext === constants.ScreenSize.Mobile ||
      context.screenContext === constants.ScreenSize.Tablet ? (
        <>
          {!context.isAuth ? (
            <>
              <FatLanguageButton
                text={t('Login')}
                block
                type="primary"
                onClick={() => setVisible(true)}
                className="justify-content-center"
              />
              <LoginPrompt onClick={() => setVisible(true)} visible={visible} />
            </>
          ) : (
            <div className={s.myProfile}>
              <Row align="middle" justify="space-between" className="no-print">
                <Col>
                  {t('Welcome')},{' '}
                  <b onClick={() => setEditProfile(!showEditProfile)}>{startCase(context.profile?.name)}</b>
                </Col>
                <Col>
                  <AccessibilityButton
                    onClick={logout}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        logout && logout();
                      }
                    }}
                    handleSpanClick={() => logoutRef.current && logoutRef.current.click()}
                    ariaLabel={t('Logout')}
                    ref={logoutRef}
                  >
                    {t('Logout')}
                  </AccessibilityButton>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <CustomLink to={RoutePaths.PROFILE} onClick={closeDrawer} ariaLabel={t('Profile')}>
                    <Button aria-label={t('Profile')} type="primary" block>
                      {t('Profile')}
                      <UserOutlined />
                    </Button>
                  </CustomLink>
                </Col>
              </Row>
              <EditProfile show={showEditProfile} onClose={() => setEditProfile(!showEditProfile)} />
            </div>
          )}
        </>
      ) : (
        <>
          <AriaLiveTag statusMessage={statusMessage} />
          {!context.isAuth ? (
            <>
              <Tooltip
                title={t('Login')}
                placement="bottom"
                destroyTooltipOnHide={{ keepParent: false }}
                getPopupContainer={(triggerNode) => triggerNode}
              >
                <AccessibilityButton
                  onClick={() => setVisible(true)}
                  type="outline"
                  className="joyride-home-step6 headerHeight"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setVisible(true);
                    }
                  }}
                  handleSpanClick={() => loginRef.current && loginRef.current.click()}
                  ariaLabel={t('Login')}
                  ref={loginRef}
                >
                  {t('Login')}
                </AccessibilityButton>
              </Tooltip>
              <LoginPrompt onClick={() => setVisible(true)} visible={visible} />
            </>
          ) : (
            <>
              {avatar ? (
                <Dropdown role="button" aria-label={t('ProfileMenu')} trigger={['click']} overlay={profileMenu}>
                  <Avatar className={`${s.avatar} joyride-home-step6`} size={40} src={context.avatar}>
                    {context.profile?.name?.substring(0, 2).toUpperCase()}
                  </Avatar>
                </Dropdown>
              ) : (
                <div className={s.myProfile}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <p>
                        {t('Welcome')},{' '}
                        <strong onClick={() => setEditProfile(!showEditProfile)}>
                          {startCase(context.profile?.name)}
                        </strong>
                      </p>
                    </Col>
                    <Col>
                      <AccessibilityButton
                        type="primary"
                        ghost
                        onClick={logout}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            logout && logout();
                          }
                        }}
                        handleSpanClick={() => logoutRef.current && logoutRef.current.click()}
                        ariaLabel={t('Logout')}
                        ref={logoutRef}
                      >
                        {t('Logout')}
                      </AccessibilityButton>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <CustomLink to={RoutePaths.PROFILE} onClick={closeDrawer} ariaLabel={t('Profile')}>
                        <Button aria-label={t('Profile')} role="button" type="primary" block>
                          {t('Profile')}
                          <UserOutlined />
                        </Button>
                      </CustomLink>
                    </Col>
                  </Row>
                  <EditProfile show={showEditProfile} onClose={() => setEditProfile(!showEditProfile)} />
                </div>
              )}
            </>
          )}
        </>
      )}

      <Modal
        role="dialog"
        aria-labelledby={t('Authentication')}
        aria-modal="true"
        footer={null}
        onCancel={handleCancelLogin}
        closeIcon={<CloseButton type="black" />}
        visible={visible}
        wrapClassName={s.loginModal}
        style={flow === 'signup' ? { top: 60 } : ''}
      >
        <Spin size="large" spinning={loading}>
          <div className={s.loginContainer}>
            <Row justify="space-between">
              <Col>
                <h2 className={'mt-0 mb-0'}>
                  {flow === 'login' && 'Login'}
                  {flow === 'signup' && (
                    <>
                      <AccessibilityButton
                        onClick={() => setFlow('login')}
                        type="link"
                        className={s.marginLeft}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setFlow('login');
                          }
                        }}
                        handleSpanClick={() => signupRef.current && signupRef.current.click()}
                        ariaLabel={t('SignUp')}
                        ref={signupRef}
                      >
                        {t('SignUp')}
                      </AccessibilityButton>
                    </>
                  )}

                  {flow === 'forgot' && (
                    <>
                      <AccessibilityButton
                        onClick={() => {
                          setFlow('login');
                          setIsDisabled(false);
                        }}
                        icon={params.q === 'reset' ? <></> : <LeftOutlined />}
                        type="link"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setFlow('login');
                            setIsDisabled(false);
                          }
                        }}
                        handleSpanClick={() => forgotPasswordTitleRef.current && forgotPasswordTitleRef.current.click()}
                        ariaLabel={t('ShowMore')}
                        ref={forgotPasswordTitleRef}
                      >
                        {t(forgotPasswordTitle)}
                      </AccessibilityButton>
                    </>
                  )}
                  {flow === 'confirm' && (
                    <>
                      <AccessibilityButton
                        onClick={() => setFlow('login')}
                        icon={<LeftOutlined />}
                        type="link"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setFlow('login');
                          }
                        }}
                        handleSpanClick={() => verificationCodeRef.current && verificationCodeRef.current.click()}
                        ariaLabel={t('VerificationCode')}
                        ref={verificationCodeRef}
                      >
                        {t('VerificationCode')} for {params.userName}
                      </AccessibilityButton>
                    </>
                  )}
                </h2>
              </Col>
              <Divider className="my-2" />
            </Row>
            <Row>
              <Col span={24}>
                {flow === 'login' && (
                  <Form
                    layout="vertical"
                    requiredMark={false}
                    form={loginForm}
                    onFinish={authenticateHandler}
                    autoComplete="off"
                    aria-labelledby={t('Login')}
                  >
                    <input autoComplete={'new-password'} style={{ display: 'none' }} aria-hidden="true" />
                    <input
                      autoComplete={'new-password'}
                      name="password"
                      type="password"
                      style={{ display: 'none' }}
                      aria-hidden="true"
                    />
                    <input
                      autoComplete={'new-password'}
                      type="newPassword"
                      style={{ display: 'none' }}
                      aria-hidden="true"
                    />
                    <Form.Item
                      name="username"
                      label={`${t('Email')} *`}
                      required
                      aria-required="true"
                      rules={[
                        { required: true, message: t('EnterEmail') },
                        { pattern: constants.InputRegex.Email, message: t('EnterValidEmail') },
                      ]}
                    >
                      <Input role="textbox" aria-label={t('Email')} placeholder={t('Email')} autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label={`${t('Pwd')} *`}
                      required
                      aria-required="true"
                      rules={[{ required: true, message: t('EnterPassword') }]}
                    >
                      <Input.Password
                        role="textbox"
                        aria-label={t('Pwd')}
                        placeholder={t('Pwd')}
                        name="password"
                        autocomplete="new-password"
                      />
                    </Form.Item>
                    {/* <Form.Item name="captcha"  required
                      aria-required="true" rules={[{ required: true, message: t('EnterCaptcha') }]}>
                      <Captcha
                        key={captchaKey}
                        onChange={(value) => {
                          setCaptchaCode(value);
                        }}
                      />
                    </Form.Item> */}
                    <Row justify="center">
                      <Col xs={12}>
                        <AccessibilityButton
                          ref={loginRef}
                          ariaLabel={t('Login')}
                          block
                          type="primary"
                          handleSpanClick={() => loginForm.submit()}
                          onClick={() => loginForm.submit()}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              loginForm.submit();
                            }
                          }}
                        >
                          {t('Login')}
                        </AccessibilityButton>
                        {/* <Button block type="primary" htmlType="submit" >
                          {t('Login')}
                        </Button> */}
                      </Col>
                    </Row>
                    <Row className={s.paddedRow}>
                      <Col xs={14}>
                        {t('DontHaveAccount')}
                        {constants.IS_REGISTRATION[context.domain] && (
                          <AccessibilityButton
                            ariaLabel={t('SignUp')}
                            type="link"
                            onClick={() => setFlow('signup')}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                setFlow('signup');
                              }
                            }}
                          >
                            <h3 className={'commonLink font-weight-normal'}>{t('SignUp')}</h3>
                          </AccessibilityButton>
                          // <Button type="link" onClick={() => setFlow('signup')}>
                          //   <h3 className={'commonLink font-weight-normal'}>{t('SignUp')}</h3>
                          // </Button>
                        )}
                      </Col>
                      <Col xs={10}>
                        <Row justify="end">
                          <AccessibilityButton
                            ariaLabel={t('Forgot_pwd')}
                            type="link"
                            onClick={() => setFlow('forgot')}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                setFlow('forgot');
                              }
                            }}
                          >
                            {t('Forgot_pwd')}
                          </AccessibilityButton>
                          {/* <Button className={`${s.forgotLink} pl-0 pr-0`} onClick={() => setFlow('forgot')} type="link">
                            {t('Forgot_pwd')}
                          </Button> */}
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                )}
                {flow === 'signup' && (
                  <Form
                    aria-labelledby={t('SignUp')}
                    layout="vertical"
                    requiredMark={false}
                    form={signupForm}
                    onFinish={createUser}
                  >
                    <input autoComplete={'new-password'} style={{ display: 'none' }} aria-hidden="true" />
                    <input
                      autoComplete={'new-password'}
                      name="password"
                      type="password"
                      style={{ display: 'none' }}
                      aria-hidden="true"
                    />
                    <input
                      autoComplete={'new-password'}
                      type="newPassword"
                      style={{ display: 'none' }}
                      aria-hidden="true"
                    />
                    <Form.Item
                      name="name"
                      label={`${t('Name')} *`}
                      required
                      aria-required="true"
                      rules={[
                        { required: true, message: t('EnterName') },
                        { min: 6, message: t('minLength') },
                        { max: 50, message: t('maxLength') },
                        { pattern: constants.InputRegex.UserName, message: t('EnterValidName') },
                      ]}
                    >
                      <Input role="textbox" aria-label={t('Name')} placeholder={t('Name')} autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label={`${t('Email')} *`}
                      required
                      aria-required="true"
                      rules={[
                        { required: true, message: t('EnterEmail') },
                        { pattern: constants.InputRegex.Email, message: t('EnterValidEmail') },
                      ]}
                    >
                      <Input role="textbox" aria-label={t('Email')} placeholder={t('Email')} autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label={`${t('Pwd')} *`}
                      required
                      aria-required="true"
                      rules={[
                        {
                          required: true,
                          message: t('EnterPassword'),
                        },
                        {
                          pattern: constants.InputRegex.Password,
                          message: t('EnterValidPassword'),
                        },
                      ]}
                    >
                      <Input.Password
                        role="textbox"
                        aria-label={t('Pwd')}
                        placeholder={t('Pwd')}
                        autocomplete="new-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="type"
                      label={`${t('User_Type')} *`}
                      required
                      aria-required="true"
                      rules={[{ required: true, message: t('SelectUserType') }]}
                    >
                      <Select role="combobox" aria-label={t('User_Type')} placeholder={t('User_Type')}>
                        {constants.UserDesignations.map((type) => (
                          <Option role="option" aria-label={type.title} key={type.id} value={type.title}>
                            {type.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      aria-required="true"
                      name="captcha"
                      rules={[{ required: true, message: t('EnterCaptcha') }]}
                    >
                      <Captcha
                        onChange={(value) => {
                          setCaptchaCode(value);
                        }}
                      />
                    </Form.Item>

                    <Form.Item name="news" valuePropName="checked">
                      <Checkbox aria-label={t('DoNotReceiveUpdates', { domain: t(context.domain) })} role="checkbox">
                        {t('DoNotReceiveUpdates', { domain: t(context.domain) })}
                      </Checkbox>
                    </Form.Item>
                    <Row justify="center">
                      <Col xs={12}>
                        <AccessibilityButton
                          ref={signupRef}
                          ariaLabel={t('SignUp')}
                          block
                          type="primary"
                          handleSpanClick={() => signupForm.submit()}
                          onClick={() => signupForm.submit()}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              signupForm.submit();
                            }
                          }}
                        >
                          {t('Login')}
                        </AccessibilityButton>
                        {/* <Button block type="primary" htmlType="submit">
                          {t('SignUp')}
                        </Button> */}
                      </Col>
                    </Row>

                    <Row justify="center">
                      <Col>
                        {t('AlreadyHaveAccount')} ?{' '}
                        <AccessibilityButton
                          type="link"
                          onClick={() => setFlow('login')}
                          ariaLabel={t('Login')}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              setFlow('login');
                            }
                          }}
                        >
                          <h3 className={'commonLink font-weight-normal'}>{t('Login')}</h3>
                        </AccessibilityButton>
                        {/* <Button type="link" onClick={() => setFlow('login')}>
                          <h3 className={'commonLink font-weight-normal'}>{t('Login')}</h3>
                        </Button> */}
                      </Col>
                    </Row>
                  </Form>
                )}
                {flow === 'forgot' && (
                  <Form
                    layout="vertical"
                    initialValues={forgotFormValues}
                    requiredMark={false}
                    form={forgotForm}
                    preserve={false}
                    onFinish={forgotPasswordHandler}
                    aria-labelledby={t('ForgotPassword')}
                  >
                    {!verificationCode ? (
                      <>
                        <Form.Item
                          name="email"
                          label={`${t('Email')} *`}
                          required
                          aria-required="true"
                          rules={[
                            { required: true, message: t('EnterEmail') },
                            { pattern: constants.InputRegex.Email, message: t('EnterValidEmail') },
                          ]}
                        >
                          <Input
                            role="textbox"
                            aria-label={t('Email')}
                            placeholder={t('Email')}
                            onChange={onChange}
                            autoComplete="off"
                          />
                        </Form.Item>
                        <Form.Item
                          name="captcha"
                          required
                          aria-required="true"
                          rules={[{ required: true, message: t('EnterCaptcha') }]}
                        >
                          <Captcha
                            key={captchaKey}
                            onChange={(value) => {
                              setCaptchaCode(value);
                            }}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        <Form.Item
                          name="code"
                          label={`${t('VerificationCode')} *`}
                          required
                          aria-required="true"
                          rules={[
                            {
                              required: true,
                              message: t('EnterVerificationCode'),
                            },
                          ]}
                        >
                          <Input
                            role="textbox"
                            aria-label={t('VerificationCode')}
                            placeholder={t('VerificationCode')}
                            autoComplete="off"
                          />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          label={`${t('Pwd')} *`}
                          required
                          aria-required="true"
                          rules={[
                            {
                              required: true,
                              message: t('EnterPassword'),
                            },
                            {
                              pattern: constants.InputRegex.Password,
                              message: t('EnterValidPassword'),
                            },
                          ]}
                        >
                          <Input.Password
                            role="textbox"
                            aria-label={t('Pwd')}
                            placeholder={t('Pwd')}
                            autocomplete="new-password"
                          />
                        </Form.Item>
                        <Form.Item
                          name="confirmPassword"
                          label={`${t('Confirm_pwd')} *`}
                          dependencies={['password']}
                          required
                          aria-required="true"
                          rules={[
                            {
                              required: true,
                              message: t('ConfirmPassword'),
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('PasswordMismtach')));
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            role="textbox"
                            aria-label={t('Confirm_pwd')}
                            placeholder={t('Confirm_pwd')}
                            autocomplete="new-password"
                          />
                        </Form.Item>
                      </>
                    )}
                    <Row>
                      <Col xs={verificationCode ? 12 : 16} md={10}>
                        {verificationCode ? (
                          <AccessibilityButton
                            ref={forgotRef}
                            ariaLabel={t('Submit')}
                            block
                            type="primary"
                            handleSpanClick={() => forgotForm.submit()}
                            onClick={() => forgotForm.submit()}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                forgotForm.submit();
                              }
                            }}
                          >
                            {t('Submit')}
                          </AccessibilityButton>
                        ) : (
                          // <Button block type="primary" htmlType="submit">
                          //   {t('Submit')}
                          // </Button>
                          <Tooltip placement="bottom" text={t('VerificationEmail')} className={s.toolTip}>
                            <FatLanguageButton
                              ref={forgotRef}
                              ariaLabel={t('VerificationEmail')}
                              block
                              type="primary"
                              htmlType="submit"
                              text={t('VerificationEmail')}
                              disabled={isDisabled}
                              handleSpanClick={() => forgotForm.submit()}
                              onClick={() => forgotForm.submit()}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  forgotForm.submit();
                                }
                              }}
                            ></FatLanguageButton>
                          </Tooltip>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
                {flow === 'confirm' && (
                  <Form
                    layout="vertical"
                    initialValues={confirmFormValues}
                    requiredMark={false}
                    form={confirmForm}
                    preserve={false}
                    onFinish={confirmHandler}
                    aria-labelledby={t('Confirm_pwd')}
                  >
                    <Form.Item
                      name="code"
                      label={`${t('VerificationCode')} *`}
                      required
                      aria-required="true"
                      rules={[
                        {
                          required: true,
                          message: t('EnterVerificationCode'),
                        },
                      ]}
                    >
                      <Input
                        role="textbox"
                        aria-label={t('VerificationCode')}
                        placeholder={t('VerificationCode')}
                        autoComplete="off"
                      />
                    </Form.Item>
                    <Row>
                      <Col xs={verificationCode ? 12 : 16} md={12}>
                        <AccessibilityButton
                          ref={confirmRef}
                          ariaLabel={t('Submit')}
                          block
                          type="primary"
                          handleSpanClick={() => confirmForm.submit()}
                          onClick={() => confirmForm.submit()}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              confirmForm.submit();
                            }
                          }}
                        >
                          {t('Submit')}
                        </AccessibilityButton>
                        {/* <Button block type="primary" htmlType="submit">
                          {t('Submit')}
                        </Button> */}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Col>
            </Row>
          </div>
        </Spin>
      </Modal>

      <Modal
        role="dialog"
        aria-labelledby={t('VerificationLinkTitle')}
        aria-modal="true"
        title={t('VerificationLinkTitle')}
        closable={false}
        open={verificationLink}
        footer={null}
      >
        <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('VerificationLinkToEmail')) }}></Typography>
      </Modal>
    </>
  );
};

export default Login;
