import { t } from 'i18next';
import { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { usePrevious } from '../Hooks/use-previous';
import { voidFunction } from '../../utils';
import { Card, Typography, Tooltip, Row, Col } from '../../components';
import {
  JoyridePages,
  JOYRIDE_PAGE_CONNECTION,
  LOGIN_PROMPT_COUNT,
  LOGIN_PROMPT_KEY,
  YES,
  VIEW_MODE_VIEW,
  LOGIN_PROMPT_OBJECT,
} from '../../constants';
import Context from '../../context';
import { RoutePaths } from '../../routePaths';
import AccessibilityButton from '../Buttons/AccessibiltyButton';

const { Title, Paragraph } = Typography;
const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const LoginPrompt = ({ onClick = voidFunction, visible = false }) => {
  const [preloginvisible, setPreloginvisible] = useState(false);
  const [context] = useContext(Context);
  const location = useLocation();
  const urlParams = useQuery();
  const tabInfo = useMemo(() => urlParams.get('tab'), [urlParams]);
  const prevLocation = usePrevious(location);
  const path = window.location.pathname;
  const noThanksRef = useRef(null);
  const loginRef = useRef(null);
  const getPageOrTabInfo = () => {
    if (location.pathname.includes(RoutePaths.DATASET)) {
      if (tabInfo) {
        return `${LOGIN_PROMPT_OBJECT.dataset}${tabInfo}`;
      } else {
        return LOGIN_PROMPT_OBJECT.datasetprofile;
      }
    } else if (location.pathname.includes(RoutePaths.MERGE)) {
      if (tabInfo) {
        return `${LOGIN_PROMPT_OBJECT.merge}${tabInfo}`;
      } else if (location.pathname.includes(VIEW_MODE_VIEW) && !tabInfo) {
        return LOGIN_PROMPT_OBJECT.mergeprofile;
      } else {
        return LOGIN_PROMPT_OBJECT.merge;
      }
    } else {
      return location.pathname;
    }
  };

  useEffect(() => {
    const count = +(sessionStorage.getItem(LOGIN_PROMPT_COUNT) || 0);
    if (sessionStorage.getItem(LOGIN_PROMPT_KEY) !== YES && !context.isAuth && count < 2) {
      const pageInfo = getPageOrTabInfo();
      const page = JOYRIDE_PAGE_CONNECTION[pageInfo];
      const doneJoyRides = localStorage.getItem(JoyridePages.GlobalJoyride);
      let showPrompt = doneJoyRides || (doneJoyRides && page) ? true : false;

      if (doneJoyRides?.includes(page)) {
        showPrompt = true;
      } else {
        showPrompt = false;
      }

      setTimeout(() => {
        setPreloginvisible(showPrompt);
        if (showPrompt) sessionStorage.setItem(LOGIN_PROMPT_COUNT, `${count + 1}`);
      }, 1000);
    }

    if (context.isAuth) {
      sessionStorage.removeItem(LOGIN_PROMPT_KEY);
      sessionStorage.removeItem(LOGIN_PROMPT_COUNT);
    }

    if (prevLocation && !isEqual(prevLocation, location)) {
      sessionStorage.removeItem(LOGIN_PROMPT_COUNT);
    }

    //eslint-disable-next-line
  }, [location.pathname, context.isAuth, location]);

  useEffect(() => {
    if (visible) hide();
  }, [visible]);

  const hide = () => {
    setPreloginvisible(false);
    sessionStorage.setItem(LOGIN_PROMPT_KEY, YES);
  };

  if (!preloginvisible) return null;

  if (path === '/') return <></>;

  return (
    <Card
      className="loginpopup rounded-common animate__animated animate__fadeIn  animate__slow shadow"
      bodyStyle={{ padding: 0 }}
    >
      <div className="d-flex align-items-center">
        <div className="px-2">
          <img width={60} height={60} src="/static/assets/images/ndap_map_logo.png" alt="NDAP" />
        </div>
        <div className="border-left">
          <div className="pr-2 pl-3 pt-2 mt-0">
            <Title level={5} className="pb-0 mb-1 font-14">
              {t('LoginTitle')}
            </Title>
            <Typography className="pb-1">{t('LoginPromptDesc')}</Typography>
          </div>
          <div className="pl-3 pr-2 pb-2 pt-1">
            <Row>
              <Col span={11}>
                {t('NoThanks')?.length > 10 ? (
                  <Tooltip title={t('NoThanks')} placement="top">
                    <Paragraph ellipsis={true} onClick={hide} className="cursor-pointer">
                      {t('NoThanks')}
                    </Paragraph>
                  </Tooltip>
                ) : (
                  <AccessibilityButton size="small" type="text" className="mr-2 cancelBtn" onClick={hide}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      hide && hide();
                    }
                  }}
                  handleSpanClick={() => noThanksRef.current && noThanksRef.current.click()}
                  ariaLabel={t('NoThanks')}
                  ref={noThanksRef}
                  >
                    {t('NoThanks')}
                  </AccessibilityButton>
                )}
              </Col>
              <Col span={13} className="text-right">
                <AccessibilityButton
                  size="small"
                  type="primary"
                  className="ml-1"
                  onClick={() => {
                    hide();
                    onClick();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      hide();
                      onClick();
                    }
                  }}
                  handleSpanClick={() => loginRef.current && loginRef.current.click()}
                  ariaLabel= {t('Login')}
                  ref={loginRef}
                >
                  {t('Login')}
                </AccessibilityButton>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default LoginPrompt;
