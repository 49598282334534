import { DOMAIN, ALL_DOMAINS, STATE_DOMAIN, DEFAULT_KEYWORDS } from '../../constants';

export const SEO_META_INFO = () => {
  return {
    home: {
      title: 'Home',
      description: `The ${STATE_DOMAIN[DOMAIN?.domain]} Data and Analytics Platform (${ALL_DOMAINS[
        DOMAIN?.domain
      ]?.toUpperCase()}) facilitates and improves access to Indian government data. Through the platform, data sets from across India's extensive administrative landscape can be accessed. ${ALL_DOMAINS[
        DOMAIN.domain
      ]?.toUpperCase()} allows users to search, merge, visualize, and download datasets easily.`,
      keywords: DEFAULT_KEYWORDS,
    },
    search: {
      title: 'Search Results',
      description: `Retrieve information based on the search query, by searching throughout the application to find matches for the keyword in all datasets, sectors, ministries, collections, indicators, and other content.`,
      keywords: 'Search Results, datasets, collections, ministries, indicators',
    },
    newsletter: {
      title: 'Newsletter',
      description: `A Govt. of India's initiative with the aim of data dissemination, to improve last-mile access and enable seamless use of published government data through an intuitive & user-friendly web platform that aggregates and hosts datasets from across India's vast statistical infrastructure.
        NDAP seeks to democratize data delivery by making government datasets readily accessible, implementing rigorous data-sharing standards, enabling interoperability across the Indian data landscape, and providing a seamless user interface and user-friendly tools.`,
      keywords:
        'National Data and Analytics Platform, NDAP, Data, Analytics, Platform, Government of India, Data Dissemination, Data Delivery, Data Sharing, Data Standards, Interoperability, User Interface, User-Friendly Tools',
    },
    catalogue: {
      title: 'Catalogue',
      description: `Find all datasets across sectors & ministries hosted on The ${
        STATE_DOMAIN[DOMAIN?.domain]
      } Data and Analytics Platform (${ALL_DOMAINS[DOMAIN?.domain]?.toUpperCase()})`,
      keywords: 'indicators, sectors, ministries, datasets, collections, NDAP, data catalogue',
    },
    help: {
      title: 'Help',
      description: `Discover how to start using NDAP with articles and videos. Explore a diverse range of datasets in the data catalog, access datasets from multiple ministries, and create visualizations for data analysis. Merge datasets, bookmark favorites, and access FAQs for a comprehensive understanding of the application.`,
      keywords:
        'NDAP, National Data Analytics Platform, Getting Started, Data Catalogue, Datasets, Visualizations, Merging Datasets, Profile, FAQ.',
    },
    siteInformation: {
      title: 'National Data Analytics Platform Overview',
      description:
        "Learn about NDAP, NITI Aayog's flagship initiative to improve access to government data. Explore accessibility, data quality, interoperability, and user-centric features. Access information on NDAP's standards, policies, and terms and conditions.",
      keywords:
        'NDAP, National Data and Analytics Platform, NITI Aayog, Accessibility, Quality, Interoperability, User-centricity, Data Standards, Policy, Terms and Conditions.',
    },
    tutorials: {
      title: 'Tutorials',
      description:
        'Watch video tutorials to learn about NDAP, including an overview, end-to-end demo, data visualization, merge function, search, and browse function. Educate yourself on the features and functionalities of the National Data and Analytics Platform.',
      keywords:
        'NDAP, National Data and Analytics Platform, Tutorials, Video Tutorials, Overview, Demo, Data Visualization, Merge Function, Search Function, Browse Function.',
    },
    research: {
      title: 'Research papers',
      description:
        'Explore the details of this collection on NDAP. This collection includes datasets covering geographical coverage at the country, state, district, village level. View the list of datasets available for detailed analysis.',
      keywords:
        'Collection Details, NDAP, Datasets, Geographical Coverage, Country , State, District, Sub-district, Village Level, Data Analysis.',
    },
    profile: {
      title: 'Profile',
      description:
        'Manage your profile on NDAP. Access your saved datasets and visualizations, bookmarks, and subscriptions. Update your account settings, upload a profile image, and reset your password. Easily manage and access your personalized data collections and subscriptions.',
      keywords:
        'Profile Page, NDAP, Bookmarks, Subscriptions, Account Settings, Upload Image, Reset Password, Saved Datasets, Saved Visualizations, Data Collections.',
    },
    publications: {
      title: 'Publications',
      description:
        'Access and upload research papers and newsletters on the NDAP Publications page. Stay informed with the latest research and subscribe to newsletters. Manage and update forms and attachments related to research papers and newsletters.',
      keywords:
        'Publications, Research Papers, Newsletters, NDAP, Upload Forms, Attachments, Latest Research, Subscribe, Manage Content.',
    },
    dataset: {
      title: 'Dataset Details',
      description:
        'Discover comprehensive dataset details on NDAP, including information from various ministries and sectors such as Finance, Banking & Insurance. View and export data as Excel files, access metadata including geographical coverage, time granularity, and indicators. Data is updated quarterly with specific year ranges and the latest updates.',
      keywords:
        'Dataset Details, NDAP, Ministry of Finance, Finance, Banking, Insurance, Geographical Coverage, State Level, Quarterly Data, Year Range, Latest Updates, Data Collection, Source Ministry, Time Granularity, Data Export, Excel Export, Metadata, Indicators.',
    },
    processingReport: {
      title: 'Processing Report',
      description:
        'Discover the thorough processing and standardization of datasets hosted on NDAP. Each dataset undergoes rigorous data quality checks to ensure accurate mapping to Local Government Directory (LGD) dimensions. The processing report provides detailed results of these quality checks, star ratings based on test results, and includes metadata completeness, LGD mapping accuracy, and various data quality assessments. Users can view, analyze, and export datasets with comprehensive meta information, geographical coverage, time granularity, and indicators.',
      keywords:
        'Processing Report, NDAP, Data Standardization, Local Government Directory, Data Quality Checks, Star Rating, Metadata Completeness, LGD Mapping, Data Quality Assessments, Data Export, Geographical Coverage, Time Granularity, Indicators.',
    },
    getApi: {
      title: 'Get API',
      description:
        "Access the dataset through NDAP's open API. Retrieve data based on location, time, and indicators with customizable dimensions such as country, state, and year. Utilize the API URL to get detailed information, including state codes and district codes, with pagination support for large datasets.",
      keywords:
        'Get API, NDAP open API, dataset API, location-based data, time-based data, indicators, customizable dimensions, state code, district code, data retrieval, data export, API URL, pagination, data access.',
    },
    merge: {
      title: 'Merge Datasets',
      description:
        'Explore the National Data Analytics Platform (NDAP) dataset merging and standardization process, focusing on the Local Government Directory (LGD) codes for accurate data integration across different geographic and temporal dimensions.',
      keywords:
        'NDAP, Dataset merging, Data standardization, Local Government Directory (LGD), Geographic dimensions, Temporal dimensions,Administrative units, Interoperable datasets, Data integration, Key dimensions, LGD codes, Splits and merges, Data transformation,Weighted averages, Population Census 2011, Data matching, Standardized data, Indian geography, Data processing pipeline, Data ingestion',
    },
    collections: {
      title: 'Collections',
      description:
        'Explore the collections on NDAP, including datasets covering geographical coverage at the country, state, district, village level. View the list of datasets available for detailed analysis.',
      keywords:
        'Collection Details, NDAP, Datasets, Geographical Coverage, Country , State, District, Sub-district, Village Level, Data Analysis.',
    },
    archive: {
      title: 'Archive',
      description:
        '"Archive" can refer to various concepts depending on the context, but generally, it denotes a collection or repository of historical documents, records, or other materials of enduring value.',
      keywords:
        'Archives, HistoricalRecords, DocumentPreservation, RecordKeeping, ArchivalScience',
    },
  };
};
