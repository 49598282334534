import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { t } from 'i18next';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { Input, Spin, Row, Col, message, Divider } from '../../components';
import { CommonPagination } from '../../shared';
import ListCollections from './ListCollections';
import { APIConfinguration, getCollections } from '../../services';
import { scrollTop } from '../../utils';
import ServiceFilter from '../../shared/DatasetList/Filters/ServiceFilter';
import { voidFunction } from '../../utils/common';

import { AlertServerError } from '../Errors';

const fetchCollections = async (parameters = {}) => {
  const { page = 1, filters = {}, searchTerm = '', isLoading = voidFunction, setData = voidFunction } = parameters;
  let params = {
    ip_offset: `${(page - 1) * 10}`,
  };

  if (searchTerm) {
    params['ip_searchterm'] = `'${searchTerm}'`;
  }

  if (filters.sectors) {
    const sectors = Object.keys(filters.sectors);
    if (sectors.length) params['ip_sector'] = sectors;
  }

  if (filters.ministries) {
    const ministries = Object.keys(filters.ministries);
    if (ministries.length) params['ip_ministry'] = ministries;
  }

  try {
    isLoading(true);
    const { data } = await getCollections(params);
    const { IsError = false, ErrorMessage = '' } = data;
    if (IsError || ErrorMessage !== '') {
      message.error(t('ErrorFethchingDatasets'));
      setData(APIConfinguration.apiErrorResponse.data);
      isLoading(false);
    } else {
      setData(data);
      isLoading(false);
    }
  } catch (error) {
    isLoading(false);
    setData(APIConfinguration.apiErrorResponse.data);
    message.error(t('ErrorFethchingCollections'), error);
    Sentry.captureException(`Failed to get collections , ` + error);
  }

  scrollTop();
};

const waitAndfetchCollections = debounce(fetchCollections, 500);

const CollectionList = ({ paginationAlign = 'end' }) => {
  const [searchTerm, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const [collections, setCollections] = useState({ totalrecords: 0 });
  const [isCollections, setIsCollections] = useState(true);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const fetch = searchTerm ? waitAndfetchCollections : fetchCollections;
    fetch({
      page,
      searchTerm,
      filters,
      isLoading: setIsCollections,
      setData: setCollections,
    });
  }, [page, searchTerm, filters]);

  const handleFilterChange = (filters, service) => {
    setFilters((previous) => ({
      ...previous,
      [service]: filters,
    }));
  };

  const totalRecords = get(collections, 'totalrecords[0].count', 0);
  const { IsError = false } = collections;

  return (
    <Spin size="large" spinning={isCollections}>
      <Input.Search
        role="searchbox"
        aria-label={t('Search_Collections')}
        className={`font-16 mb-2 collectionSearch`}
        placeholder={t('Search_Collections')}
        onChange={(e) => setSearch(e.target.value.trim())}
        onSearch={setSearch}
        allowClear={true}
        autoComplete="off"
      />
      <Row gutter={[10, 0]}>
        <Col>
          <ServiceFilter
            productName="sectors"
            placeholder={t('FilterBySectors')}
            onApply={handleFilterChange}
            apply={filters.sectors}
          />
        </Col>

        <Col>
          <ServiceFilter
            productName="ministries"
            placeholder={t('FilterByMinistries')}
            onApply={handleFilterChange}
            apply={filters.ministries}
          />
        </Col>
      </Row>
      <Divider className="mt-3 mb-0" />
      {IsError && <AlertServerError />}

      <div className={`d-flex justify-content-${paginationAlign} mb-0`}>
        <CommonPagination
          showSizeChanger={false}
          showQuickJumper={true}
          onChange={(page) => setPage(page)}
          total={totalRecords}
          current={page}
          hideOnSinglePage={true}
          className="paginationStyle"
        />
      </div>
      <ListCollections data={collections} isLoading={isCollections} />
      <div className={`d-flex justify-content-${paginationAlign} mt-3`}>
        <CommonPagination
          showSizeChanger={false}
          showQuickJumper={true}
          onChange={(page) => setPage(page)}
          total={totalRecords}
          current={page}
          hideOnSinglePage={true}
          className="paginationStyle"
        />
      </div>
    </Spin>
  );
};

export default CollectionList;
