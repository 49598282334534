import React, { useRef } from 'react';
import DOMPurify from 'dompurify';
import { Typography, Row, Col, Tooltip } from '../../../components';
import { TYPE_ALL_DIMENSIONS } from '../../../constants';
import { useAppliedFilterContext, useFilterContext, useFilterActions } from '../../../providers/FilterContextProvider';
import CustomIcon from '../../CustomIcon';
import DimensionIcon from '../../CustomIcon/DimensionIcon';
import DatasetAllDimensions from '../../DatasetAllDimensions';
import mergeStyles from './index.module.less';
import { t } from 'i18next';
import AccessibilityButton from '../../Buttons/AccessibiltyButton';
const { Title, Text } = Typography;

const ChartDimensions = ({
  title = '',
  buttonText = '',
  details = {},
  applyModalTo = null,
  allow = false,
  onlyDimensionType = false,
  otherThenDimensionType = false,
  type = TYPE_ALL_DIMENSIONS,
  effectiveOnly = false,
  filter = false,
  loading = false,
}) => {
  const { setFilter } = useFilterContext();
  const { getSelectedFilters, getSelectedCount } = useAppliedFilterContext();
  const { removeApplied } = useFilterActions({ sourceID: details.SourceId, type });
  let selected = getSelectedFilters({ sourceID: details.SourceId, type }) || [];

  if (onlyDimensionType && selected.length) {
    selected = selected.filter(
      (item) => (item.DimensionType || item.dimension_type || '').toLowerCase() === onlyDimensionType.toLowerCase()
    );
  }

  if (filter && selected.length) {
    selected.filter((item) => filter(item));
  }

  if (otherThenDimensionType && selected.length) {
    selected = selected.filter(
      (item) => (item.DimensionType || item.dimension_type || '').toLowerCase() !== otherThenDimensionType.toLowerCase()
    );
  }

  if (!Array.isArray(selected)) {
    selected = Object.keys(selected).length ? [selected] : [];
  }

  if (allow) {
    selected = selected.slice(0, allow);
  }

  let isDisabledMode = false;
  if (effectiveOnly) {
    let effectiveRule = Object.entries(effectiveOnly);

    for (let [key, allowed] of effectiveRule) {
      let typeCount = getSelectedCount({ sourceID: details.SourceId, type: key });

      if (typeCount > allowed) {
        isDisabledMode = true;
      }

      if (isDisabledMode) {
        break;
      }
    }
  }
  const replaceAddRef = useRef(null);
  const isReplace = allow && selected.length === allow;
  return (
    <div className={`${mergeStyles.axisType} `}>
      <Row>
        <Col span={24} className="mt-2">
          <Title className={`font-16 mb-3 ${isDisabledMode ? 'text-muted' : ''}`}>{t(title)}</Title>
        </Col>
        <Col span={24}>
          {selected &&
            selected.map((item) => {
              const name = (
                <span
                  className="text-capitalize"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.DisplayName),
                  }}
                ></span>
              );

              return (
                <div
                  key={item.ID}
                  className={`${mergeStyles.visualBtnWrapper} mb-1 position-relative pr-3 pl-2 pt-2 pb-2`}
                >
                  <Tooltip title={name}>
                    <Text ellipsis className={`text-dark ${isDisabledMode ? 'line-through text-muted' : ''}`}>
                      <DimensionIcon type={item.DimensionType} verticalAlign={3} className="mr-1" />
                      {name}
                    </Text>
                  </Tooltip>

                  <span className="position-absolute right-0" style={{ right: 2, top: 1 }}>
                    <CustomIcon
                      type={'CloseIcon'}
                      verticalAlign={8}
                      onClick={() => !loading && removeApplied(selected, item)}
                      width={15}
                      height={15}
                    />
                  </span>
                </div>
              );
            })}
        </Col>
        <Col span={24} className="mt-2">
          <AccessibilityButton
            className={`${mergeStyles.dashedBtn} d-flex font-weight-bold text-left`}
            type="dashed"
            block
            icon={<CustomIcon type={isReplace ? 'ReplaceIcon' : 'AddIcon'} verticalAlign={3} className="ml-n1" />}
            onClick={() => setFilter({ sourceID: details.SourceId, type })}
            disabled={isDisabledMode || loading}
            onKeyDown={(e) => {
              if (e.key === 'Enter') 
                setFilter({ sourceID: details.SourceId, type })
            }}
            handleSpanClick={() => replaceAddRef.current && replaceAddRef.current.click()}
            ariaLabel= {`${isReplace ? t('Replace') : t('Add')} ${t(buttonText)}`}
            ref={replaceAddRef}
          >
            {`${isReplace ? t('Replace') : t('Add')} ${t(buttonText)}`}
          </AccessibilityButton>
        </Col>

        <DatasetAllDimensions
          sourceID={details.SourceId}
          dimensions={details.Dimensions?.Items || []}
          applyModalTo={applyModalTo}
          sourceName={details.SourceName}
          icon={false}
          allow={allow}
          onlyDimensionType={onlyDimensionType}
          otherThenDimensionType={otherThenDimensionType}
          type={type}
        />
      </Row>
    </div>
  );
};

export default ChartDimensions;
