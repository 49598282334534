import {
  calcDrawerWidth,
  isExpired,
  textExists,
  textSearch,
  voidFunction,
  getUrlParams,
  buildUrlParamsFromObj,
  scrollTop,
  getBase64,
  isMobileDevice,
  getLocation,
  appendToPageTitle,
  exportToCsv,
  onlyTextAndNumbers,
  onlyTextToLowerCase,
  replaceAll,
  getObjectEntries,
  parseStringToNumber,
  checkStandarisation,
  characterLimit,
  getLocaleStringByCountry,
  checkScalingFactor,
  purifyQueryStringObject,
  getDynamicPath,
  parseDataTableYear,
  updateTitleAndMetaInfo,
  isObject,
  isBoolean,
  convertKbToMb,
  extractFileNameAndFormat,
  createExcelAndGetSize,
  convertToBoolean,
  formatTitle
} from './common';

export {
  calcDrawerWidth,
  isExpired,
  textExists,
  textSearch,
  voidFunction,
  getUrlParams,
  buildUrlParamsFromObj,
  scrollTop,
  getBase64,
  isMobileDevice,
  getLocation,
  appendToPageTitle,
  exportToCsv,
  onlyTextAndNumbers,
  onlyTextToLowerCase,
  replaceAll,
  getObjectEntries,
  parseStringToNumber,
  checkStandarisation,
  characterLimit,
  getLocaleStringByCountry,
  checkScalingFactor,
  purifyQueryStringObject,
  getDynamicPath,
  parseDataTableYear,
  updateTitleAndMetaInfo,
  isObject,
  isBoolean,
  convertKbToMb,
  extractFileNameAndFormat,
  createExcelAndGetSize,
  convertToBoolean,
  formatTitle
};
