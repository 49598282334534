import { CloseCircleFilled, InfoCircleFilled, WarningFilled } from '@ant-design/icons';
import { t } from 'i18next';

import { Modal, Button } from '../../components';
import CustomIcon from '../CustomIcon';

const notify = ({ type = 'success', message = false, onLinkClick = false, linkText = 'ClickToView' }) => {
  const defaultIconStyle = { fontSize: 30 };
  const icons = {
    success: <CustomIcon type={'NotifySuccess'} width={40} height={40} />,
    error: <CloseCircleFilled style={defaultIconStyle} className="text-danger" />,
    warning: <WarningFilled style={defaultIconStyle} className="text-warning" />,
    info: <InfoCircleFilled style={defaultIconStyle} className="text-primary" />,
  };

  Modal[type]({
    centered: true,
    icon: null,
    okButtonProps: { className: 'd-none' },
    closable: true,
    className: 'custom-notification',
    maskClosable: true,
    content: (
      <div className="text-center">
        <div className="mt-n4 mb-1">{icons[type]}</div>
        {message && (
          <div className="font-16" style={{ fontWeight: 400 }}>
            {t(message)}
          </div>
        )}
        {onLinkClick && (
          <div className="mt-2">
            <Button
              aria-label={t('DownloadPng')}
              role="button"
              type="link"
              onClick={() => {
                Modal.destroyAll();
                onLinkClick();
              }}
            >
              <span className="text-underline">{t(linkText)}</span>
            </Button>

            {/* <AccessibilityButton
              type="link"
              onClick={() => {
                Modal.destroyAll();
                onLinkClick();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  Modal.destroyAll();
                  onLinkClick();
                }
              }}
              handleSpanClick={() => linkTextRef.current && linkTextRef.current.click()}
              ariaLabel={t(linkText)}
              ref={linkTextRef}
            > */}
          </div>
        )}
      </div>
    ),
  });
};

export const success = ({ message = false, onLinkClick = false, linkText = 'ClickToView' }) =>
  notify({ type: 'success', message, onLinkClick, linkText });

export const error = ({ message = false, onLinkClick = false, linkText = 'ClickToView' }) =>
  notify({ type: 'error', message, onLinkClick, linkText });

export const warning = ({ message = false, onLinkClick = false, linkText = 'ClickToView' }) =>
  notify({ type: 'warning', message, onLinkClick, linkText });

export const info = ({ message = false, onLinkClick = false, linkText = 'ClickToView' }) =>
  notify({ type: 'info', message, onLinkClick, linkText });

const messages = {
  success,
  error,
  warning,
  info,
};

export default messages;
