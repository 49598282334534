import { useMemo, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { typeConfig } from './LeftChartsList';
import { useAppliedFilterContext, useFilterContext } from '../../../providers/FilterContextProvider';
import { Modal, Row, Col } from '../../../components';
import { t } from 'i18next';
import CustomIcon from '../../CustomIcon';
import { voidFunction } from '../../../utils';
import AccessibilityButton from '../../Buttons/AccessibiltyButton';

const HelpUserForInput = ({ selected = {}, previous = {}, sourceID = false, onSelectChartType = voidFunction }) => {
  const { getSelectedCount } = useAppliedFilterContext();
  const { setFilter, isFilterShown, filters } = useFilterContext();
  const addActionRef = useRef(null);
  const previousNameRef = useRef(null);
  const neededFilters = useMemo(() => {
    let filters = Object.values(typeConfig[selected.type] || {});
    let required = { score: 0, filters: [], title: [] };

    if (!isFilterShown({ sourceID }) && sourceID) {
      for (let index in filters) {
        const dataRow = filters[index];
        let count = getSelectedCount({ sourceID, type: dataRow.type, oneLevel: true });

        if (dataRow.required) {
          if (!count) {
            required.filters.push(dataRow);

            if (!required.title.includes(dataRow.filterType)) {
              required.title.push(dataRow.filterType);
            }
          }
        }

        required.score += count;
      }
    }

    return required;

    // eslint-disable-next-line
  }, [selected, previous, filters, sourceID]);

  if (isEmpty(selected) || isEmpty(previous)) return null;

  return (
    <Modal
      role="dialog"
      aria-labelledby={t('HelpUserForInput')}
      aria-modal="true"
      getContainer={'#main-content'}
      title={false}
      visible={neededFilters.score > 0 && neededFilters.filters.length}
      footer={false}
      bodyStyle={{ padding: 0 }}
      closable={false}
      style={{ top: 30 }}
    >
      <div className="text-center">
        <div className="p-4 border-bottom">
          <div>
            <CustomIcon type={'ExclamationTriangle'} />
          </div>
          <div>
            <strong>
              {t('AddMissingFilters', {
                filters: `${neededFilters.title.join(' & ').replace(/-chart/gi, '')}`,
                chartType: `$t(${selected.name})`,
              })}
            </strong>
          </div>
        </div>
        <div className="p-3 bg-color-light-white">
          {neededFilters.filters.map((filter) => (
            <Row justify="center" key={filter.title} className="mb-3">
              <Col span={10}>
                <div className="text-left">
                  <div className="mb-1">
                    <strong>{filter.title}</strong>
                  </div>
                  <AccessibilityButton
                    className="font-weight-bold text-left rounded w-100"
                    type="dashed"
                    icon={<CustomIcon type={'AddIcon'} verticalAlign={3} className="ml-n1" />}
                    onClick={() => setFilter({ sourceID, type: filter.type })}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter')
                        setFilter({ sourceID, type: filter.type })
                    }}
                    handleSpanClick={() => addActionRef.current && addActionRef.current.click()}
                    ariaLabel={t('AddAction', { action: `$t(${filter.buttonText})` })}
                    ref={addActionRef}
                  >
                    {t('AddAction', { action: `$t(${filter.buttonText})` })}
                  </AccessibilityButton>
                </div>
              </Col>
            </Row>
          ))}
          <div className="mt-3">
            {t('OrSwitchBack')}
            <AccessibilityButton className="p-0 ml-1" type="link" onClick={() => onSelectChartType(previous)}
              onKeyDown={(e) => {
                if (e.key === 'Enter')
                  onSelectChartType(previous)
              }}
              handleSpanClick={() => previousNameRef.current && previousNameRef.current.click()}
              ariaLabel={previous.name}
              ref={previousNameRef}>
              {previous.name}
            </AccessibilityButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HelpUserForInput;
