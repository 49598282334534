import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  QuestionCircleFilled,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { blue, green, red } from '@ant-design/colors';
import { t } from 'i18next';

import { Tag, Tooltip, Modal, Table, Alert } from './index';
import { useState, useContext } from 'react';
import CustomIcon from '../shared/CustomIcon';
import Context from '../context';
import { CloseButton } from '../shared';
import { voidFunction } from '../utils';

const { Column } = Table;
const StandarizationTag = ({ isStandarized = true }) => {
  const [hover, onHover] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [context] = useContext(Context);

  return (
    <div style={{ letterSpacing: 0.8, textShadow: '.3px .3px' }}>
      <Tag
        color={isStandarized ? 'processing' : 'error'}
        className={`rounded-pill font-10 ${hover && !isStandarized && 'text-underline cursor-pointer shadow-sm'}`}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
        onClick={() => setShowInfo(true)}
      >
        <span style={{ marginRight: 2 }} className="postion-relative d-inline-block">
          {hover && !isStandarized ? (
            <Tooltip title={t('ClickToKnowMore')} visible={true} color={isStandarized ? blue[4] : red[4]}>
              <QuestionCircleFilled className="font-12 animate__animated animate__flipInY mr-1" />
            </Tooltip>
          ) : (
            <>
              {isStandarized ? (
                <CheckCircleFilled
                  style={{ color: green.primary }}
                  className="font-12 d-inline-block mr-1 animate__animated animate__flipInY"
                />
              ) : (
                <ExclamationCircleFilled className="font-12 d-inline-block mr-1 animate__animated animate__flipInY" />
              )}
            </>
          )}
        </span>
        {t(isStandarized ? 'Standarized' : 'NotStandarized', { domain: t(context.domain) })}
      </Tag>

      {!isStandarized ? (
        <StandardizationDetails
          onClose={() => {
            setShowInfo(false);
          }}
          visible={showInfo}
        />
      ) : null}
    </div>
  );
};

export const IsAllowedIcon = ({ allowed = false }) => (
  <div className="text-center">
    {!allowed ? (
      <CloseOutlined
        style={{
          color: red.primary,
        }}
      />
    ) : (
      <CheckOutlined
        style={{
          color: green.primary,
        }}
      />
    )}
  </div>
);

export const StandardizationDetails = ({ visible, onClose = voidFunction }) => (
  <Modal
    role="dialog"
    aria-labelledby={t('Standarisation.Title')}
    aria-modal="true"
    width={600}
    title={
      <span className="font-14">
        <CustomIcon type={'NonStandardDataset'} width={20} height={14} />
        {t('Standarisation.Title')}
      </span>
    }
    visible={visible}
    footer={false}
    closeIcon={<CloseButton type="black" />}
    onCancel={onClose}
  >
    <Alert role="alert" aria-label={t('Standarisation.Note')} type="warning" description={t('Standarisation.Note')} className="mb-3" />
    <Table
      role="table"
      aria-label={t('StandardizationFeaturesTable')}
      bordered
      pagination={false}
      dataSource={[
        {
          key: '1',
          feature: t('Merge_Datasets'),
          allowedStand: true,
          allowedNonStand: false,
        },
        {
          key: '2',
          feature: t('ViewProcessingReport'),
          allowedStand: true,
          allowedNonStand: false,
        },
        {
          key: '3',
          feature: t('Standarisation.LocationFilter'),
          allowedStand: true,
          allowedNonStand: false,
        },
        {
          key: '4',
          feature: t('Standarisation.MapView'),
          allowedStand: true,
          allowedNonStand: false,
        },
      ]}
      size="small"
    >
      <Column
        title={<div>{t('Standarisation.Feature')}</div>}
        dataIndex="feature"
        key="feature"
        render={(data) => <span>{data}</span>}
      />

      <Column
        dataIndex="allowedNonStand"
        key={'allowedNonStand'}
        render={(allowed) => <IsAllowedIcon allowed={allowed} />}
      />
    </Table>
  </Modal>
);

export default StandarizationTag;
