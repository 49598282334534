import React, { useState, useRef, useContext } from 'react';
import { BellFilled, SyncOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { presetPalettes } from '@ant-design/colors';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import Context from '../../context';
import { ACTIVITIES } from '../../constants';
import { subscribeData, postClickStreamEvent } from '../../services';
import { Modal, FatLanguageButton } from '../../components';
import notify from '../NotificationModal';
import { RoutePaths } from '../../routePaths';

const { confirm } = Modal;
const getStoreSubscription = (subscriptions, id) =>
  (subscriptions?.records || []).find((data) => String(data.id) === String(id));

const SubscribeButton = ({ type, id, name = '', ml = 3 }) => {
  const [store, setContext] = useContext(Context);
  const [subscriptions, setSubscription] = useState(getStoreSubscription(store.subscriptions, id));
  const [isLoading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const subscriptionType = type === 'merge' ? 'datasetNew' : type;
  const histroy = useHistory();

  React.useEffect(() => {
    setSubscription(getStoreSubscription(store.subscriptions, id));
  }, [store.subscriptions, id]);

  const handleClick = () => {
    if (!store.profile?.email) {
      setContext((state) => ({
        ...state,
        showLogin: true,
      }));
    } else {
      const doSubscribe = () =>
        subscribeData({
          userID: store.profile.email,
          ip_sourcecode: id,
          ip_type: type,
          subscribe: subscriptions ? false : true,
          onError: () => {
            const errorMessage = subscriptions ? 'UnSubscriptionFailed' : 'SubscriptionFailed';
            notify.error({ message: errorMessage });
            setStatusMessage(t(errorMessage));
            // notify.error({
            //   message: subscriptions ? 'UnSubscriptionFailed' : 'SubscriptionFailed',
            // });
            Sentry.captureException('Failed to fetch subscription details');
          },
          setLoading,
          setData: (data) => {
            const successMessage = subscriptions ? 'DataUnsubscribed' : 'DataSubscribed';
            notify.success({
              // message: subscriptions ? 'DataUnsubscribed' : 'DataSubscribed',
              message: successMessage,
              onLinkClick: () => {
                histroy.push(`${RoutePaths.PROFILE}/subscriptions`);
              },
              linkText: 'ViewSubscriptions',
            });
            setStatusMessage(t(successMessage));

            postClickStreamEvent({
              activity: subscriptions ? ACTIVITIES.UNSUBSCRIBE : ACTIVITIES.SUBSCRIBE,
              userid: store?.profile?.email || '',
              subscribe_object_id: `${id}`,
              subscribe_object_type: `${type}`,
            });

            setContext((ps) => ({ ...ps, subscriptions: data }));
          },
        });

      if (subscriptions) {
        confirm({
          title: t('ConfirmUnSubscription'),
          icon: <ExclamationCircleOutlined />,
          content: name,
          onOk() {
            doSubscribe();
          },
        });
      } else {
        doSubscribe();
      }
    }
  };
  const unSubscribeRef = useRef(null);
  const subscribeRef = useRef(null);
  return (
    <span>
      <div aria-live="polite" aria-atomic="true">
        {statusMessage && <p style={{ position: 'absolute', left: '-9999px' }}>{statusMessage}</p>}
      </div>
      {subscriptions ? (
        <FatLanguageButton
          text={t('UnSubscribe')}
          tooltip={t('Subscribed', { type: `$t(${subscriptionType})` })}
          className={`ml-${ml} font-16 font-weight-semi-bold text-capitalize joyride-dataset-step5`}
          iconLeft={(isLoading && <SyncOutlined spin />) || <BellFilled />}
          onClick={handleClick}
          style={{ color: presetPalettes.gold.primary }}
          type="link"
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleClick && handleClick();
          }}
          handleSpanClick={() => unSubscribeRef.current && unSubscribeRef.current.click()}
          aria-label={t('UnSubscribe')}
          ref={unSubscribeRef}
        />
      ) : (
        <FatLanguageButton
          text={t('Subscribe')}
          tooltip={t('ClickToSubscribe', { type: `$t(${subscriptionType})` })}
          type="link"
          className={`ml-${ml} font-16 font-weight-semi-bold text-capitalize joyride-dataset-step5`}
          iconLeft={(isLoading && <SyncOutlined spin />) || <BellFilled />}
          onClick={handleClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleClick && handleClick();
          }}
          handleSpanClick={() => subscribeRef.current && subscribeRef.current.click()}
          aria-label={t('Subscribe')}
          ref={subscribeRef}
        />
      )}
    </span>
  );
};

export default SubscribeButton;
