import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import Context from '../../context';
import { ModalPopup } from '../../shared';
import { JoyridePages } from '../../constants';
import { voidFunction } from '../../../src/utils';

const UsefulTipJoyride = ({ page, startJoyride = voidFunction, visible = false }) => {
  const [isModalVisible, setIsModalVisible] = useState(visible);
  const [context] = React.useContext(Context);

  useEffect(() => {
    if (localStorage.getItem(JoyridePages.GlobalJoyride) !== null && page !== null && visible) {
      if (!localStorage.getItem(JoyridePages.GlobalJoyride).includes(page)) {
        setIsModalVisible(true);
      } else {
        setIsModalVisible(false);
      }
    } else {
      if (visible) {
        setIsModalVisible(true);
      }
    }
  }, [visible, page]);

  const onChange = (e) => {
    if (e) {
      localStorage.setItem(JoyridePages.GlobalJoyride, Object.values(JoyridePages).toString());
      localStorage.setItem(JoyridePages.Joyride, Object.values(JoyridePages).toString());
    }
    setIsModalVisible(false);
  };

  const handleJoyride = () => {
    setIsModalVisible(false);
    startJoyride(true);
    getJoyridePages();
  };

  const handleClose = () => {
    setIsModalVisible(false);
    startJoyride(false);
    getJoyridePages();
  };

  const getJoyridePages = () => {
    let pageList = [];
    const joyrideLocalStorage = localStorage.getItem(JoyridePages.GlobalJoyride);
    const splitStorage = joyrideLocalStorage && joyrideLocalStorage?.split(',');
    if (splitStorage) {
      pageList = [...splitStorage, page];
    } else {
      pageList = [page];
    }
    let uniqueList = pageList.filter((c, index) => {
      return pageList.indexOf(c) === index;
    });
    localStorage.setItem(JoyridePages.GlobalJoyride, uniqueList);
  };

  const content = <h5 className="font-14 font-weight-normal">{t('UsefulTipDescription')}</h5>;

  return (
    <>
      {context.screenContext === 'desktop' && isModalVisible && (
        <ModalPopup
          role="dialog"
          aria-labelledby={t('UsefulTip')}
          aria-modal="true"
          title={t('UsefulTip')}
          complete={handleClose}
          proceed={false}
          content={content}
          open={isModalVisible}
          width={500}
          onReturn={() => {
            handleJoyride();
          }}
          skip={{
            title: t('UsefulTipNotShow'),
            onChange: (e) => {
              onChange(e.target.checked);
            },
          }}
        />
      )}
    </>
  );
};
export default UsefulTipJoyride;
