import { Button } from '../../components';
import { voidFunction } from '../../utils';

const AccessibilityButton = ({
  value,
  ref,
  handleSpanClick = voidFunction,
  disabled = false,
  tabIndex = 0,
  ariaLabel,
  className,
  onKeyDown = voidFunction,
  onClick = voidFunction,
  handleFocus = voidFunction,
  handleBlur = voidFunction,
  children,
  ...props
}) => {
  return (
    <span
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      onClick={() => handleSpanClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      aria-label={ariaLabel}
    >
      <Button
        ref={ref}
        value={value}
        disabled={disabled}
        className={`${className} pointer`}
        tabIndex="-1"
        onClick={onClick}
        role="button"
        aria-label={ariaLabel}
        {...props}
      >
        {children}
      </Button>
    </span>
  );
};

export default AccessibilityButton;
