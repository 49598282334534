import React, { useContext, useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import Context from '../../context';
import Icon, { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Popover, Slider, Row, Col, InputNumber, Space, Badge } from '../../components';
import isEqual from 'lodash/isEqual';
import { ScreenSize } from '../../constants';
import s from './index.module.less';
import AccessibilityButton from '../Buttons/AccessibiltyButton';

const RangeDropdown = ({ text, min = 0, max = 100, step = 1, onApply = () => { }, mr = 10, apply = [] }) => {
  min = Number(min);
  max = Number(max);
  const hide = min === 0 && max === 0;
  const [context] = useContext(Context);
  const [isVisible, setVisibility] = useState(false);
  const [range, setRange] = useState(apply.length ? apply : [min, max]);
  const [isSetRange, setRangeset] = useState(apply.length ? true : false);
  const [validYear, setValidYear] = useState(false);
  const btnApplyRef = useRef(null);
  const clearRef = useRef(null);
  const textRef = useRef(null);
  useEffect(() => {
    if (!apply.length) setRange([min, max]);

    //eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    setRange([min, max]);
  }, [min, max]);

  useEffect(() => {
    if (apply.length && !isEqual(apply, range) && !isVisible) setRange(apply);

    if (apply === '') handleClear();
    //eslint-disable-next-line
  }, [apply, isVisible]);

  useEffect(() => {
    validateYear(range);

    //eslint-disable-next-line
  }, [range]);

  const handleChange = (range) => {
    validateYear(range);
    setRange(range);
  };

  const validateYear = (range) => {
    if (range[0] < min || range[0] > max) setValidYear(true);
    else if (range[1] < min || range[1] > max) setValidYear(true);
    else setValidYear(false);
  };

  const handleOnApply = () => {
    onApply(range);
    setVisibility(false);
    setRangeset(true);
  };

  const handleClear = () => {
    setRange([min, max]);
    onApply([]);
    setVisibility(false);
    setRangeset(false);
  };

  const onInputChange = (input, type) => {
    const { value } = input.target;
    updateRange(+value, type);
  };

  const onStep = (number, type) => {
    updateRange(number, type);
  };

  const updateRange = (value, type) => {
    setRange((previousRange) => {
      switch (type) {
        case 'max':
          if (value > previousRange[0]) {
            return [previousRange[0], value];
          }

          break;

        case 'min':
        default:
          if (value < previousRange[1]) {
            return [value, previousRange[1]];
          }

          break;
      }

      return previousRange;
    });
  };

  if (hide) return null;

  return (
    <Popover
      visible={isVisible}
      onVisibleChange={(status) => setVisibility(status)}
      trigger="click"
      placement="bottom"
      content={
        <div style={{ width: context.screenContext === ScreenSize.Mobile ? `${18}rem` : `${20}rem` }}>
          <Row gutter={[10, 20]}>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <Row>
                    <Col span={8} className={s.textBreak}>
                      <label>{t('From')}</label>
                    </Col>

                    <Col span={14}>
                      <InputNumber
                        aria-label={t('MinimumRange')}
                        role="spinbutton"
                        value={range[0]}
                        type={'number'}
                        min={min}
                        max={max}
                        onBlur={(e) => onInputChange(e, 'min')}
                        onStep={(e) => onStep(e, 'min')}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Space>
                    <label>{t('To')}</label>
                    <InputNumber
                      aria-label={t('MaximumRange')}
                      role="spinbutton"
                      value={range[1]}
                      type={'number'}
                      min={min}
                      max={max}
                      onBlur={(e) => onInputChange(e, 'max')}
                      onStep={(e) => onStep(e, 'max')}
                    />
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Slider
                tipFormatter={(value) => value}
                tooltipPlacement={'top'}
                min={min}
                max={max}
                range={true}
                onChange={handleChange}
                value={range}
                step={step}
                getTooltipPopupContainer={() => document.querySelector('.ant-slider-step')}
              />
              {validYear && <p className="text-danger mt-2 mb-2">{t('validYear', { min, max })}</p>}
            </Col>
            <Col span={12}>
              <AccessibilityButton style={{ width: '100%' }} type="primary" onClick={handleOnApply} disabled={validYear}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleOnApply();
                  }
                }}
                handleSpanClick={() => btnApplyRef.current && btnApplyRef.current.click()}
                ariaLabel={t('BtnApply')}
                ref={btnApplyRef}
              >
                {t('BtnApply')}
              </AccessibilityButton>
            </Col>
            <Col span={12}>
              <AccessibilityButton style={{ width: '100%' }} type="secondary" onClick={handleClear}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleClear();
                  }
                }}
                handleSpanClick={() => clearRef.current && clearRef.current.click()}
                ariaLabel={t('Clear')}
                ref={clearRef}
              >
                {t('Clear')}
              </AccessibilityButton>
            </Col>
          </Row>
        </div>
      }
    >
      <AccessibilityButton
        type="outline"
        onClick={() => setVisibility(!isVisible)}
        style={{ marginRight: mr }}
        className="pr-3 mr-0 rounded"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setVisibility(!isVisible)
          }
        }}
        handleSpanClick={() => textRef.current && textRef.current.click()}
        ariaLabel={t(text)}
        ref={textRef}
      >
        <Badge
          count={isSetRange ? `${apply[0] || ''}-${apply[1] || ''}` : 0}
          style={{ zIndex: 1 }}
          offset={[-6, -4]}
        ></Badge>
        {t(text)}
        <Icon component={isVisible ? UpOutlined : DownOutlined} className="font-12" />
      </AccessibilityButton>
    </Popover>
  );
};

export default RangeDropdown;
