import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Modal } from 'antd';
import { voidFunction } from '../../utils';
import AccessibilityButton from '../Buttons/AccessibiltyButton';

const DownloadModal = ({ show, file, onCancel = voidFunction, download = voidFunction }) => {
  const [visible, setVisible] = useState(show);
  const downloadRef = useRef(null);
  const closeRef = useRef(null);
  const onClose = () => {
    setVisible(false);
    onCancel(false);
  };

  useEffect(() => {
    setVisible(show);
  }, [show]);

  if (!file) return null;

  const handleDownload = () => {
    localStorage.removeItem('Size');
    if (file.path) {
      const link = document.createElement('a');
      link.href = file.path;
      link.download = file.name;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      download(true);
    }
    onCancel(false);
  };

  return (
    <Modal
      role="dialog"
      aria-labelledby={t('FileInformation')}
      aria-modal="true"
      title={t('FileInformation')}
      visible={visible}
      onCancel={onClose}
      footer={[
        <AccessibilityButton key="download" type="primary" onClick={handleDownload}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleDownload && handleDownload();
            }
          }}
          handleSpanClick={() => downloadRef.current && downloadRef.current.click()}
          ariaLabel={t('Download')}
          ref={downloadRef}>
          {t('Download')}
        </AccessibilityButton>,
        <AccessibilityButton key="close" onClick={onClose}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClose && onClose();
            }
          }}
          handleSpanClick={() => closeRef.current && closeRef.current.click()}
          ariaLabel={t('Close')}
          ref={closeRef}
        >
          {t('Close')}
        </AccessibilityButton>,
      ]}
    >
      <p>
        <strong>{t('FileName')}:</strong> {file.name}
      </p>
      <p>
        <strong>{t('FileSize')}:</strong> {file.size || t('NA')}
      </p>
      <p>
        <strong>{t('FileFormat')}:</strong> {file.format}
      </p>
    </Modal>
  );
};

export default DownloadModal;
