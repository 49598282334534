import React, { useContext, useRef } from 'react';
import { Modal, Checkbox, Alert } from '../../components';
import { ScreenSize } from '../../constants';
import Context from '../../context';
import s from './index.module.less';
import { CloseButton } from '../../shared';
import { t } from 'i18next';
import CustomLink from '../Accessibility/Link';
import AccessibilityButton from '../Buttons/AccessibiltyButton';

const ModalPopup = ({
  onReturn,
  open,
  complete,
  title = '',
  cancel = false,
  proceed = true,
  content = '',
  width = 600,
  skip = false,
}) => {
  const [context] = useContext(Context);
  const cancelButtonRef = useRef(null);
  const proceedButtonRef = useRef(null);
  const startDemoRef = useRef(null);
  const handleProceed = () => {
    onReturn();
  };
  const handleCancel = () => {
    complete();
  };

  return (
    <>
      <Modal
        role="dialog"
        aria-labelledby={title || t('Notice')}
        aria-modal="true"
        title={title || t('Notice')}
        visible={open}
        centered
        onCancel={handleCancel}
        width={width}
        footer={[
          skip && (
            <div className="float-left" key="skip">
              <Checkbox aria-label={t('skip')} role="checkbox" onChange={skip.onChange}>{skip.title}</Checkbox>
            </div>
          ),
          cancel && (
            <AccessibilityButton
              key="cancel"
              onClick={handleCancel}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleCancel && handleCancel();
              }}
              handleSpanClick={() => cancelButtonRef.current && cancelButtonRef.current.click()}
              ariaLabel={t('Cancel')}
              ref={cancelButtonRef}
            >
              {t('Cancel')}
            </AccessibilityButton>
          ),
          proceed && (
            <AccessibilityButton
              title={t('Proceed')}
              key="submit"
              type="primary"
              onClick={handleProceed}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleProceed && handleProceed();
              }}
              handleSpanClick={() => proceedButtonRef.current && proceedButtonRef.current.click()}
              ariaLabel={t('Proceed')}
              ref={proceedButtonRef}
            >
              {t('Proceed')}
            </AccessibilityButton>
          ),
          !proceed && (
            <AccessibilityButton
              key="submit"
              type="primary"
              onClick={handleProceed}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleProceed && handleProceed();
              }}
              handleSpanClick={() => startDemoRef.current && startDemoRef.current.click()}
              ariaLabel={t('StartDemo')}
              ref={startDemoRef}
            >
              {t('StartDemo')}
            </AccessibilityButton>
          ),
        ]}
        content={content}
        closeIcon={<CloseButton />}
      >
        {content && <div>{content}</div>}
        {!content && (
          <div className={s.modalContent}>
            {context.screenContext === ScreenSize.Desktop && (
              <Alert role="alert" aria-label={t('mergeWarningText')} message={t('mergeWarningText')} type="warning" className="mb-3" />
            )}
            {t('NDAPStandardText_one', { domain: t(context.domain) })}{' '}
            <CustomLink
              to="/info?tab=standardization"
              className="mr-1"
              ariaLabel={t('NDAPStandardText_two', { domain: t(context.domain) })}
            >
              <b>
                <u>{t('NDAPStandardText_two', { domain: t(context.domain) })}</u>
              </b>
            </CustomLink>{' '}
            {t('NDAPStandardText_three')}{' '}
            <CustomLink to="/info?tab=termsandconditions" className="mr-1" ariaLabel={t('NDAPStandardText_four')}>
              <b>
                <u>{t('NDAPStandardText_four')}</u>
              </b>
            </CustomLink>{' '}
            {t('NDAPStandardText_five', { domain: t(context.domain) })}
          </div>
        )}
      </Modal>
    </>
  );
};

export default ModalPopup;
