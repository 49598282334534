import React from 'react';
import { t } from 'i18next';
import { Link, useHistory } from 'react-router-dom';
import useNavigate from '../../../shared/Hooks/useNavigate';
import { Condition } from '../../../components';
import { voidFunction } from '../../../utils';

const CustomLink = ({
  to,
  children,
  ariaLabel,
  skip = false,
  target = '_self',
  className,
  isPrivate = false,
  onClick = voidFunction,
  ...props
}) => {

  const history = useHistory();
  const navigate = useNavigate();
  const isExternal = to?.startsWith('http');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      if (target === '_blank') {
        if (isExternal) {
          handleExternalClick();
        } else {
          window.open(to, '_blank');
        }
        return false;
      }
      if (skip) {
        window.location.href = '/#home';
      } else if (isPrivate) {
        navigate(to);
      } else {
        history.push(to);
      }
    }
  };

  const handleExternalClick = () => {
    if (window.confirm(t('ExternalLinkMessage'))) {
      window.open(to, '_blank');
    }
  };

  if (isExternal) {
    return (
      <a
        href={to}
        onClick={(e) => {
          e.preventDefault();
          handleExternalClick();
        }}
        tabIndex="-1"
        aria-label={ariaLabel}
        target={target}
        rel={target ? '' : 'noopener noreferrer'}
        title={ariaLabel}
        className={className}
      >
        <span tabIndex="0" role="link" onKeyDown={handleKeyDown} aria-label={ariaLabel} title={ariaLabel}>
          <Condition show={skip}>{children}</Condition>
          <Condition show={!skip}>
            {children}
          </Condition>
        </span>
      </a>
    );
  }

  return (
    <span tabIndex="0" role="link" onKeyDown={handleKeyDown} aria-label={ariaLabel} title={ariaLabel}>
      <Condition show={skip}>{children}</Condition>
      <Condition show={!skip}>
        <Link
          onClick={onClick}
          to={to}
          tabIndex="-1"
          className={className}
          target={target}
          rel={target ? '' : 'noopener noreferrer'}
          role="link"
          aria-label={ariaLabel}
          {...props}
        >
          {children}
        </Link>
      </Condition>
    </span>
  );
};

export default CustomLink;
