import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { t } from 'i18next';
import {
  // Button,
  Spin,
  Tabs,
  Dropdown,
  FatLanguageButton,
  Row,
  Col,
  Menu,
  message,
  Alert,
  DatasetName,
  Condition,
} from '../../components';
import { ReactComponent as DownloadIcon } from '../../assets/svg/download.svg';
import { postClickStreamEvent, getDatasetDownloadLink } from '../../services';
import Context from '../../context';
import {
  JoyridePages,
  ACTIVITIES,
  GridFixedSpan,
  CHART_TYPE_PIVOT,
  CHART_TYPES,
  TYPE_CONDITION,
  TYPE_LOCATION,
  TYPE_TIME,
  TYPE_DIMENSIONS,
  VISUALIZE_TYPES,
  TYPE_GRANULARITY,
  ScreenSize,
  TYPE_CONDITION_VISUALIZATION,
} from '../../constants';
import ProcessingReport from './ProcessingReport';
import ProfileTab from './ProfileTab';
import DataTab from './DataTab';
import VisualizationTab from './VisualizationTab';
import { steps, stepsDatatab, stepsVisualization, stepsVisualizationCharts } from './joyride';
import { IntroJoyRide, ModalPopup, UsefulTipJoyride } from '../../shared';
import SubscribeButton from '../Subscriptions/SubscribeButton';
import SaveBookmarkDetails from '../Bookmarks/saveBookmarks';
import { voidFunction, parseDataTableYear, convertKbToMb } from '../../utils';
import Share from '../Share';
import s from './index.module.less';
import { getDatasetHeaderColumns } from './Columns';
import { RoutePaths } from '../../routePaths';
import { PageContainer } from '../Layout';
import { useFilterActions, useFilterContext } from '../../providers/FilterContextProvider';
import { checkStandarisation } from '../../utils/common';
import DownloadModal from '../DownloadModal';
import AccessibilityButton from '../Buttons/AccessibiltyButton';
// import PageTitleNew from '../PageTitleNew';

const { TabPane } = Tabs;

const Dataset = (props) => {
  const { currentPage = 1, setCurrentPage = voidFunction, download = voidFunction, activeTab = () => { } } = props;
  const [dataPreview, setDataPreview] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { datasetId = false, dataset = {} } = props;
  const { data = [], headers = [], total = 0, profile = [], details = {}, NextPagination = 0 } = dataset;
  const [chartType, setChartType] = useState('');
  const [tabs, setTabs] = useState({
    profile: true,
    data: false,
    meta: false,
    processingreport: false,
    visualization: false,
  });
  const [showDataPopup, setShowDataPopup] = useState(1);
  const [showDataTab, setShowDataTab] = useState(false);
  const [showPopup, setShowPopup] = useState(0);
  const [filteredDataColumns, setFilteredDataColumns] = useState(false);
  const [hiddenDataColumns, setHiddenDataColumns] = useState(false);
  const [tableCols, setTableCols] = useState([]);
  const [allTableColumns, setAllTableCols] = useState([]);
  const [context, setContext] = useContext(Context);
  const [startJoyride, setStartJoyride] = useState(false);
  const [startJoyrideData, setStartJoyrideData] = useState(false);
  const [startJoyrideVisualization, setStartJoyrideVisualization] = useState(false);
  const [graphType, setGraphType] = useState(false);
  const [activeGraphType, setActiveGraphType] = useState(false);
  const [chartTitle, setChartTitle] = useState('');
  const [visible, setIsVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pageSize = 100;
  const iconRotations = 160;
  const Joyridepage = JoyridePages.Dataset;
  const Joyridepagedatasetdata = JoyridePages.DatasetData;
  const JoyridepagedatasetVisualization = JoyridePages.DatasetVisualization;
  const [startJoyrideCharts, setStartJoyrideCharts] = useState(false);
  const JoyridepageCharts = JoyridePages.VisualizationCharts;
  const chartDetails = CHART_TYPES.find(({ type }) => activeGraphType === type) || {};
  const [isDownloadTable, setIsDownloadTable] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isDownloadDataset, setIsDownloadDataset] = useState(false);
  const [isDownloadVisualisation, setIsDownloadVisualisation] = useState(false);
  const [isDownloadModal, setIsDownloadModal] = useState(false);
  const [fileObj, setFileObj] = useState({});
  const { clearFilters } = useFilterContext();
  const titleRef = useRef();
  const MobileAlertForAdvancedFeatures =
    !localStorage.getItem('MobileAlertForAdvancedFeatures') && context.screenContext === ScreenSize.Mobile;

  const handleMerge = (isMergePop = true) => {
    if (isMergePop) {
      setShowPopup(false);
    }

    postClickStreamEvent({
      activity: ACTIVITIES.START_MERGE,
      userid: context?.profile?.email || '',
      datasetid_1: datasetId,
    });
    window.open(`${RoutePaths.MERGE}?dataset=${datasetId}`, '_blank');
  };

  const { destroy } = useFilterActions();
  /**
   * Destroy filters on unmount
   * */
  // eslint-disable-next-line
  useEffect(() => () => destroy(), []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has('chart')) {
      setChartType(params.get('chart'));
    }

    if (params.has('tab')) {
      changeTab(params.get('tab'));
    }

    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    if (localStorage.getItem(JoyridePages.Joyride) !== null && Joyridepage !== null) {
      if (localStorage.getItem(JoyridePages.Joyride).includes(Joyridepage)) {
        setStartJoyride(false);
      }
    }
    if (localStorage.getItem(JoyridePages.Joyride) !== null && Joyridepagedatasetdata !== null) {
      if (localStorage.getItem(JoyridePages.Joyride).includes(Joyridepagedatasetdata)) {
        setStartJoyrideData(false);
      }
    }
    if (localStorage.getItem(JoyridePages.Joyride) !== null && JoyridepagedatasetVisualization !== null) {
      if (localStorage.getItem(JoyridePages.Joyride).includes(JoyridepagedatasetVisualization)) {
        setStartJoyrideVisualization(false);
      }
    }
    if (localStorage.getItem(JoyridePages.Joyride) !== null && JoyridepageCharts !== null) {
      if (localStorage.getItem(JoyridePages.Joyride).includes(JoyridepageCharts)) {
        setStartJoyrideCharts(false);
      }
    }

    if (details?.SourceName) {
      setIsVisible(true);
    }

    localStorage.setItem("Pivot", false);
  }, [
    Joyridepage,
    JoyridepagedatasetVisualization,
    Joyridepagedatasetdata,
    JoyridepageCharts,
    tabs,
    activeGraphType,
    details?.SourceName,
  ]);

  useEffect(() => {
    const init = () => {
      let tableColsTemp = [];

      if (headers && !tableCols.length) {
        tableColsTemp = getDatasetHeaderColumns(headers);
        setAllTableCols(tableColsTemp);
        setTableCols(tableColsTemp);
      }

      const tempRowData =
        data?.map((item) => {
          return { ...item, Year: parseDataTableYear(item?.Year) };
        }) || [];

      setTableData(tempRowData);

      if (!dataPreview.length) {
        setDataPreview(tempRowData.slice(0, 100));
      }
    };
    init();
  }, [headers, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!filteredDataColumns?.length) {
      let items = getDatasetHeaderColumns(headers);
      setTableCols(items);
    } else {
      setTableCols(filteredDataColumns);
    }
  }, [filteredDataColumns, headers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (context.isAuth && isDownloadTable) downloadTable();
  }, [context.isAuth, isDownloadTable]); // eslint-disable-line

  useEffect(() => {
    if (context.isAuth && isDownloadDataset) downloadModalDatasetDetails();
    // eslint-disable-next-line
  }, [context.isAuth, isDownloadDataset]);

  useEffect(() => {
    if (context.isAuth && isDownloadVisualisation) {
      // document.getElementById('download-graph').click();
      downloadModalVisulaizationPivotTable();
    }
  }, [context.isAuth, isDownloadVisualisation]);

  const onDatasetPageChange = (page) => {
    setCurrentPage(page);
  };

  const changeTab = (key) => {
    const current = Object.keys(tabs).find((tabKey) => tabs[tabKey]);

    if (current === key) return;

    if (key === 'data' && showDataPopup === 1) {
      if (!isStandarised) {
        setShowDataPopup(1);
      } else {
        setShowDataPopup(0);
      }
      setShowDataTab(false);
      setTimeout(() => {
        setShowDataTab(true);
      }, 500);
    }

    setTabs((ps) => {
      let allTabs = { ...ps };
      Object.keys(allTabs).forEach((v) => (allTabs[v] = false));

      activeTab(key);
      return { ...allTabs, [key]: true };
    });
  };

  const onTabChange = (key) => {
    params.set('tab', key);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });

    clearFilters();
  };

  const authenticateDownloadEntireDataset = (e) => {
    if (!context.isAuth) {
      e.preventDefault();
      setContext((state) => ({
        ...state,
        showLogin: true,
      }));
      setIsDownloadDataset(true);
    } else {
      downloadModalDatasetDetails();
    }
  };

  const downloadModalDatasetDetails = () => {
    setIsDownloadModal(true);
    downloadEntireDataset();
  };

  const downloadModalVisulaizationPivotTable = async () => {
    setIsDownloadModal(true);
    if (activeGraphType === CHART_TYPE_PIVOT) {
      document.getElementById('download-graph').click();
      setFileObj({ name: `${details?.SourceName}`, format: 'XLSX', size: localStorage.getItem('Size') });
    } else {
      setFileObj({ name: `${details?.SourceName}`, format: 'PNG', size: '10 KB' });
    }
    // document.getElementById('download-graph').click(false);
  };

  const downloadEntireDataset = async () => {
    setDownloadLoading(true);
    try {
      const downloadRes = await getDatasetDownloadLink({ source_code: details?.SourceId });

      if (downloadRes.data.IsError) {
        message.error(t('DatasetExportFailed'));
      } else {
        setFileObj({ name: `${details?.SourceId}_all_files`, format: 'ZIP', path: downloadRes.data.URL, size: convertKbToMb(downloadRes.data.Size) });
        // const a = document.createElement('a');
        // document.body.appendChild(a);
        // a.href = downloadRes.data.URL;
        // a.target = '_blank';
        // a.click();
        // a.remove();

        postClickStreamEvent({
          activity: ACTIVITIES.STATIC_DOWNLOAD,
          userid: context?.profile?.email || '',
          datasetid_1: details?.SourceId,
        });
      }
    } catch (e) {
      console.log(e);
      message.error(t('DatasetExportFailed'));
      Sentry.captureException(`Dataset export failed , ` + e);
    } finally {
      setDownloadLoading(false);
    }
  };

  const authenticateDownload = (e) => {
    if (!context.isAuth) {
      e.preventDefault();
      setContext((state) => ({
        ...state,
        showLogin: true,
      }));
      setIsDownloadTable(true);
    } else {
      downloadTable();
    }
  };

  const downloadTable = async () => {
    await download({ columns: tableCols });
    setIsDownloadTable(false);
  };

  const viewReportClick = () => {
    postClickStreamEvent({
      activity: ACTIVITIES.VIEW_PROCESSING_REPORT,
      userid: context?.profile?.email || '',
      datasetid_1: details?.SourceId,
    });

    history.push(`${RoutePaths.PROCESSING_REPORT}/${details?.SourceId}`);
  };
  const mergeDatasetsRef = useRef(null);
  const exportDatasetsRef = useRef(null);
  const downloadTableRef = useRef(null);
  const profileActionsResponsiveMenu = (
    <Menu role="menu">
      <Menu.Item key="profileshare" tabIndex="0" role="menuitem" aria-label={t('Share')}>
        <Share datasetID={datasetId} datasetName={details?.SourceName} queryParams={params.toString()} types={[]} />
      </Menu.Item>
      <Menu.Item key="profilesubscribe" tabIndex="0" role="menuitem" aria-label={t('Subscribe')}>
        <SubscribeButton id={datasetId} type={'dataset'} name={details?.SourceName} ml={0} />
      </Menu.Item>
      <Menu.Item key="profilebookmark" tabIndex="0" role="menuitem" aria-label={t('save')}>
        <SaveBookmarkDetails
          datasetID={datasetId}
          name={details?.SourceName}
          queryParams={params.toString()}
          types={[]}
        />
      </Menu.Item>
      <Menu.Item key="profilemergedataset" role="menuitem" aria-label={t('Merge_Datasets')}>
        <AccessibilityButton
          onClick={() => setShowPopup(true)}
          type="primary"
          className="rounded-common border-0 commonbtn-light font-16 font-weight-semi-bold"
          disabled={true}
          size="small"
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              setShowPopup(true)
          }}
          handleSpanClick={() => mergeDatasetsRef.current && mergeDatasetsRef.current.click()}
          aria-label={t('Merge_Datasets')}
          ref={mergeDatasetsRef}
        >
          {t('Merge_Datasets')}
        </AccessibilityButton>
        <ModalPopup
          open={showPopup}
          datasetId={datasetId}
          complete={() => setShowPopup(false)}
          onReturn={() => handleMerge(true)}
        />
      </Menu.Item>
      <Menu.Item key="profileexport" role="menuitem" aria-label={t('ExportDataset')}>
        <Spin spinning={downloadLoading}>
          <AccessibilityButton
            icon={<DownloadIcon className="mr-1" />}
            type="primary"
            onClick={authenticateDownloadEntireDataset}
            size="large"
            className="rounded-common font-16 font-weight-semi-bold lg-btn-height"
            onKeyDown={(e) => {
              if (e.key === 'Enter')
                authenticateDownloadEntireDataset && authenticateDownloadEntireDataset();
            }}
            handleSpanClick={() => exportDatasetsRef.current && exportDatasetsRef.current.click()}
            aria-label={t('ExportDataset')}
            ref={exportDatasetsRef}
          >
            {t('ExportDataset')}
          </AccessibilityButton>
        </Spin>
      </Menu.Item>
    </Menu>
  );

  const isStandarised = checkStandarisation(details);
  const profileActions = (
    <Row gutter={[16, 10]} align="middle" className="pb-2">
      <Col>
        <Share datasetID={datasetId} datasetName={details?.SourceName} queryParams={params.toString()} types={[]} />
      </Col>
      <Col>
        <SubscribeButton id={datasetId} type={'dataset'} name={details?.SourceName} ml={0} />
      </Col>
      <Col>
        <SaveBookmarkDetails
          datasetID={datasetId}
          name={details?.SourceName}
          queryParams={params.toString()}
          types={[]}
        />
      </Col>
      <Col>
        <FatLanguageButton
          disabled={!isStandarised}
          tooltip={
            !isStandarised
              ? t('DisableDueToNotStandarized', {
                feature: t('Merge_Datasets'),
              })
              : ''
          }
          text={t('Merge_Datasets')}
          onClick={() => setShowPopup(true)}
          type="primary"
          className="joyride-dataset-step7 rounded-common border-0 commonbtn-light font-weight-semi-bold font-16 lg-btn-height"
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              setShowPopup(true)
          }}
          handleSpanClick={() => mergeDatasetsRef.current && mergeDatasetsRef.current.click()}
          aria-label={t('Merge_Datasets')}
          ref={mergeDatasetsRef}
        />
        <ModalPopup
          open={showPopup}
          datasetId={datasetId}
          complete={() => setShowPopup(false)}
          onReturn={() => handleMerge(true)}
        />
      </Col>
      <Col>
        <Spin spinning={downloadLoading}>
          <FatLanguageButton
            text={t('ExportDataset')}
            iconLeft={<DownloadIcon className="mr-1" />}
            type="primary"
            onClick={authenticateDownloadEntireDataset}
            size="large"
            className="joyride-dataset-step8 rounded-common font-16  font-weight-semi-bold lg-btn-height"
            onKeyDown={(e) => {
              if (e.key === 'Enter')
                authenticateDownloadEntireDataset && authenticateDownloadEntireDataset();
            }}
            handleSpanClick={() => exportDatasetsRef.current && exportDatasetsRef.current.click()}
            aria-label={t('ExportDataset')}
            ref={exportDatasetsRef}
          />
        </Spin>
      </Col>
    </Row>
  );

  const datatabTypes = [
    TYPE_CONDITION,
    TYPE_DIMENSIONS,
    TYPE_LOCATION,
    TYPE_TIME,
    TYPE_GRANULARITY,
    TYPE_CONDITION_VISUALIZATION,
  ];
  const activeGraphTypeRef = useRef(null);
  const dataActionsResponsiveMenu = (
    <Menu role="menu">
      <Menu.Item key="datashare" role="menuitem" aria-label={t('Share')}>
        <Share
          datasetID={datasetId}
          datasetName={details?.SourceName}
          queryParams={params.toString()}
          types={datatabTypes}
        />
      </Menu.Item>
      <Menu.Item key="datasubscribe" role="menuitem" aria-label={t('Subscribe')}>
        <SubscribeButton id={datasetId} type={'dataset'} name={details?.SourceName} ml={0} />
      </Menu.Item>
      <Menu.Item key="databookmark" role="menuitem" aria-label={t('save')}>
        <SaveBookmarkDetails
          datasetID={datasetId}
          name={details?.SourceName}
          queryParams={params.toString()}
          types={datatabTypes}
        />
      </Menu.Item>
      <Menu.Item key="datadownload" role="menuitem" aria-label={t('DownloadTable')}>
        <AccessibilityButton
          type="primary"
          onClick={authenticateDownload}
          size="large"
          className="rounded-common font-16 lg-btn-height font-weight-semi-bold"
          icon={<DownloadIcon className="mr-1" />}
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              authenticateDownload && authenticateDownload();
          }}
          handleSpanClick={() => downloadTableRef.current && downloadTableRef.current.click()}
          aria-label={t('DownloadTable')}
          ref={downloadTableRef}
        >
          {t('DownloadTable')}
        </AccessibilityButton>
      </Menu.Item>
    </Menu>
  );

  const dataActions = (
    <Row gutter={4} align="middle" className="pb-2">
      <Col>
        <Share
          datasetID={datasetId}
          datasetName={details?.SourceName}
          queryParams={params.toString()}
          types={datatabTypes}
        />
      </Col>
      <Col>
        <SubscribeButton id={datasetId} type={'dataset'} name={details?.SourceName} ml={0} />
      </Col>
      <Col>
        <SaveBookmarkDetails
          datasetID={datasetId}
          name={details?.SourceName}
          queryParams={params.toString()}
          types={datatabTypes}
        />
      </Col>
      <Col>
        <FatLanguageButton
          text={t('DownloadTable')}
          iconLeft={<DownloadIcon className="mr-1" />}
          type="primary"
          onClick={authenticateDownload}
          size="large"
          className="rounded-common font-weight-semi-bold font-16 joyride-datasetdata-stepOne lg-btn-height"
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              authenticateDownload && authenticateDownload();
          }}
          handleSpanClick={() => downloadTableRef.current && downloadTableRef.current.click()}
          aria-label={t('DownloadTable')}
          ref={downloadTableRef}
        />
      </Col>
    </Row>
  );

  const downloadVisualization = (
    <>
      <FatLanguageButton
        text={t(activeGraphType === CHART_TYPE_PIVOT ? 'DownloadPivotTable' : 'DownloadVisualization')}
        type="primary"
        disabled={!graphType}
        onClick={() => {
          if (!context.isAuth) {
            setContext((state) => ({
              ...state,
              showLogin: true,
            }));
            setIsDownloadVisualisation(true);
          } else {
            // document.getElementById('download-graph').click();
            downloadModalVisulaizationPivotTable();
          }
        }}
        className="rounded-common font-16 font-weight-semi-bold joyride-datasetvisualizationchart-step1 lg-btn-height"
        iconLeft={<DownloadIcon className="mr-1" />}
        size={'large'}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            {
              if (!context.isAuth) {
                setContext((state) => ({
                  ...state,
                  showLogin: true,
                }));
                setIsDownloadVisualisation(true);
              } else {
                // document.getElementById('download-graph').click();
                downloadModalVisulaizationPivotTable();
              }
            }
          }
        }}
        handleSpanClick={() => activeGraphTypeRef.current && activeGraphTypeRef.current.click()}
        aria-label={t(activeGraphType === CHART_TYPE_PIVOT ? 'DownloadPivotTable' : 'DownloadVisualization')}
        ref={activeGraphTypeRef}
      />
      {activeGraphType !== CHART_TYPE_PIVOT && graphType && (
        <>
          <UsefulTipJoyride startJoyride={(e) => setStartJoyrideCharts(e)} visible={visible} page={JoyridepageCharts} />
          <IntroJoyRide steps={stepsVisualizationCharts()} enabled={startJoyrideCharts} page={JoyridepageCharts} />
        </>
      )}
    </>
  );

  const downloadPivotTable = () => {
    localStorage.setItem('Pivot', true);
    document.getElementById('download-graph').click();
  }

  const onCancel = () => {
    setIsDownloadModal(false);
    localStorage.setItem('Pivot', false);
  }

  let saveVisualName = [details?.SourceName];

  if (typeof chartTitle === 'string') {
    saveVisualName.push(chartTitle.trim());
  }

  if (chartTitle?.title) {
    saveVisualName.push(chartTitle?.title.trim());
  }

  if (chartDetails.name) {
    saveVisualName.push(chartDetails.name);
  }

  saveVisualName = saveVisualName.join(' - ');

  const visualTypes = [...datatabTypes, ...VISUALIZE_TYPES];

  const visualizeActionsResponsiveMenu = (
    <Menu role="menu">
      <Menu.Item key="visualizationshare" role="menuitem" aria-label={t('Share')}>
        <Share
          datasetID={datasetId}
          name={saveVisualName}
          queryParams={`${params.toString()}&chart=${activeGraphType}`}
          disabled={!graphType}
        />
      </Menu.Item>
      <Menu.Item key="visualizationsubscribe" role="menuitem" aria-label={t('Subscribe')}>
        <SubscribeButton id={datasetId} type={'dataset'} name={saveVisualName} ml={0} />
      </Menu.Item>
      <Menu.Item key="visualizationbookmark" role="menuitem" aria-label={t('save')}>
        <SaveBookmarkDetails
          datasetID={datasetId}
          name={<SubscribeButton id={datasetId} type={'dataset'} name={saveVisualName} ml={0} />}
          queryParams={`${params.toString()}&chart=${activeGraphType}`}
          disabled={!graphType}
        />
      </Menu.Item>
      <Menu.Item key="visualizationdownload" role="menuitem" aria-label={t(activeGraphType === CHART_TYPE_PIVOT ? 'DownloadPivotTable' : 'DownloadVisualization')}>{downloadVisualization}</Menu.Item>
    </Menu>
  );

  const visualizeActions = (
    <Row gutter={[16, 10]} align="middle" className="pb-2">
      <Col>
        <Share
          datasetID={datasetId}
          name={saveVisualName}
          queryParams={`${params.toString()}&chart=${activeGraphType}`}
          disabled={!graphType}
        />
      </Col>
      <Col>
        <SubscribeButton id={datasetId} type={'dataset'} name={saveVisualName} ml={0} />
      </Col>
      <Col>
        <SaveBookmarkDetails
          datasetID={datasetId}
          name={saveVisualName}
          queryParams={`${params.toString()}&chart=${activeGraphType}`}
          disabled={!graphType}
          types={visualTypes}
        />
      </Col>
      <Col>{downloadVisualization}</Col>
    </Row>
  );

  const onCreatePivot = () => {
    onTabChange('visualization');
    setChartType(CHART_TYPE_PIVOT);
  };

  const onClose = () => {
    localStorage.setItem('MobileAlertForAdvancedFeatures', true);
  };

  return (
    <PageContainer size={1} background="bg-white" bottomSpace={0}>
      <div className={`${s.datasetContainer} move-up`}>
        <Spin size="large" spinning={context.isLoading} tip={t('fetchingDatasetDetails')}>
          {MobileAlertForAdvancedFeatures && (
            <Alert role="alert" aria-label={t('AdvAlert')} message={t('AdvAlert')} type="info" closable onClose={onClose} className="mb-3" />
          )}

          <Condition show={context.isAuth && isDownloadModal}>
            <DownloadModal show={context.isAuth && isDownloadModal} file={fileObj} onCancel={onCancel} download={tabs.profile ? downloadEntireDataset : downloadPivotTable} />
          </Condition>

          <Row justify={'space-between'}>
            {/* <Col>
            <PageTitleNew title={'Dataset'} /></Col>
            <Col> */}
            <DatasetName
              name={details?.SourceName}
              standarization={details?.Standardization}
              forwardedRef={titleRef}
              showStandarization={true}
              iconSize={30}
              fontSize={18}
            />
            {/* </Col> */}
          </Row>
          <Row>
            <Col {...GridFixedSpan.size.full}>
              <Tabs
                animated={{ inkBar: false, tabPane: false }}
                defaultActiveKey="profile"
                onChange={onTabChange}
                activeKey={Object.keys(tabs).find((k) => tabs[k])}
                tabBarExtraContent={
                  context.screenContext === 'mobile' || context.screenContext === 'tablet' ? (
                    <Dropdown.Button
                      aria-label={t('HiddenDatasetTabsMenu')}
                      role="button"
                      overlayClassName={s.actionsMenu}
                      trigger={['click']}
                      overlay={
                        <>
                          {tabs.profile && profileActionsResponsiveMenu}
                          {tabs.data && dataActionsResponsiveMenu}
                          {tabs.visualization && visualizeActionsResponsiveMenu}
                        </>
                      }
                    ></Dropdown.Button>
                  ) : (
                    <>
                      {tabs.profile && profileActions}
                      {tabs.data && dataActions}
                      {tabs.visualization && visualizeActions}
                    </>
                  )
                }
              >
                <TabPane tab={<div className={'joyride-dataset-step1'} tabIndex="0" aria-label={t('Profile')}>{t('Profile')}</div>} key="profile">
                  {tabs.profile && (
                    <UsefulTipJoyride startJoyride={(e) => setStartJoyride(e)} visible={visible} page={Joyridepage} />
                  )}
                  {tabs.profile && <IntroJoyRide steps={steps()} enabled={startJoyride} page={Joyridepage} />}
                </TabPane>
                <TabPane tab={<div className={'joyride-dataset-step2'} tabIndex="0" aria-label={t('DataTable')}>{t('DataTable')}</div>} key="data">
                  {showDataPopup > 1 && (
                    <>
                      <UsefulTipJoyride
                        startJoyride={(e) => setStartJoyrideData(e)}
                        visible={visible}
                        page={Joyridepagedatasetdata}
                      />
                      <IntroJoyRide steps={stepsDatatab()} enabled={startJoyrideData} page={Joyridepagedatasetdata} />
                    </>
                  )}
                </TabPane>
                {context.screenContext === 'desktop' && (
                  <TabPane
                    tab={<div className={'joyride-dataset-step3'} tabIndex="0" aria-label={t('Visualization')}>{t('Visualization')}</div>}
                    key={'visualization'}

                  >
                    <UsefulTipJoyride
                      startJoyride={(e) => setStartJoyrideVisualization(e)}
                      visible={visible}
                      page={JoyridepagedatasetVisualization}
                    />
                    <IntroJoyRide
                      steps={stepsVisualization()}
                      enabled={startJoyrideVisualization}
                      page={JoyridepagedatasetVisualization}
                    />
                  </TabPane>
                )}
              </Tabs>

              <div>
                <Row>
                  <Col span={24}>
                    {tabs.profile && (
                      <ProfileTab
                        tabIndex="0"
                        total={total}
                        profile={profile}
                        details={details}
                        preview={dataPreview}
                        tableCols={allTableColumns}
                        iconRotations={iconRotations}
                        viewReportClick={viewReportClick}
                        downloadEntireDataset={authenticateDownloadEntireDataset}
                      />
                    )}
                    {tabs.data && showDataTab && (
                      <DataTab
                        tabIndex="0"
                        columns={tableCols}
                        total={total}
                        data={tableData}
                        details={details}
                        onDatasetPageChange={onDatasetPageChange}
                        pageSize={pageSize}
                        iconRotations={iconRotations}
                        currentPage={currentPage}
                        hiddenDataColumns={hiddenDataColumns}
                        setFilteredDataColumns={(showColumns, selectedColumns) => {
                          setFilteredDataColumns(showColumns);
                          setHiddenDataColumns(selectedColumns);
                        }}
                        filteredDataColumns={filteredDataColumns}
                        allColumns={allTableColumns}
                        onCreatePivot={onCreatePivot}
                        NextPagination={NextPagination}
                      />
                    )}

                    {tabs.processingreport && (
                      <ProcessingReport showReport={tabs['processingreport']} datasetID={datasetId} />
                    )}

                    {context.screenContext === 'desktop' && tabs.visualization && (
                      <VisualizationTab
                        tabIndex="0"
                        details={details}
                        chartType={chartType}
                        onLoad={(type) => {
                          setGraphType(type);
                        }}
                        setActiveGraphType={setActiveGraphType}
                        setChartType={setChartType}
                        visualTitle={(title) => setChartTitle(title)}
                        merge={false}
                        isVisualisation={true}
                        domain={context?.domain}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <ModalPopup
            open={showDataPopup < 1}
            complete={() => setShowDataPopup(2)}
            onReturn={() => {
              setShowDataPopup(2);
            }}
          />
        </Spin>
      </div>
    </PageContainer>
  );
};

export default Dataset;
