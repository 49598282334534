import React, { useState } from 'react';
import { Dropdown, Menu, message, FatLanguageButton } from '../../components';
import Context from '../../context';
import { useAppliedFilterContext } from '../../providers/FilterContextProvider';
import { saveFiltersToBookmark } from '../../services';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { FacebookOutlined, TwitterOutlined, LinkOutlined } from '@ant-design/icons';
import CustomIcon from '../../shared/CustomIcon';
import { t } from 'i18next';
import { postClickStreamEvent } from '../../services';
import { MERGE_MODES, ACTIVITIES, COLLECTION } from '../../constants';
import { RoutePaths } from '../../routePaths';
import { getUrl } from '../../utils/common';

const Share = ({
  datasetName,
  category = 'share',
  mode = 'single',
  datasetID = [],
  type = 'dataset',
  queryParams = '',
  types = false,
  disabled = false,
}) => {
  const [isBookmarkInProgress, setIsBookmarkInProgress] = useState(false);
  const [bookmarkID, setBookmarkID] = useState(0);
  const [store] = React.useContext(Context);
  const [url, setUrl] = useState(undefined);
  const [content, setContent] = useState(undefined);
  const { exportAppliedFilters } = useAppliedFilterContext();

  const onShareClick = async () => {
    setIsBookmarkInProgress(true);
    let { id, IsError } = await saveFiltersToBookmark({
      mode,
      filters: exportAppliedFilters(datasetID, store?.profile?.email, types, queryParams),
      bookmarkID: `${bookmarkID}`,
    });

    if (!bookmarkID) setBookmarkID(id);

    if (IsError || !id) {
      message.error(t('Share_Failed'));
      return;
    } else {
      const eventData = {
        activity: ACTIVITIES.SHARE,
        userid: store?.profile?.email || '',
      };
      if (mode.toLowerCase() === 'single') {
        eventData['datasetid_1'] = datasetID.toString();
      } else {
        eventData['datasetid_1'] = datasetID[0].toString();
        if (datasetID.length > 1) eventData.datasetid_2 = datasetID[1]?.toString();
        if (datasetID.length > 2) eventData.datasetid_3 = datasetID[2]?.toString();
      }
      postClickStreamEvent(eventData);
    }

    let curWinLocation = '';
    const query = new URLSearchParams(`?${queryParams}`);
    let params = {};
    query.set('filter_id', id);
    for (const [key, value] of query.entries()) {
      params[key] = value;
    }

    if ([MERGE_MODES.CUSTOMIZE_FILTER.toLowerCase(), MERGE_MODES.QUICK_MERGE.toLowerCase(), 'merge'].includes(mode)) {
      curWinLocation = getUrl(`${RoutePaths.MERGE}`, { ...params, dataset: datasetID });
    } else if (mode === 'single') {
      curWinLocation = getUrl(`${RoutePaths.DATASET}/${datasetID}`, params);
    } else {
      if (category === 'share') {
        curWinLocation = getUrl();
      } else {
        curWinLocation = getUrl('/reports/' + id);
      }
    }

    let content = t('Share_Content', { datasetName });
    setContent(content);
    setUrl(curWinLocation);
    setIsBookmarkInProgress(false);
  };

  const onCopyLink = () => {
    if (url) {
      if (type === COLLECTION) {
        const collectionUrl = getUrl();
        navigator.clipboard.writeText(collectionUrl);
        message.success(t('CopiedSuccessfully'));
      } else {
        navigator.clipboard.writeText(url);
        message.success(t('CopiedSuccessfully'));
      }
    } else {
      message.error(t('CopyError'));
    }
  };

  const shareMenu = (
    <Menu role="menu">
      <Menu.Item key="facebook" disabled={isBookmarkInProgress} role="menuitem" aria-label={t('Share_Facebook')}>
        <FacebookShareButton url={url} quote={content}>
          <FacebookOutlined /> {t('Share_Facebook')}
        </FacebookShareButton>
      </Menu.Item>
      <Menu.Item key="twitter" disabled={isBookmarkInProgress} role="menuitem" aria-label={t('Share_Twitter')}>
        <TwitterShareButton url={url} title={content}>
          <TwitterOutlined /> {t('Share_Twitter')}
        </TwitterShareButton>
      </Menu.Item>
      <Menu.Item key="copyLink" onClick={onCopyLink} role="menuitem" aria-label={t('CopyLink')}>
        <LinkOutlined className="mr-1" />
        {t('CopyLink')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown role="button" aria-label={t('Share')} trigger={['click']} overlay={shareMenu}>
        <FatLanguageButton
          text={t('Share')}
          tooltip={t('shareToolTip', { shareType: `$t(${type})` })}
          type="link"
          icon={<CustomIcon type="Share" width={15} height={14} verticalAlign={0} />}
          onClick={onShareClick}
          className="font-16 font-weight-semi-bold joyride-dataset-step4 joyride-MergeResultData-Step1"
        />
      </Dropdown>
    </div>
  );
};

export default Share;
