import { QuestionCircleTwoTone } from '@ant-design/icons';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { Modal, Tooltip } from '../../../components';
import AccessibilityButton from '../../Buttons/AccessibiltyButton';
// import { cleanName } from '../../../utils/common';

const MetaLongDescription = ({ description = '', name = '' }) => {
  const [showLg, setShowLg] = useState();
  const closeRef = useRef(null);
  // WIP: due to some some issue commented out xhanged to below
  // if (cleanName(description) === cleanName(name) || !description) return null;
  if (description === name || !description) return null;

  return (
    <>
      <Tooltip title={t('ClickToKnowMore')} trigger="hover">
        <QuestionCircleTwoTone className="ml-1 -left text-primary" onClick={() => setShowLg(true)} />
      </Tooltip>
      <Modal
        role="dialog"
        aria-labelledby={null}
        aria-modal="true"
        visible={showLg}
        maskClosable={false}
        onCancel={() => setShowLg(false)}
        title={null}
        closable={false}
        footer={[
          <AccessibilityButton
            type="primary"
            onClick={() => setShowLg(false)}
            className="rounded"
            onKeyDown={(e) => {
              if (e.key === 'Enter') setShowLg(false);
            }}
            handleSpanClick={() => closeRef.current && closeRef.current.click()}
            aria-label={t('Close')}
            ref={closeRef}
          >
            {t('Close')}
          </AccessibilityButton>,
        ]}
      >
        <div className="text-left">{description}</div>
      </Modal>
    </>
  );
};

export default MetaLongDescription;
