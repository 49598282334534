import { t } from 'i18next';
import { useState, useEffect } from 'react';
import { Typography, Popover, Button, Tag } from '../../components';
import CustomIcon from '../CustomIcon';

const { Text } = Typography;
const YearsFilteredCount = ({ selectMode, selected, dataIndex, showUpto = 3, iconType = 'Clock' }) => {
  const [andMore, setAndMore] = useState(() => (selected?.length > showUpto ? selected?.length - showUpto : 0));

  useEffect(() => {
    const len = selected?.length - showUpto;
    if (len > 0) setAndMore(len);
    else setAndMore(0);

    // eslint-disable-next-line
  }, [selectMode, selected]);

  if (!selected?.length) return null;

  return (
    <Text ellipsis className="font-12">
      {selectMode && <span className="mr-1 font-14">{t('YearsFiltered')}</span>}
      {selectMode && selected?.slice(0, showUpto)?.map((item) => <Tag key={item.ID}>{item.Name}</Tag>)}
      {(andMore && (
        <Popover
          title={
            <>
              <CustomIcon type={iconType} verticalAlign={3} className="mr-1 ml-n2" />
              {t('Time')}
            </>
          }
          placement="bottom"
          content={
            <div style={{ maxHeight: 100, overflow: 'auto' }}>
              {selected?.slice(showUpto)?.map((item, i) => (
                <div key={`${i}`} className="mb-1 font-12">
                  {item[dataIndex]}
                </div>
              ))}
            </div>
          }
        >
          <Button araia-label={` & ${andMore} more`} role="button" type="link" className="font-12 p-0 ml-1">{` & ${andMore} more`}</Button>
        </Popover>
      )) ||
        null}
    </Text>
  );
};

export default YearsFilteredCount;
