import React, { useContext } from 'react';
import { t } from 'i18next';
import { Typography, Row, Col, FatLanguageText } from '../../components';
import CustomIcon from '../../shared/CustomIcon';
import { ScreenSize } from '../../constants';
import Context from '../../context';
import CatalogueNdapHeaderRadio from './CatalogueNdapHeaderRadio';

const { Title, Text } = Typography;

export const CatalogueNdapHeader = ({ metaData = {}, onChange = () => {}, appliedFilters = {} }) => {
  const [context] = useContext(Context);
  const onRadioChange = (value) => {
    onChange(value);
  };
  return (
    <Row>
      {context.screenContext === ScreenSize.Desktop && (
        <Col xl={5} className='cat-head-left-print'>
          <CustomIcon type={'DataCatalogue'} width={272} height={136} className="ml-n4" />
        </Col>
      )}
      <Col
        xs={24}
        md={24}
        xl={19}
        className={`${
          context.screenContext !== ScreenSize.Desktop ? 'pt-0 mt-0' : 'pt-4 mt-2'
        } pl-2 joyride-catalog-step1 cat-head-right-print`}
      >
        <Title level={2} className="mb-0 pb-2">
          {t('Data_Catalogue')}
        </Title>
        <Row>
          <Col md={24} xl={13}>
            <CatalogueNdapHeaderRadio onRadioChange={onRadioChange} appliedFilters={appliedFilters} />
          </Col>
          <Col md={24} xl={11}>
            <Row>
              <Col xs={24} md={6} xl={8} className="font-16">
                <div className="d-flex">
                  <CustomIcon type={'DatasetBlue'} verticalAlign={3} />
                  <Text className="font-weight-bold  mr-1 ml-1">{metaData?.noOfDatasets}</Text>
                  <FatLanguageText text={t('V_Datasets')} className="font-16" />
                </div>
              </Col>
              <Col xs={24} md={6} xl={8} className="font-16">
                <div className="d-flex">
                  <CustomIcon type={'SearchSector'} verticalAlign={3} />
                  <Text className="font-weight-bold  mr-1 ml-1">{metaData?.noOfSectors}</Text>
                  <FatLanguageText text={t('filtersSectors')} className="font-16" />
                </div>
              </Col>
              <Col xs={24} md={6} xl={8} className="font-16">
                <div className="d-flex">
                  <CustomIcon type={'MinistryBlue'} verticalAlign={2} />
                  <Text className="font-weight-bold  mr-1 ml-1">{metaData?.noOfMinistries}</Text>
                  <FatLanguageText text={t('filtersMinistries')} className="font-16" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CatalogueNdapHeader;
