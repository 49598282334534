import { useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import max from 'lodash/max';
import { t } from 'i18next';
import { ColumnInput, ColumnHeaderInput } from '../CustomInputs';
import { Table, Input, Row, Col, Spin, Typography } from '../../components';
import { textSearch, voidFunction } from '../../utils';
import { cleanName } from '../../utils/common';
import { emptyContent } from '../../shared/Empty/EmptyContent';
import AccessibilityButton from '../Buttons/AccessibiltyButton';
const { Text } = Typography;

const flushHierarchy = ({ targetDataSource, checkList, dataIndex, isCheckValue = true }) => {
  let hierarchicalKeys = Object.keys(checkList);
  let currentIndex = hierarchicalKeys.indexOf(dataIndex);
  let hierarchyLength = hierarchicalKeys.length;
  let currentID = `${dataIndex}ID`;
  let rootID = `${dataIndex}`.replace('Name', 'Code');

  if (!isCheckValue && hierarchyLength !== currentIndex + 1) {
    for (let i = currentIndex + 1; i < hierarchyLength; i++) {
      let subDataIndex = hierarchicalKeys[i];
      if (targetDataSource[currentID] === targetDataSource[`${subDataIndex}${rootID}`]) {
        checkList[subDataIndex] = false;
        let subKey = `${subDataIndex}Checked`;
        targetDataSource[subKey] = false;
      }
    }
  }
};

const allSelectedValues = (state) => {
  return sortBy(
    state
      .filter((item) => Object.values(item.checked || {}).includes(true))
      .map((item) => ({ ...item, checked: { ...item.checked } })),
    ['order']
  );
};

export const selectAllValues = ({
  dataIndex,
  selected = true,
  isCheckValue = true,
  onSelect = voidFunction,
  allow = 0,
  root,
  dataSource,
}) => {
  const key = `${dataIndex}Checked`;
  let skip = allow ? allow : 0;

  const state = dataSource.map((item) => {
    if (item[dataIndex] && (!root || (root && item.root === root))) {
      selected = (allow && skip--) || !allow ? selected : false;
      const checkList = item['checked'] || {};
      checkList[dataIndex] = selected;

      flushHierarchy({ targetDataSource: item, checkList, dataIndex, isCheckValue });
      return { ...item, [key]: selected, checked: checkList };
    } else {
      return { ...item };
    }
  });

  onSelect(allSelectedValues(state), dataIndex, state, voidFunction);
};

const TableInput = ({
  placeholder = 'Search',
  height = 400,
  width = '100%',
  onSelect = voidFunction,
  loading = false,
  columns = [],
  splitColumns = false,
  dataSource = [],
  disableTranslation = false,
  active = {},
  root = false,
  allow = false,
  resetSearch = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [seachIndex, setSearchIndex] = useState(false);
  const [focused, setFocused] = useState(false);
  const [tableHeadHeight, setTableHeadHeight] = useState(0);
  const [resettingHeight, setResttingHeight] = useState(false);
  const searchInterval = useRef(null);
  const tableHeadRefs = useRef([]);
  const data = dataSource;
  const clearRef = useRef(null);
  const setHeight = () => {
    setResttingHeight(true);
    const maxHeight = max(
      tableHeadRefs.current.map((el) => {
        let elements = el?.querySelectorAll('tr');
        if (elements)
          return sum(
            [...elements].map((el) => {
              return el.offsetHeight;
            })
          );
        return 0;
      })
    );

    if (maxHeight) {
      setTableHeadHeight(0);
      setTableHeadHeight(maxHeight);
    }

    setResttingHeight(false);
  };

  useEffect(() => {
    window.addEventListener('resize', () => setTimeout(setHeight, 1000));
    return () => {
      window.removeEventListener('resize', () => { });
    };
  }, []);

  useEffect(() => {
    if (tableHeadRefs.current.length > 1) {
      setHeight();
    }
  }, [tableHeadRefs, dataSource]);

  useEffect(() => {
    if (searchTerm) setSearchTerm('');

    // eslint-disable-next-line
  }, [resetSearch]);

  const handleSearch = (e, column = false, dynamicSearch = false) => {
    let value = cleanName(e.target.value, ' ', false);

    setSearchIndex(column);

    if (column) {
      setSearchTerm((ps) => (ps === false ? { [column]: value } : { ...ps, [column]: value }));
    } else {
      setSearchTerm(value);
    }

    if (!focused) setFocused(true);

    if (dynamicSearch) {
      active['search'] = value;
      active['searchIndex'] = column;
      active['searchReset'] = false;

      if (searchInterval.current) {
        clearTimeout(searchInterval.current);
      }

      setTimeout(() => {
        onSelect(active, column);
      }, 500);

      if (!value) {
        active['search'] = value;
        active['searchReset'] = true;
        onSelect(active, column);
      }
    }
  };

  const allowOnly = ({ state, allow, dataIndex, key }) => {
    if (allow) {
      let skip = allow - 1;
      state.forEach((item) => {
        if (item[key]) {
          if (skip) {
            --skip;
          } else {
            item[key] = false;
            item['checked'][dataIndex] = false;
          }
        }
      });
    }
  };

  const handleSelect = (item, checked, dataIndex, options = {}) => {
    let defaultOptions = { isCheckValue: true, searchKey: false };
    const { isCheckValue, searchKey } = { ...defaultOptions, ...options };
    const key = isCheckValue ? `${dataIndex}Checked` : dataIndex;
    const column = searchKey || dataIndex;

    let state = sortBy(cloneDeep(dataSource), ['order']);
    const dataTargetIndex = state.findIndex((d) => item[column] === d[column] && ((root && d.root === root) || !root));

    if (allow && checked) {
      allowOnly({ state, allow, dataIndex, key });
    }

    if (dataTargetIndex !== -1) {
      let targetDataSource = cloneDeep(state[dataTargetIndex]);
      const checkList = targetDataSource['checked'] || {};
      checkList[dataIndex] = checked;
      flushHierarchy({ targetDataSource, checkList, dataIndex, isCheckValue: checked });
      state[dataTargetIndex] = {
        ...targetDataSource,
        [key]: checked,
        checked: checkList,
      };

      if (checked) {
        const order = allSelectedValues(state).length;
        state[dataTargetIndex]['order'] = order;
      } else if (state[dataTargetIndex]['order']) {
        const order = state[dataTargetIndex]['order'];
        state = state.map((item) => {
          if (item.order >= order) {
            item.order = item.order - 1;
          }

          return item;
        });

        delete state[dataTargetIndex]['order'];
      }
    }

    setSearchTerm('');
    setSearchIndex(false);

    onSelect(allSelectedValues(state), dataIndex, state, voidFunction);
  };

  const getSelectCount = (dataIndex, updatedData = false, root = false) => {
    const key = `${dataIndex}Checked`;
    const ID = `${dataIndex}ID`;
    let list = data || [];

    if (updatedData !== false) {
      list = updatedData;
    }

    const allCounts = list.map((item) => {
      if (((item[key] && (item[ID] || item[dataIndex])) || !dataIndex) && ((root && item.root === root) || !root)) {
        return Object.values(item.checked || {}).filter((isChecked) => isChecked).length;
      }

      return 0;
    });

    return sum(allCounts);
  };

  const getSelected = (dataIndex, updatedData = false, root = false) => {
    const key = `${dataIndex}Checked`;
    const ID = `${dataIndex}ID`;
    let list = data || [];

    if (updatedData !== false) {
      list = updatedData;
    }

    if (!dataIndex) {
      return list.filter((item) => (root && item.root === root) || !root);
    }

    return list.filter((item) => item[key] && (item[ID] || item[dataIndex]) && ((root && item.root === root) || !root));
  };

  const getAllTypeValue = (dataIndex, updatedData = false, root = false) => {
    const ID = `${dataIndex}ID`;
    let items = [];
    let isIDList = data[0]?.ID;
    let list = isIDList && root === false ? uniqBy(data, 'ID') : cloneDeep(data);

    if (updatedData !== false) {
      list = updatedData;
    }

    if (root) {
      list = list.filter((item) => item.root === root);
    }

    list.forEach((item) => {
      let match = item[ID] || item[dataIndex];

      if (match) {
        items.push(match);
      }
    });

    return items;
  };

  const isInterminate = ({ dataIndex, partial = true, selectedCount = false, count = false }) => {
    const length = selectedCount !== false ? selectedCount : getSelected(dataIndex, false, root).length;
    const dataLength = allow ? allow : count !== false ? count : getAllTypeValue(dataIndex, false, root).length;

    if (partial) {
      return length > 0 && length <= dataLength;
    }

    return length > 0 && length >= dataLength;
  };

  const selectAll = (dataIndex, selected = true, isCheckValue = true) => {
    selectAllValues({
      onSelect: onSelect,
      dataIndex,
      selected,
      isCheckValue,
      root,
      allow,
      dataSource,
    });
  };

  const toggleAll = (dataIndex) => selectAll(dataIndex, !isInterminate({ dataIndex, partial: false }));

  const getColumn = ({
    title,
    dataIndex,
    key,
    render = voidFunction,
    customRender = false,
    enableCheckbox = false,
    enableRadioSelect = false,
    showNumberOfRecord = false,
    isCheckValue = true,
    searchKey = false,
    disableTranslation = false,
    showDimensionIcon = false,
    disableCounts = false,
    merge = false,
    showPlus = false,
  }) => {
    if (enableCheckbox || enableRadioSelect) {
      let selectedCount = getSelected(dataIndex, false, root).length;
      let count = getAllTypeValue(dataIndex, false, root).length;

      return {
        title: (
          <ColumnHeaderInput
            title={title}
            indeterminate={isInterminate({ dataIndex, selectedCount, count })}
            checked={isInterminate({ dataIndex, partial: false, selectedCount, count })}
            onChange={() => toggleAll(dataIndex)}
            count={selectedCount}
            clear={() => selectAll(dataIndex, false, isCheckValue)}
            numberOfRecords={count}
            showNumberOfRecord={showNumberOfRecord}
            disableTranslation={disableTranslation}
            enableRadioSelect={enableRadioSelect}
            disableCounts={disableCounts}
            isOutOfLimit={active.isOutOfLimit}
            showPlus={showPlus}
          />
        ),
        dataIndex,
        key,
        render: (name, rowData) => {
          if (rowData.search) return rowData.search;
          if (!name) return null;
          const onChange = (e) => {
            if (enableRadioSelect) {
              setSearchTerm('');
              setSearchIndex(false);
              onSelect(rowData, dataIndex);
            } else {
              handleSelect(rowData, e.target.checked, dataIndex, {
                isCheckValue,
                searchKey,
              });
            }
          };

          const isActive = active?.[dataIndex] === name;
          const rootCount = enableRadioSelect ? getSelectCount(false, false, rowData.ID) : 0;

          const input = (
            <ColumnInput
              name={name}
              checked={rowData[dataIndex + 'Checked']}
              onChange={onChange}
              enableRadioSelect={enableRadioSelect}
              active={isActive}
              count={rootCount}
              showDimensionIcon={showDimensionIcon}
              dimensionType={rowData.DimensionType}
              colorCode={merge && rowData.colorIndicator > -1 ? rowData.colorIndicator - 1 : -1}
            />
          );

          if (customRender) {
            return customRender({
              input,
              rowData,
              name,
              handleSelect,
              dataIndex,
              isCheckValue,
              searchKey,
            });
          } else {
            return (
              <>
                {input}
                {render({ name, rowData })}
              </>
            );
          }
        },
      };
    }

    return {
      title,
      dataIndex,
      key,
      render,
    };
  };

  const isKeySet = data[0]?.key || false;
  let source = [...data];

  if (root) {
    source = source.filter((item) => item.root === root);
  }

  if (!isKeySet) {
    source = source.map((d) => ({ ...d, key: d.ID }));
  }

  const properties = {
    bordered: true,
    size: 'small',
    loading,
    pagination: false,
    className: 'min-height-30vh',
    scroll: { y: height, x: width },
  };

  const tables = {
    default: () => (
      <>
        <Input.Search
          role="searchbox"
          aria-label={t(placeholder)}
          placeholder={t(placeholder)}
          onChange={handleSearch}
          className="font-14 mb-2 CommonSearch"
          disabled={loading}
          value={searchTerm}
          allowClear
          autoComplete="off"
        />
        <Table
          role="table"
          aria-label={t('TableData')}
          columns={columns.map((c) => getColumn((c && { ...c, disableTranslation }) || {}))}
          {...properties}
          dataSource={textSearch({
            data: source,
            searchTerm,
            wordSearch: false,
            searchKey: columns.map((c) => c.dataIndex),
          })}
          className="filter-table"
          locale={emptyContent}
        />
      </>
    ),
    split: () => {
      let span = Math.round(24 / columns.length);

      return (
        <Spin spinning={loading}>
          <Row className="split-tables">
            {columns.map((c, i) => {
              let dataRecord = source
                .filter((d) => d[c.dataIndex])
                .map((item, num) => ({ ...item, key: `${item.key || 'key'}-${num}` }));

              let searchWord = searchTerm?.[c.dataIndex];

              if (searchWord && !active.isOutOfLimit) {
                dataRecord = textSearch({
                  data: dataRecord,
                  searchTerm: searchWord,
                  wordSearch: true,
                  searchKey: [c.dataIndex],
                  otherDims: false,
                });
              }

              const coloumnSearchInput = (
                <Input
                  role="textbox"
                  aria-label={t('SearchColumn', { column: c.title })}
                  placeholder={t('SearchColumn', { column: c.title })}
                  onChange={(e) => handleSearch(e, c.dataIndex, active.isOutOfLimit)}
                  className="font-12 mb-0 "
                  disabled={loading}
                  allowClear
                  value={searchWord}
                  autoFocus={seachIndex === c.dataIndex && focused}
                  size="small"
                  onBlur={() => {
                    setFocused(false);
                  }}
                  autoComplete="off"
                />
              );

              const colSpan = i === columns.length - 1 && span % 2 ? span - 1 : span;

              return (
                <Col span={colSpan} key={`${i}-split`}>
                  <Table
                    role="table"
                    aria-label={t('TableData')}
                    components={{
                      header: {
                        wrapper: (e) => {
                          let selectedCount = getSelected(c.dataIndex, false, root).length;
                          let count = getAllTypeValue(c.dataIndex, false, root).length;

                          return (
                            <thead
                              className={`${e.className} height-transition`}
                              ref={(el) => (tableHeadRefs.current[i] = el)}
                              style={
                                tableHeadHeight && !resettingHeight
                                  ? {
                                    height: tableHeadHeight,
                                    minHeight: tableHeadHeight,
                                    maxHeight: tableHeadHeight,
                                  }
                                  : {}
                              }
                            >
                              {e.children}
                              <tr>
                                <th className="border-0 pt-0 h-100 d-table-cell v-align-bottom">
                                  <div className="ant-table-cell">
                                    <div>
                                      <Text type="secondary" className="font-weight-normal font-12">
                                        {(!c.enableRadioSelect && (
                                          <>
                                            {t('Selected', { count: selectedCount })}
                                            <AccessibilityButton
                                              type="link"
                                              size="small"
                                              disabled={
                                                !isInterminate({ dataIndex: c.dataIndex, selectedCount, count })
                                              }
                                              onClick={() => selectAll(c.dataIndex, false, false)}
                                              className="p-0 ml-1 text-lowercase font-12"
                                              onKeyDown={(e) => {
                                                if (e.key === 'Enter') selectAll(c.dataIndex, false, false);
                                              }}
                                              handleSpanClick={() => clearRef.current && clearRef.current.click()}
                                              ariaLabel={t('Clear')}
                                              ref={clearRef}
                                            >
                                              {t('Clear')}
                                            </AccessibilityButton>
                                          </>
                                        )) ||
                                          t('ClickToLoadValues')}
                                      </Text>
                                    </div>
                                    {coloumnSearchInput}
                                  </div>
                                </th>
                              </tr>
                            </thead>
                          );
                        },
                      },
                    }}
                    columns={[
                      getColumn({
                        ...c,
                        disableTranslation,
                        disableCounts: true,
                      }),
                    ]}
                    {...properties}
                    loading={false}
                    dataSource={dataRecord}
                    bordered={false}
                    locale={emptyContent}
                  />
                </Col>
              );
            })}
          </Row>
        </Spin>
      );
    },
  };

  return tables[splitColumns ? 'split' : 'default']();
};

export default TableInput;
