import { Row, Col } from '../../components';

export const PageContainer = ({ children, size = 1, topSpace = 4, bottomSpace = 4, background = '', ...rest }) => (
  <Row justify="center" gutter={[0, 20]} className={`${background}`}>
    <Col
      xl={24 - size - 1}
      lg={24 - size - 2}
      xs={24 - size - 1}
      sm={24 - size - 2}
      md={24 - size - 2}
      className={`pt-${topSpace} pb-${bottomSpace} ${rest.className}`}
      {...rest}
    >
      {children}
    </Col>
  </Row>
);

export const HeaderContainer = ({ children, topSpace = 0, bottomSpace = 3, ...rest }) => (
  <Row justify="center" gutter={[0, 20]}>
    <Col xl={24} lg={24} xs={24} sm={24} md={24} className={`pt-${topSpace} pb-${bottomSpace}`} {...rest}>
      {children}
    </Col>
  </Row>
);

export const LeftContainer = ({ children }) => <>{children}</>;

export const RightContainer = ({ children }) => <>{children}</>;
