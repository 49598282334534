import { t } from 'i18next';
import sortBy from 'lodash/sortBy';
import { Typography, Popover, Button } from '../../../components';
import { TYPE_LOCATION } from '../../../constants';
import { useAppliedFilterContext } from '../../../providers/FilterContextProvider';
import CustomIcon from '../../CustomIcon';

const { Text } = Typography;
const TextSelectedCount = ({ sourceID, type = TYPE_LOCATION, dataIndex, showUpto = 1, iconType = 'Location' }) => {
  const { getSelectedFilters } = useAppliedFilterContext();
  const selected = sortBy(
    getSelectedFilters({
      sourceID,
      type,
    }).filter(({ checked }) => checked?.[dataIndex]),
    ({ checked }) => (checked[dataIndex] ? 0 : 1)
  );

  let names = [];

  if (selected?.length) {
    names = selected.splice(0, showUpto).map((item) => item[dataIndex]);
  }

  const andMore = selected.length;

  if (!names.length) return null;

  return (
    <Text ellipsis className="font-12">
      <CustomIcon type={iconType} verticalAlign={3} width={14} height={14} />
      {names.join(' & ')}
      {(andMore && (
        <Popover
          title={
            <>
              <CustomIcon type={TYPE_LOCATION} verticalAlign={3} className="mr-1 ml-n2" />
              {t('Locations')}
            </>
          }
          placement="bottom"
          content={
            <div style={{ maxHeight: 100, overflow: 'auto' }}>
              {selected.map((item, i) => (
                <div key={`${i}`} className="mb-1 font-12">
                  {item[dataIndex]}
                </div>
              ))}
            </div>
          }
        >
          <Button aria-label={` & ${andMore} more`} role="button" type="link" className="font-12 p-0 ml-1">{` & ${andMore} more`}</Button>
        </Popover>
      )) ||
        null}
    </Text>
  );
};

export default TextSelectedCount;
