import { t } from 'i18next';
import { Checkbox, Typography } from '../../components';
import { voidFunction } from '../../utils';
import AccessibilityButton from '../Buttons/AccessibiltyButton';
import { useRef } from 'react';

const { Text } = Typography;
const ColumnHeaderInput = ({
  title = 'All',
  indeterminate = false,
  checked = false,
  onChange = voidFunction,
  count = 0,
  numberOfRecords = 0,
  showNumberOfRecord = false,
  clear = voidFunction,
  disableTranslation = false,
  enableRadioSelect = false,
  disableCounts = false,
  isOutOfLimit = false,
  showPlus = false,
  onClick = voidFunction,
}) => {
  const header = ((disableTranslation && title) || t(title)) + (showNumberOfRecord && ` (${numberOfRecords})`);
  const clearRef = useRef(null);
  return (
    <div className="position-relative table-title d-flex justify-content-between">
      {(enableRadioSelect && header) || (
        <div className="d-flex">
          <Checkbox
            aria-label={header}
            role="checkbox"
            className="text-uppercase pt-0"
            indeterminate={indeterminate && !checked}
            checked={checked}
            onChange={onChange}
          >
            {header}
          </Checkbox>
          {isOutOfLimit && showPlus ? (
            <span
              onClick={() => onClick(500)}
              style={{
                marginLeft: 4,
                textTransform: 'lowercase',
                fontSize: 12,
                cursor: 'pointer',
              }}
            >
              {t('UseSearch')}
            </span>
          ) : (
            ''
          )}
        </div>
      )}
      <div>
        {!disableCounts ? (
          <Text type="secondary" className="font-weight-normal font-12">
            {(!enableRadioSelect && (
              <>
                {t('Selected', { count })}
                <AccessibilityButton
                  type="link"
                  size="small"
                  disabled={!indeterminate}
                  onClick={clear}
                  className="p-0 ml-1 text-lowercase font-12"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter')
                      clear && clear();
                  }}
                  handleSpanClick={() => clearRef.current && clearRef.current.click()}
                  aria-label={t('Clear')}
                  ref={clearRef}
                >
                  {t('Clear')}
                </AccessibilityButton>
              </>
            )) ||
              t('ClickToLoadValues')}
          </Text>
        ) : null}
      </div>
    </div>
  );
};

export default ColumnHeaderInput;
