import React, { useRef } from 'react';
import { LoadingOutlined, MailOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import jwt from 'jsonwebtoken';
import * as Sentry from '@sentry/browser';
import { Modal, Form, Input, Select, message } from '../../../components';
import { CloseButton } from '../../../shared';
import { UserDesignations, InputRegex } from '../../../constants';
import { voidFunction } from '../../../utils';
import ChangePassword from '../ChangePassword';
import Context from '../../../context';
import { updatePofile } from '../../../services';
import AvatarUpload from '../../../shared/AvatarUpload';
import { Auth } from '../../../services';
import AccessibilityButton from '../../../shared/Buttons/AccessibiltyButton';
import { AriaLiveTag } from '../../../components';
const { Option } = Select;

const EditProfile = ({ show = true, width = 400, onClose = voidFunction }) => {
  const [form] = Form.useForm();
  const [showChangePassword, setChangePassword] = React.useState(false);
  const [authContext, setContext] = React.useContext(Context);
  const [isUpdating, setUpdating] = React.useState(false);
  const editProfileRef = useRef(null);
  const [statusMessage, setStatusMessage] = React.useState('');

  const handleClose = () => {
    onClose();
    setChangePassword(false);
  };

  const updateStatusMessage = (message) => {
    setStatusMessage('');
    setTimeout(() => setStatusMessage(message), 100);
  };

  const handleSubmit = async (values) => {
    try {
      setUpdating(true);
      const result = await updatePofile({
        userType: values['custom:UserType'],
        name: values['name'],
      });
      if (result.data.isError) {
        message.error(t('Profile_Error'));
      } else {
        const token = await Auth.getTokenAndRefreshOnExpire(true);

        setContext((state) => {
          const newState = {
            ...state,
            profile: jwt.decode(token),
            token: token,
          };
          return newState;
        });

        sessionStorage.setItem('token', token);
        message.success(t('Profile_updated'));
        updateStatusMessage(t('Profile_updated'));
      }
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      message.error(t('Profile_Error'));
      updateStatusMessage(t('Profile_Error'));
      Sentry.captureException(`Failed to update profile, ` + error);
    }
  };

  return (
    <>
      <div aria-live="polite" aria-atomic="true">
        {statusMessage && <p style={{ position: 'absolute', left: '-9999px' }}>{statusMessage}</p>}
      </div>
      <Modal
        role="dialog"
        aria-labelledby={t('Edit_Profile')}
        aria-modal="true"
        wrapClassName="overflow-auto"
        style={{ top: 25 }}
        title={t('Edit_Profile')}
        visible={show && !showChangePassword}
        width={width}
        footer={null}
        onCancel={handleClose}
        closeIcon={<CloseButton />}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            name: authContext?.profile?.name,
            email: authContext?.profile?.email,
            'custom:UserType': authContext?.profile['custom:UserType'],
          }}
          autoComplete="off"
          onFinish={!isUpdating && handleSubmit}
          aria-labelledby={t('Edit_Profile')}
        >
          <div className="text-center">
            <AvatarUpload />
          </div>
          <Form.Item
            name="name"
            label={t('Name')}
            required
            aria-required="true"
            rules={[
              { required: true, message: t('Input_Name') },
              { min: 6, message: t('minLength') },
              { max: 50, message: t('maxLength') },
              { pattern: InputRegex.UserName, message: t('Input_Valid_Name') },
            ]}
            className="mb-3"
          >
            <Input role="textbox" aria-label={t('Input_Name')} placeholder={t('Input_Name')} addonBefore={<UserOutlined />} size="large" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="email"
            label={t('Email')}
            rules={[
              { required: true, message: t('Input_Email') },
              { pattern: InputRegex.Email, message: t('Input_Valid_Email') },
            ]}
            className="mb-3"
          >
            <Input
              role="textbox"
              aria-label={t('Input_Email')}
              placeholder={t('Input_Email')}
              addonBefore={<MailOutlined />}
              disabled
              size="large"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            name="custom:UserType"
            label={t('User_Role')}
            rules={[{ required: true, message: t('Input_role') }]}
            required
            aria-required="true"
            className="mb-3"
          >
            <Select role="combobox" aria-label={t('User_Role')} placeholder={t('User_Role')} suffixIcon={<UserSwitchOutlined />} size="large">
              {UserDesignations.map((type) => (
                <Option role="option" aria-label={type.title} key={type.id} value={type.title}>
                  {type.title}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <AccessibilityButton
            type="link"
            htmlType="button"
            className="w-100"
            onClick={() => setChangePassword(!showChangePassword)}
            ariaLabel={t('Change_Pwd')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setChangePassword(!showChangePassword);
              }
            }}
          >
            {t('Change_Pwd')}
          </AccessibilityButton>

          {/* <Button
            type="link"
            htmlType="button"
            className="w-100"
            onClick={() => setChangePassword(!showChangePassword)}
          >
            {t('Change_Pwd')}
          </Button> */}

          <AccessibilityButton
            ariaLabel={t('SaveChanges')}
            ref={editProfileRef}
            type="primary"
            className="w-100 mt-3"
            size="large"
            handleSpanClick={() => form.submit()}
            onClick={() => form.submit()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                form.submit();
              }
            }}
          >
            {isUpdating ? <LoadingOutlined spin /> : null}
            {isUpdating ? t('SavingChanges') : t('SaveChanges')}
          </AccessibilityButton>

          {/* <Button type="primary" className="w-100 mt-3" size="large" htmlType="submit">
            {isUpdating ? <LoadingOutlined spin /> : null}
            {isUpdating ? t('SavingChanges') : t('SaveChanges')}
          </Button> */}
        </Form>
      </Modal>
      <ChangePassword
        show={showChangePassword}
        width={width}
        back={() => setChangePassword(!showChangePassword)}
        onClose={handleClose}
      />
    </>
  );
};

export default EditProfile;
