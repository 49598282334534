import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import { t } from 'i18next';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import DOMPurify from 'dompurify';
import * as Sentry from '@sentry/browser';
import debounce from 'lodash/debounce';
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';
import { SearchOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Typography, Popover, Tag, Input, message } from '../../components';
import { getSearchDefaultAutocomplete, getSearchAutocomplete, postClickStreamEvent } from '../../services';
import SearchIconType from './SearchIconType';
import { RoutePaths } from '../../routePaths';
import SearchPopupContent from './SearchPopupContent';
import Context from '../../context';
import HandleOutsideClick from '../Hoc/HandleOutsideClick';
import { CloseButton } from '../../shared';
import { ACTIVITIES, ANONYMOUS_USER, ALL_DOMAINS } from '../../constants';
import s from './index.module.less';
import { usePrevious } from '../Hooks/use-previous';
import { onlyTextAndNumbers } from '../../utils';
import CustomLink from '../Accessibility/Link';

const { Title } = Typography;
const defaultFilter = 'q,indicator,dimensions,dataset,collection';

const searchCategories = ['search', 'header', 'homenew', 'kadap', 'megdap', 'dbtdap'];

const search = async (params = {}) => {
  const { value = '', callBack = () => { }, email, search = '', isAuth = false } = params;
  if (value) {
    const params = {
      ip_query: value || '',
      ip_username: email || ANONYMOUS_USER,
      search: search.id || defaultFilter,
      isAuth: isAuth,
    };

    try {
      const res = await getSearchAutocomplete(params);
      const results = res?.data?.Records?.hits?.hits;
      if (results && results?.length) callBack(results);
      else callBack([]);
    } catch (e) {
      if (axios.isCancel(e)) return;
      Sentry.captureException(`Failed to fetch search suggestions, ` + e);
      console.error(t('SearchSuggestionsLoadingfailed'), e);
      message.error(t('FailedFetchingSearchSuggestions'));
    }
  } else {
    const params = {
      ip_username: email || ANONYMOUS_USER,
    };
    if (isAuth) {
      try {
        const res = await getSearchDefaultAutocomplete(params);
        const results = res?.data?.Records?.hits?.hits;
        if (results && results?.length) callBack(results);
        else callBack([]);
      } catch (e) {
        if (axios.isCancel(e)) return;
        Sentry.captureException(`Failed to fetch default search suggestions by username, ` + e);
        console.error(t('DefaultSearchSuggestionsLoadingfailed'), e);
        message.error(t('FailedFetchingDefaultSearchSuggestions'));
      }
    }
  }
};

const waitAndSearch = debounce(search, 500);

const Searchbar = ({
  containerClass = '',
  searchInput = '',
  onlySearch = false,
  exclude = [],
  onSelect = () => { },
  category = 'search',
  width = 40,
  height = 50,
  headerFilter = {},
  showHeader = false,
  headerFilterChange = () => { },
  handleSearchEmpty = () => { },
  handleFocus = () => { },
}) => {
  const [searchValue, setSearchValue] = useState(DOMPurify.sanitize(searchInput));
  const [searchOptions, setSearchOptions] = useState([]);
  const [filter, setFilter] = useState(headerFilter);
  const [, setInputFocus] = useState(false);
  const [clicked, setClicked] = useState(false);
  const history = useHistory();
  const [context] = useContext(Context);
  const excludeDataset = useMemo(() => exclude, [exclude]);
  const [showFilters, setShowFilters] = useState(true);
  const inputRef = useRef(null);
  const popupReference = useRef(null);
  const previousHeaderFilter = usePrevious(headerFilter);
  const [isLoading, setIsLoading] = useState(false);

  const searchPlaceHolders = {
    catalogue: t('CataloguePlaceHolder'),
    kadap: t('SearchKADAPData'),
    homenew: t('SearchNDAPData'),
    megdap: t('SearchMegDapData'),
  };

  HandleOutsideClick(popupReference, () => {
    inputRef.current.blur();
    if (document.activeElement === inputRef.current.input) {
      setClicked(true);
    } else {
      setClicked(false);
    }
  });

  useEffect(() => {
    let isMounted = true;
    const getSearchResults = async () => {
      if (searchValue) setIsLoading(true);
      else setIsLoading(false);
      const searchResults = !searchValue ? search : waitAndSearch;
      searchResults({
        isAuth: context.isAuth,
        value: searchValue,
        email: context?.profile?.email,
        search: filter,
        callBack: (data) => {
          isMounted && setSearchOptions(data);
          setIsLoading(false);
          postClickStreamEvent({
            activity: ACTIVITIES.MAIN_SEARCH,
            userid: context?.profile?.email || '',
            search_query: onlyTextAndNumbers(searchValue),
            search_results: `${data?.length || 0}`,
          });
        },
      });
    };

    getSearchResults();

    return () => {
      isMounted = false;
    };
  }, [searchValue, context?.profile?.email, context.isAuth, filter]);

  useEffect(() => {
    setSearchValue(DOMPurify.sanitize(searchInput));
    setClicked(false);
  }, [searchInput]);

  useEffect(() => {
    if (!isEqual(previousHeaderFilter, headerFilter)) {
      setFilter(headerFilter);
      if (size(headerFilter) > 0 || size(filter) > 0) {
        setShowFilters(false);
      } else {
        setShowFilters(true);
      }
    }
    //eslint-disable-next-line
  }, [headerFilter]);

  const onPressEnter = () => {
    if (searchCategories.includes(category)) {
      if (searchValue) {
        const params = new URLSearchParams({
          query: searchValue,
          search: filter?.key ? filter?.key : 'Variables,DatasetInfo',
        }).toString();
        history.push(`/search?${params}`);
      }
    } else {
      if (searchValue) onSelect(searchValue);
    }
  };

  const handleSearch = async (evt) => {
    setClicked(true);
    const value = evt.target.value;
    if (value) {
      const formatValue = DOMPurify.sanitize(value);
      if (formatValue) setSearchValue(formatValue);
      else setSearchValue(searchValue);
    } else {
      setSearchValue('');
      handleSearchEmpty('');
    }
    inputRef.current.focus();
  };

  const handleSelect = (item = {}) => {
    const { label } = item;
    setSearchValue(label);
    const formatLabel = label?.replace(/&/g, '');
    if (searchCategories.includes(category)) {
      const params = new URLSearchParams({
        query: formatLabel,
        search: filter?.key ? filter?.key : 'Variables,DatasetInfo',
      }).toString();
      history.push(`/search?${params}`);
    } else {
      onSelect(item);
    }
  };

  const onClose = () => {
    setFilter({});
    setShowFilters(true);
  };

  const closePopup = () => {
    setClicked(false);
  };

  const onInputFocus = (evt) => {
    setClicked(true);
    const value = DOMPurify.sanitize(evt.target.value);
    setInputFocus(true);
    if (value) {
      setSearchValue(value);
    } else {
      setSearchValue(null);
    }
    handleFocus(true);
  };

  const onInputBlur = () => {
    if (searchValue || size(filter) > 0) {
      setInputFocus(true);
    } else {
      setInputFocus(false);
    }
    handleFocus(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      if (searchValue || size(filter) > 0) {
        setClicked(true);
      } else {
        setClicked(false);
      }
    }
  };

  const onFilterChange = (selFilter) => {
    setFilter(selFilter);
    setInputFocus(true);
    setShowFilters(false);
    inputRef.current.focus();
    headerFilterChange(selFilter);
  };

  const filteredOptions = searchOptions?.filter((obj) => {
    return excludeDataset?.indexOf(obj?._source?.objectid) === -1;
  });

  return (
    <>
      <div id="searchbar" className={[s.searchBarPopup, containerClass].join(' ')}>
        <Popover
          getPopupContainer={() => document.getElementById('searchbar')}
          overlayClassName={`${category === 'homenew' ? s.HomenewDropdown : ''} search-popover`}
          placement="bottom"
          content={
            <div ref={popupReference}>
              <SearchPopupContent
                data={filteredOptions}
                filters={filter}
                onSelect={handleSelect}
                onFilterChange={onFilterChange}
                category={category}
                width={category === 'header' ? width + 35 : width}
                height={height}
                showFilters={showFilters}
                closePopup={closePopup}
                showHeader={showHeader}
                isLoading={isLoading}
              />
            </div>
          }
          trigger="click"
          visible={clicked}
        >
          <Input
            role="textbox"
            aria-label={searchPlaceHolders[category] || t('Searchbar_label')}
            ref={inputRef}
            style={{
              width: `${width}vw`,
            }}
            placeholder={searchPlaceHolders[category] || t('Searchbar_label')}
            prefix={
              <>
                <SearchOutlined className={s.searchIcon} size={category === 'header' ? 16 : 20} />
                {size(filter) > 0 && (
                  <Tag
                    icon={
                      <SearchIconType path={filter.id} className="mr-2 ml-2" size={category === 'header' ? 14 : 18} />
                    }
                    className={`${s.hintItem} font-24`}
                    closable
                    onClose={onClose}
                    closeIcon={<CloseButton onClick={onClose} className={s.closeBtn} />}
                  >
                    {filter.name}
                  </Tag>
                )}
              </>
            }
            className={
              category === 'header'
                ? `${s.searchInput} ${s.searchInputHeader} font-14 rounded`
                : category === 'catalogue'
                  ? `${s.searchInput} ${s.searchInputCatalogue} font-14`
                  : `${s.searchInput} font-18 joyride-home-step7 joyride-searchresults-step1`
            }
            allowClear={true}
            value={searchValue}
            onChange={handleSearch}
            onPressEnter={onPressEnter}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            onKeyDown={handleKeyDown}
            autocomplete="off"
          />
        </Popover>
      </div>
      {!onlySearch && category !== 'homenew' ? (
        <div className="mt-3">
          <Row gutter={[30, 0]}>
            {/*
            WIP
            <Col>
                <Link to={`${RoutePaths.VISUALIZE}`} className="m4-3">
                  <Title level={5} type="secondary" className={s.searchBarLink}>
                    <span className={s.searchBarLink}>
                      <LineChartOutlined className="mr-1" />
                      {t('Visualize_title_home')}
                    </span>
                  </Title>
                </Link>
              </Col> */}

            {context.domain === ALL_DOMAINS.ndap && (
              <Col>
                <CustomLink to={`${RoutePaths.TUTORIALS}`} ariaLabel={t('about', { domain: t(context.domain) })}>
                  <Title level={5} type="secondary" className={`${s.searchBarLink} joyride-home-step8`}>
                    <span className={s.searchBarLink}>
                      <VideoCameraOutlined /> {t('NDAP_works', { domain: t(context.domain) })}
                    </span>
                  </Title>
                </CustomLink>
              </Col>
            )}
          </Row>
        </div>
      ) : null}
    </>
  );
};

export default Searchbar;
