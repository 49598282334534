import { Select, Row, Col, Checkbox, Card, Input, message, Tooltip } from '../../components';
import {
  CHART_TYPE_BUBBLE,
  CHART_TYPE_COLUMN,
  CHART_TYPE_LINE,
  CHART_TYPE_PIE,
  CHART_TYPE_SCATTER,
  CHART_TYPE_TREEMAP,
  GridFixedSpan,
} from '../../constants';
import get from 'lodash/get';
import { t } from 'i18next';
import { voidFunction } from '../../utils';
import { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadImage } from 'plotly.js-strict-dist-min';
import YearsFilteredCount from './YearsFilteredCount';

const { Option } = Select;

const getAllActions = ({ initialState = false, ...params }) => ({
  zoomInOut: initialState,
  pan: initialState,
  zoom: initialState,
  box: initialState,
  lasso: initialState,
  autoSelect: initialState,
  reset: initialState,
  ...params,
});

const noActions = getAllActions({});
const optionByTypes = {
  [CHART_TYPE_COLUMN]: noActions,
  [CHART_TYPE_PIE]: noActions,
  [CHART_TYPE_BUBBLE]: noActions,
  [CHART_TYPE_LINE]: noActions,
  [CHART_TYPE_SCATTER]: noActions,
  [CHART_TYPE_TREEMAP]: noActions,
};

export const CardTitle = ({
  isPercentage = false,
  className = '',
  loadingYears = false,
  years = [],
  selectedYear = false,
  onChangeAggregateOverTime = false,
  onChangeYear = voidFunction,
  aggregateOverTime = false,
  divId = false,
  onDownload = voidFunction,
  chartTitle = 'chart',
  action = [],
  chartType = false,
  selectMode = null,
  minimum = 2,
  enableMinimumCheck = false,
  merge = false,
}) => {
  let [downloadingGraph, SetDownloadingGraph] = useState(false);
  let [previousChecked, setPreviousChecked] = useState('');
  let [aggregate, setAggregate] = useState(false);

  useEffect(() => {
    setAggregate(aggregateOverTime);
  }, [aggregateOverTime]);

  const AggregateFilter = (
    <>
      {selectMode && <YearsFilteredCount selectMode={selectMode} selected={years} dataIndex={'Name'} />}
      {!selectMode && (
        <Select
          defaultActiveFirstOption={true}
          mode={selectMode}
          value={selectedYear || get(years, '[0].Name')}
          className="mr-2"
          loading={loadingYears}
          size="small"
          onChange={(checked) => {
            if (enableMinimumCheck && Array.isArray(checked) && checked.length < minimum) {
              message.error(t('MinimumTwoYearsRequired', { minimum }));
              onChangeYear(previousChecked);
            } else {
              onChangeYear(checked);
              setPreviousChecked(checked);
            }
          }}
          disabled={aggregate}
        >
          {years.map((item) => (
            <Option value={item.ID} key={item.ID}>
              {item.Name}
            </Option>
          ))}
        </Select>
      )}
      {(onChangeAggregateOverTime && !selectMode && (
        <Checkbox aria-label={t('AggregateOverTime')} role="checkbox" checked={aggregate} onChange={onChangeAggregateOverTime}>
          {t('AggregateOverTime')}
        </Checkbox>
      )) ||
        null}
    </>
  );

  if (!optionByTypes[chartType]) {
    optionByTypes[chartType] = noActions;
  }

  return (
    <Row className={className}>
      <Col {...GridFixedSpan.size.full}>
        {(!loadingYears && !years?.length) || merge ? null : AggregateFilter}
        {isPercentage ? <Input role="textbox" aria-label={t('Percent')} addonBefore={'%'} style={{ width: 100 }} /> : null}
        <div className="float-right">
          {action}
          {divId ? (
            <span className="d-none">
              <Tooltip title="Download">
                <DownloadOutlined
                  id="download-graph"
                  disabled={downloadingGraph}
                  onClick={() => {
                    if (!downloadingGraph && document.getElementById(divId)) {
                      SetDownloadingGraph(true);
                      onDownload(true);
                      setTimeout(() => {
                        downloadImage(divId, {
                          filename: chartTitle,
                        });
                        onDownload(false);

                        SetDownloadingGraph(false);
                      }, 300);
                    }
                  }}
                />
              </Tooltip>
            </span>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

const GraphCard = (props) => (
  <div ref={props.reference}>
    <Card
      title={<CardTitle {...props} />}
      bordered={true}
      className={`border rounded mb-5 ${props.className || ''}`}
      headStyle={{ background: '#F9F9F9' }}
      bodyStyle={{ textAlign: 'center', overflowX: 'auto', ...(props.bodyStyles || {}) }}
    >
      {props.children}
      {(props.titleToFooter && <CardTitle {...props} className="mt-3 text-left" />) || null}
    </Card>
  </div>
);

export default GraphCard;
