import CustomIcon from '../shared/CustomIcon';
import { Tooltip } from './index';
import { CheckCircleFilled, ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { blue, green, red } from '@ant-design/colors';
import { t } from 'i18next';
import { useState, useContext } from 'react';
import { StandardizationDetails } from './StandarizationTag';
import Context from '../context';

export const StandardizationDatasetIcon = ({ isStandarized, iconSize = 45, standarisationSubIcon = false }) => {
  const [hover, setHover] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [context] = useContext(Context);

  return (
    <span className="position-relative">
      <CustomIcon
        type={isStandarized ? 'Dataset' : 'NonStandardDataset'}
        className="mr-1"
        style={{ marginTop: 4 }}
        width={iconSize}
        height={iconSize}
      />
      {standarisationSubIcon ? (
        <>
          <Tooltip
            color={isStandarized ? blue[4] : red[4]}
            placement={'right'}
            title={t(isStandarized ? 'Standarized' : 'NotStandarized', { domain: t(context.domain) })}
            destroyTooltipOnHide={{ keepParent: false }}
            getPopupContainer={(triggerNode) => triggerNode}
          >
            <span
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onClick={() => !isStandarized && setShowInfo(true)}
              className="font-12 position-absolute cursor-pointer bg-white rounded-circle "
              style={{
                left: 0,
                bottom: -2,
                color: isStandarized ? green[4] : red[4],
                lineHeight: 0,
              }}
            >
              {hover && !isStandarized ? (
                <QuestionCircleFilled
                  style={{ color: isStandarized ? blue[4] : red[4] }}
                  className="animate__animated animate__flipInY"
                />
              ) : !isStandarized ? (
                <ExclamationCircleFilled className="animate__animated animate__flipInY" />
              ) : (
                <CheckCircleFilled className="animate__animated animate__flipInY" />
              )}
            </span>
          </Tooltip>
          {!isStandarized ? <StandardizationDetails visible={showInfo} onClose={() => setShowInfo(false)} /> : null}
        </>
      ) : null}
    </span>
  );
};
