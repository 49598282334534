import React from 'react';
import { Condition } from '../../components';
import s from './index.module.less';
import { t } from 'i18next';

const PageTitleNew = ({ title, show = true, size=" ", children }) => {
  return (
    <Condition show={show}>
    <div className={s.pageTitle} size={size}>
      <Condition show={title}>
        <h1>{t(title)}</h1>
      </Condition>
      <Condition show={!title}>
        {children}
      </Condition>
    </div>
    </Condition>
  );
};

export default PageTitleNew;
