import React, { useContext } from 'react';
import { t } from 'i18next';
import { Row, Col } from 'antd';
import Icon from '@ant-design/icons';
import sortBy from 'lodash/sortBy';
import { Typography, Empty } from '../../components';
import Context from '../../context';
import { ScreenSize } from '../../constants';
import { sectorList } from './sectorConstants';
import { RoutePaths } from '../../routePaths';
// import { Link } from 'react-router-dom';
import CustomLink from '../Accessibility/Link';
import { formatTitle } from "../../utils";
import s from './index.module.less';

const { Text } = Typography;
export const getIcon = (sectorName, iconType = '') => {
  return (
    // sectorList.find((x) => x.title === sectorName || (iconType && new RegExp(x.iconType, 'gi').test(iconType)))
    //   ?.iconName || sectorList.find((x) => x.title === 'Default')?.iconName
    sectorList.find((x) => x.title === sectorName)?.iconName || sectorList.find((x) => x.title === 'Default')?.iconName
  );
};

const SectorList = ({ data = [], category = '' }) => {
  const [context, ,] = useContext(Context);
  const records = sortBy(data, ['name']);

  const chunkData = (dataToChunk) => {
    let chunked = [];
    let temporary;
    let chunk = 5;
    if (context.screenContext === 'mobile') chunk = 2;
    if (context.screenContext === 'tablet') chunk = 4;

    for (let i = 0, j = dataToChunk.length; i < j; i += chunk) {
      temporary = dataToChunk.slice(i, i + chunk);
      chunked.push(temporary);
    }
    return chunked;
  };

  const dataChunked = chunkData(records);

  return (
    <>
      {!dataChunked?.length && <Empty description={t('NoData')} />}
      <Row
        align="middle"
        gutter={category === 'homenew' ? [0, 0] : [20, 0]}
        className={`${category === 'homenew' ? s.homenew : ''} ${context.screenContext === ScreenSize.Mobile ? s.homenewMobile : ''
          } ${context.screenContext === ScreenSize.Tablet ? s.homenewTablet : ''} position-relative`}
      >
        {dataChunked?.length > 0 &&
          dataChunked?.map((row, rowIndex) =>
            row?.map(
              (record, index) =>
                record.name && (
                  <Col
                    key={`${rowIndex}-${index}-${record.id}`}
                    className={[
                      s.sectorItem,
                      index === row.length - 1 ? s.sectorItemColBorder : '',
                      rowIndex !== dataChunked.length - 1 ? s.sectorItemRowBorder : '',
                      `${category === 'homenew'
                        ? context.screenContext === ScreenSize.Mobile
                          ? 'px-4 py-0'
                          : 'px-4 py-3'
                        : ''
                      } ${context.screenContext === ScreenSize.Mobile ? 'py-0' : ''} border-right border-bottom sec-li-print`,
                    ]}
                    xs={24}
                    md={8}
                    lg={6}
                    xl={4}
                  >
                    <CustomLink to={`${RoutePaths.CATALOGUE}?sectors=${record.id}`} ariaLabel={record.name}>
                      <Row
                        className={[
                          context.screenContext !== 'mobile' || record?.name?.length < 20 ? s.innerRow : '',
                          s.sectorContent,
                        ]}
                        gutter={8}
                        align="middle"
                        justify="center"
                      >
                        <Col xs={4} md={7} xl={7}>
                          <Icon
                            height={40}
                            width={40}
                            className={s.sectorIcon}
                            component={getIcon(record.name, record.iconName)}
                          />
                        </Col>
                        <Col xs={20} md={17} xl={17}>
                          <h4 className="font-15">
                              <Text className={s.sectorTitle}>{formatTitle(record.name)}</Text>
                            {/* <Text className={s.sectorTitle}>{t(formatTitle(record.name)) || record.name}</Text> */}
                          </h4>
                        </Col>
                      </Row>
                    </CustomLink>
                  </Col>
                )
            )
          )}
        <div className={`${s.borderRight} newBorder`}></div>
        <div className={`${s.borderBottom} newBorder`}></div>
      </Row>
    </>
  );
};

export default SectorList;
