import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import * as config from './config';
import { BrowserTracing } from '@sentry/tracing';
import { lazyWithRetry } from './customReactLazy';
import PageLoader from './shared/PageLoader';
import './index.css';
import './print.css';
import './App.less';

const App = lazyWithRetry(() => import('./App'));

if (config.NODE_ENV === 'production') {
  Sentry.init({
    dsn: config.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}

(() => {
  /* eslint-disable */
  if (!sessionStorage.length) {
    // Ask other tabs for session storage
    localStorage.setItem('getSessionStorage', Date.now());
  }

  window.addEventListener('storage', (event) => {
    //console.log('storage event', event);

    if (event.key == 'getSessionStorage') {
      // Some tab asked for the sessionStorage -> send it

      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
      // sessionStorage is empty -> fill it

      var data = JSON.parse(event.newValue),
        value;

      for (const key in data) {
        sessionStorage.setItem(key, data[key]);
      }
    }
  });
  /* eslint-enable */
})();

if (config.NODE_ENV === 'development') require('dotenv').config();
ReactDOM.render(
  <Router>
    <Suspense fallback={<PageLoader />}>
      <App />
    </Suspense>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (config.NODE_ENV === 'development') reportWebVitals(console.log);
