import React from 'react';
import { Helmet } from 'react-helmet';
import { state } from "../../context";

const Meta = ({ title, description, keywords }) => {
  const language = localStorage.getItem('i18nextLng') || 'en';

  return (
    <Helmet>
      <html lang={language} />
      <title>{`${title} - ${state.domain ? state.domain?.toUpperCase() : 'NDAP'}`}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default Meta;
