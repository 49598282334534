import { ReactComponent as IconAgriCuluture } from '../../assets/svg/ic-agriculture-active.svg';
import { ReactComponent as IconDefault } from '../../assets/svg/iconDefault.svg';
import { ReactComponent as IconFinance } from '../../assets/svg/ic-finance-active.svg';
import { ReactComponent as IconHealth } from '../../assets/svg/ic-health-active.svg';
import { ReactComponent as IconIndustries } from '../../assets/svg/ic-industries-active.svg';
import { ReactComponent as IconPower } from '../../assets/svg/ic-power-active.svg';
import { ReactComponent as IconScience } from '../../assets/svg/ic-science-active.svg';
import { ReactComponent as InternalExternalAffairs } from '../../assets/svg/ic-internalexternal-active.svg';
import { ReactComponent as SocioEconomic } from '../../assets/svg/ic-economy-active.svg';
import { ReactComponent as WaterResources } from '../../assets/svg/waterResources.svg';
import { ReactComponent as IconEducation } from '../../assets/svg/iconsBarChart.svg';
import { ReactComponent as IconHousing } from '../../assets/svg/ic-housing-active.svg';
import { ReactComponent as IconConsumerAffairs } from '../../assets/svg/ic-consumer-active.svg';
import { ReactComponent as IconCommunication } from '../../assets/svg/ic-communication.svg';
import { ReactComponent as IconCultureTourism } from '../../assets/svg/ic-tourism.svg';
import { ReactComponent as IconTransport } from '../../assets/svg/ic-transport.svg';
import { ReactComponent as IconHumanResource } from '../../assets/svg/ic-human-active.svg';
import {ReactComponent as IconCoalMine } from '../../assets/svg/CoalMines.svg';
import {ReactComponent as IconDefence } from '../../assets/svg/ic-defence.svg';
import {ReactComponent as IconEmployment } from '../../assets/svg/ic-employment.svg';
import {ReactComponent as IconEnvNatural } from '../../assets/svg/ic-envnatural.svg';
import {ReactComponent as IconGovernance } from '../../assets/svg/governance.svg';
import {ReactComponent as IconHomeAffairs} from '../../assets/svg/ic-home-affairs.svg';
import {ReactComponent as IconBroadcasting} from '../../assets/svg/ic-broadcasting.svg';
import {ReactComponent as IconLaw} from '../../assets/svg/ic-law-justice.svg';
import {ReactComponent as IconPetrol} from '../../assets/svg/ic-petroleum.svg';
import {ReactComponent as IconRuralDev} from '../../assets/svg/ic-rural.svg';
import {ReactComponent as IconYouthSports} from '../../assets/svg/ic-sports.svg';
import {ReactComponent as IconSustainDev} from '../../assets/svg/ic-sustain.svg';
import {ReactComponent as IconAnimalHus} from '../../assets/svg/ic-animal-husbandry.svg';
import {ReactComponent as IconArtCul} from '../../assets/svg/ic-artcul.svg';
import {ReactComponent as IconSocialJustice} from '../../assets/svg/ic-justice.svg';
import {ReactComponent as IconChemicals} from '../../assets/svg/ic-chemicals.svg';

export const sectorList = [
  {
    title: 'Agriculture and Cooperation',
    iconType: 'agriculture',
    iconName: IconAgriCuluture,
  },
  {
    title: 'Education and Training',
    iconType: 'education',
    iconName: IconEducation,
  },
  {
    title: 'Finance, Banking & Insurance',
    iconType: 'finance',
    iconName: IconFinance,
  },
  {
    title: 'Health and Family Welfare',
    iconType: 'health',
    iconName: IconHealth,
  },
  {
    title: 'Commerce and Industry',
    iconType: 'industries',
    iconName: IconIndustries,
  },
  {
    title: 'Internal & External Affairs',
    iconType: 'commerce',
    iconName: InternalExternalAffairs,
  },
  {
    title: 'Energy and Power',
    iconType: 'power',
    iconName: IconPower,
  },
  {
    title: 'Human Resources Development',
    iconType: 'humanresource',
    iconName: IconHumanResource,
  },
  {
    title: 'Science, Technology & Research',
    iconType: 'Science',
    iconName: IconScience,
  },
  {
    title: 'Socio-Economic development',
    iconType: 'economy',
    iconName: SocioEconomic,
  },
  {
    title: 'Water Resources Management',
    iconType: 'water',
    iconName: WaterResources,
  },
  {
    title: 'Housing and Urban Development',
    iconType: 'housing',
    iconName: IconHousing,
  },
  {
    title: 'Food & Public Distribution',
    iconType: 'consumer',
    iconName: IconConsumerAffairs,
  },
  {
    title: 'Tourism',
    iconType: 'tourism',
    iconName: IconCultureTourism,
  },
  {
    title: 'Communications',
    iconType: 'communications',
    iconName: IconCommunication,
  },
 
  {
    title: 'Coal & Mine',
    iconType: 'coalmines',
    iconName: IconCoalMine,
  },
  {
    title: 'Communications and Information Technology\n',
    iconType: 'communicationsandtech',
    iconName: IconCommunication,
  },
  {
    title: 'Defence',
    iconType: 'defence',
    iconName: IconDefence,
  },
  {
    title: 'Employment & Labour',
    iconType: 'employment',
    iconName: IconEmployment,
  },
  {
    title: 'Environment & Natural Resources',
    iconType: 'environmentnatural',
    iconName: IconEnvNatural,
  },
  {
    title: 'Governance & Administration',
    iconType: 'governanceadministration',
    iconName: IconGovernance,
  },
  {
    title: 'Home affairs & National Security',
    iconType: 'homeaffairs',
    iconName: IconHomeAffairs,
  },
  {
    title: 'Information & Broadcasting',
    iconType: 'infobroadcasting',
    iconName: IconBroadcasting,
  },
  {
    title: 'Law & Justice',
    iconType: 'lawjustice',
    iconName: IconLaw,
  },
  {
    title: 'Petroleum, Oil & Natural Gas',
    iconType: 'petroleum',
    iconName: IconPetrol,
  },
  {
    title: 'Rural Development & Panchayati Raj',
    iconType: 'ruraldev',
    iconName: IconRuralDev,
  },
  {
    title: 'Sustainable Development Goals',
    iconType: 'sustain',
    iconName: IconSustainDev,
  },
  {
    title: 'Youth Affairs & Sports',
    iconType: 'youthsports',
    iconName: IconYouthSports,
  },
  {
    title: 'Art and Culture',
    iconType: 'artculture',
    iconName: IconArtCul,
  },
  {
    title: 'Social Justice & Empowerment',
    iconType: 'socialjustice',
    iconName: IconSocialJustice,
  },
  {
    title: 'Transport and Infrastructure',
    iconType: 'transportinfra',
    iconName: IconTransport,
  },
  {
    title: 'Default',
    iconType: 'default',
    iconName: IconDefault,
  },
  {
    title: 'Animal Husbandary and Fishing',
    iconType: 'animalhusbandry',
    iconName: IconAnimalHus,
  },
  {
    title: 'Chemicals & Fertilizers',
    iconType: 'chemicalFertilizers',
    iconName: IconChemicals,
  },
];
