import React, { useRef, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { t } from 'i18next';
// import { Button } from '../../components';
import { exportToCsv } from '../../utils';
import DownloadModal from '../DownloadModal';
import { cleanName } from '../../utils/common';
import AccessibilityButton from '../Buttons/AccessibiltyButton';

const ExportTableToCSVButton = ({
  rows = [],
  columns = [],
  fileName = `ndap-data-table-${new Date().toDateString()}`,
}) => {
  const [isDownloadModal, setIsDownloadModal] = useState(false);
  const [fileObj, setFileObj ] = useState({});
  const exportToCsvRef = useRef(null);
  const onExport = (skip) => {
    let header = [];
    let cols = columns.map((item) => {
      header.push(item.dowloadTitle || (typeof item.title === 'function' ? item.title() : item.title));
      return item.dataIndex;
    });

    let dataRows = [header];

    rows.forEach((item) => {
      let row = [];
      cols.forEach((key) => {
        if (key === 'State') row.push(item[key + 'Label'] ? item[key + 'Label'] : item[key]);
        else row.push(item[key] || '');
      });

      dataRows.push(row);
    });

    return exportToCsv(cleanName(fileName, '-', true).replace(/--+/gi, '-'), dataRows, skip);
  };

  const openDownloadModal = async () => {
    localStorage.setItem('Pivot', false);
    setIsDownloadModal(true);
    const file = await onExport(false);
    setFileObj({ name: cleanName(fileName, '-', true)?.replace(/--+/gi, '-'), format: 'CSV', size: file, path: '' });
  }

  return (
    <div className="text-right position-relative">
      <DownloadModal show={isDownloadModal} file={fileObj} download={onExport} onCancel={(val) => setIsDownloadModal(val)} />
      <AccessibilityButton onClick={openDownloadModal} icon={<DownloadOutlined />} type="link" size="small" className="fon-12"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            openDownloadModal && openDownloadModal();
          }
        }}
        handleSpanClick={() => exportToCsvRef.current && exportToCsvRef.current.click()}
        ariaLabel={t('ExportToCsv')}
        ref={exportToCsvRef}
        >
        {t('ExportToCsv')}
      </AccessibilityButton>
    </div>
  );
};

export default ExportTableToCSVButton;
