import React from 'react';
import { Input } from '../../components';
import { textSearch } from '../../utils/common';

const ListWithSearch = (
  WrapperComponent,
  { search = '', placeholder = '', data = [], searchKey, emptySearchValue = [], ...rest }
) => {
  const [searchTerm, setSearchTerm] = React.useState(search);
  const handleChange = (e) => {
    setSearchTerm(e.target.value || '');
  };

  return () => (
    <>
      {data?.length ? (
        <Input
          role="textbox"
          aria-label={placeholder}
          placeholder={placeholder}
          onChange={handleChange}
          value={searchTerm}
          allowClear={true}
          autoComplete="off"
        />
      ) : null}

      <WrapperComponent
        data={textSearch({ data, searchTerm, searchKey, emptySearchValue })}
        searchTerm={searchTerm}
        resetSearch={() => setSearchTerm('')}
        {...rest}
      />
    </>
  );
};

export default ListWithSearch;
