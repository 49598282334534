import { Timeline, Typography } from '../../../components';
import { cleanName } from '../../../utils/common';
import { PROCESSING_TYPE } from '../../../constants';
import { FindHeader } from './StarProcessing';
import { t } from 'i18next';
import CustomIcon from '../../CustomIcon';
import Scrollspy from 'react-scrollspy';

const StarMenu = ({ data }) => {
  const headers = PROCESSING_TYPE.filter((type) => {
    const keys = Object.keys(data?.[type] || {});
    return keys.length ? true : false;
  }).map((type) => {
    const header = FindHeader(data[type], t('StarReport'));
    return header;
  });

  return (
    <Scrollspy
      className="ant-timeline timeline-with-scrollspy"
      items={headers.map((header) => cleanName(header))}
      currentClassName="is-current"
    >
      {headers.map((header) => (
        <Timeline.Item
          key={header}
          style={{ minHeight: 60 }}
          className="timeline-dotted cursor-pointer text-uppercase"
          dot={<CustomIcon type={'StarFilled'} className="star" width="24" height="24" />}
        >
          <a href={`#${cleanName(header)}`} role="link">
            <Typography.Text type="primary" className="font-16 font-weight-semi-bold">
              {header.replace(' Star: ', '. ')}
            </Typography.Text>
          </a>
        </Timeline.Item>
      ))}
    </Scrollspy>
  );
};

export default StarMenu;
