import { useRef } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import DOMPurify from 'dompurify';
import { Tooltip, Typography, Card, Row, Col, Popconfirm } from '../../../components';
import mergeStyles from './index.module.less';
import {
  RULE_ONLY,
  RULE_OTHER_THAN,
  RULE_RESTRICTED_UPTO,
  STANDERED_FUNCTIONS,
  WEIGHTED_AVG,
} from '../../../constants';
import { useFilterActions } from '../../../providers/FilterContextProvider';
import { voidFunction } from '../../../utils';
import CustomIcon from '../../CustomIcon';
import uniq from 'lodash/uniq';
import AccessibilityButton from '../../Buttons/AccessibiltyButton';

const REASON_MESSAGE = {
  [RULE_RESTRICTED_UPTO]: (value, title, type) => {
    const rules = { 1: 'single', 2: 'two', 3: 'three', 4: 'four' };
    return `${rules[value]} ${type} (${title})`;
  },
  [RULE_OTHER_THAN]: (value, title, type) => `other then ${value} ${type} (${title})`,
  [RULE_ONLY]: (value, title, type) => `only ${value} ${type} (${title})`,
};

const NotIncludedTitle = ({ onRemove = voidFunction }) => {
  return (
    <Row className="font-16 mb-2 mt-1">
      <Col span={20}>
        <CustomIcon type={'ExclamationTriangle'} className="mr-1" />
        {t('NotIncluded')}
      </Col>
      <Col span={4} className="text-right">
        <Popconfirm
          onConfirm={onRemove}
          okText="Yes"
          cancelText="No"
          placement="bottomRight"
          title={<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('DeleteConfirmation')) }} />}
        >
          <CloseOutlined />
        </Popconfirm>
      </Col>
    </Row>
  );
};

const ExtraFilters = ({ sourceID, onSelectChartType = voidFunction }) => {
  const addReplaceRef = useRef(null);
  const fromNameRef = useRef(null);
  const { getExtras, removeExtras, addOrReplaceExtras, getSelectedCount } = useFilterActions({ sourceID });
  const { groupedBy = {}, from = false } = getExtras();
  let entries = Object.entries(groupedBy);

  if (!entries.length) return null;
  let rows = [];
  let reasons = [];

  for (let [key, types] of entries) {
    let typeEntries = Object.entries(types);
    for (let [title, dataItems] of typeEntries) {
      rows = [
        ...rows,
        ...dataItems
          .filter((i) => i.reason)
          .map((item) => {
            reasons.push(REASON_MESSAGE[item.reason]?.(item.reasonValue, title, key.substring(0, key.length - 1)));
            let name = item.AggregationFunction
              ? `${STANDERED_FUNCTIONS[item.AggregationFunction] || WEIGHTED_AVG[item.AggregationFunction]} of ${
                  item.DisplayName
                }`
              : item.DisplayName;

            const selectedCount = getSelectedCount({ sourceID, oneLevel: true, type: item.chartType });
            const isRestrictedUpto = item.reason === RULE_RESTRICTED_UPTO;
            let isAdd = isRestrictedUpto && selectedCount < item.reasonValue;

            return (
              <div
                key={item.ID}
                className={`${mergeStyles.visualBtnWrapper} mb-2 position-relative pr-3 pl-2 pt-2 pb-2 bg-white`}
              >
                <Row>
                  <Col span={16} className={'pt-1'}>
                    <Typography.Text ellipsis className="color-light-dark">
                      <CustomIcon type={item.DimensionType || 'ChartIndicator'} verticalAlign={3} className={`mr-1`} />
                      <Tooltip title={name}>
                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(name) }}></span>
                      </Tooltip>
                    </Typography.Text>
                  </Col>
                  <Col span={8} className="text-right pl-1">
                    <AccessibilityButton
                      className="p-0 m-0 font-10"
                      type="link"
                      onClick={() => {
                        addOrReplaceExtras(item, !isAdd);
                      }}
                      disabled={!isRestrictedUpto}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') addOrReplaceExtras(item, !isAdd);
                      }}
                      handleSpanClick={() => addReplaceRef.current && addReplaceRef.current.click()}
                      ariaLabel={t(`${isAdd ? 'Add' : 'Replace'}InChart`)}
                      ref={addReplaceRef}
                    >
                      {t(`${isAdd ? 'Add' : 'Replace'}InChart`)}
                    </AccessibilityButton>
                  </Col>
                </Row>
              </div>
            );
          }),
      ];
    }
  }

  if (!rows.length) return null;

  return (
    <Card
      title={false}
      className="bg-color-gray-viz-notify rounded mb-3 mt-3"
      bodyStyle={{ paddingTop: 8, paddingBottom: 8, paddingRight: 14, paddingLeft: 14 }}
    >
      <NotIncludedTitle onRemove={removeExtras} />
      <div className="font-12">
        <div> {t('VizSupport', { reasons: uniq(reasons).join(' and ') })}</div>
        {from ? (
          <div>
            {t('VizGoBack')}
            <AccessibilityButton
              type="link"
              size="small"
              className="p-0 m-0 ml-1 font-12"
              onClick={() => {
                onSelectChartType(from.type);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') 
                  onSelectChartType(from.type);
              }}
              handleSpanClick={() => fromNameRef.current && fromNameRef.current.click()}
              ariaLabel= {t(from.name)}
              ref={fromNameRef}
            >
              {t(from.name)}
            </AccessibilityButton>
          </div>
        ) : null}
      </div>

      {rows}
    </Card>
  );
};

export default ExtraFilters;
