import { t } from 'i18next';
import CustomIcon from '../../CustomIcon';
import DimensionIcon from '../../CustomIcon/DimensionIcon';
import { Tooltip, Typography } from '../../../components';
import {
  NOT_APPLICABLE,
  TYPE_INDICATORS,
  // TYPE_OTHERS,
  VARIABLE_TYPES,
  STATE_UT,
  LOCATION_GRANALURITY,
} from '../../../constants';
import { getLocaleStringByCountry } from '../../../utils';

export const renderTextData = (data, defaultText = '-') => data || defaultText;
export const renderTextWithToolTip = (data, defaultText = '-', scalingFactor = false, placement = 'topLeft') => {
  return (
    (data && (
      <Tooltip
        placement={placement}
        title={
          <>
            {data}
            {defaultText && defaultText !== '-' ? (
              <>
                <br />
                {`${t('UnitsOfMeasure')}: ${defaultText}`}
              </>
            ) : (
              ''
            )}
            <br />
            {scalingFactor && <>{`${t('ScalingFactor')}: ${scalingFactor}`}</>}
          </>
        }
      >
        {data}
      </Tooltip>
    )) ||
    defaultText
  );
};

export const ColumnHeader = ({
  text,
  icon = false,
  merge = false,
  colorCode = false,
  isPrimary = false,
  unitsofMeaseure = null,
  scalingFactor = false,
}) => {
  const lowerText = text?.toLowerCase();
  const formattedText = LOCATION_GRANALURITY.state === lowerText ? STATE_UT[lowerText] : text;
  return (
    <div className={`ml-n1`} style={{ paddingLeft: !icon ? 0 : isPrimary ? 0 : 20 }}>
      <div>
        <Typography.Paragraph ellipsis={{ rows: 3 }} className="m-0 position-relative">
          <span className="position-absolute" style={{ left: 0 }}>
            {isPrimary && (
              <CustomIcon type={'PrimaryKey'} verticalAlign={4} className={`mr-n1 ${!merge && 'color-blue'}`} />
            )}
            {icon && (
              <Tooltip placement="top" title={icon}>
                <DimensionIcon
                  type={icon}
                  width={16}
                  height={16}
                  className="mb-0 mr-1"
                  merge={merge}
                  colorCode={colorCode}
                />
              </Tooltip>
            )}
          </span>
          <div style={{ marginLeft: isPrimary && icon ? '30px' : !isPrimary && !icon ? '0px' : '20px' }}>
            {renderTextWithToolTip(formattedText, unitsofMeaseure, scalingFactor)}
          </div>
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export const getDatasetHeaderColumns = (headers = [], merge = false, width = 170, applyKeyIndentifier = true) => {
  return headers
    ?.filter((item) => !item?.ID?.includes('Code'))
    ?.map((item, index) => {
      if (typeof item === 'string') {
        return {
          title: <ColumnHeader merge={merge} text={item} />,
          dataIndex: item,
          key: `${item} ${index}`,
          render: (t, rowdata) => renderTextWithToolTip(rowdata[`${item}Label`] || t),
          width,
          display: item,
          dowloadTitle: item,
        };
      }

      const isPrimary =  item.VariableType === VARIABLE_TYPES.keyIdentifier && (item.indicator_dimension !== 'Indicator');
        // WIP - OLD: item.VariableType === VARIABLE_TYPES.keyIdentifier && (item.dimension_type || '').toLowerCase() !== TYPE_OTHERS;
      const isIndicator = item.indicator_dimension === 'Indicator' || item.icon === TYPE_INDICATORS;

      return {
        title: (
          <ColumnHeader
            merge={merge}
            text={item.DisplayName || item.name}
            icon={item.dimension_type || item.icon}
            colorCode={item.colorIndicator - 1}
            isPrimary={applyKeyIndentifier && isPrimary}
            unitsofMeaseure={item?.UnitsofMeaseure}
            scalingFactor={isIndicator ? item.ScalingFactor : false}
          />
        ),
        dataIndex: item.ID || item.name,
        ID: item.ID,
        key: `${item.ID || item.name} ${index}`,
        isIndicator,
        render: (t, rowdata) => {
          const value = rowdata[`${item.ID || item.name}Label`] || t;

          return (
            <div className={`${isIndicator ? 'text-right' : 'text-left'}`}>
              {renderTextWithToolTip(
                isIndicator && !isNaN(value)
                  ? value !== null
                    ? getLocaleStringByCountry(value, 'en-IN')
                    : ' '
                  : value,
                '-',
                false,
                isIndicator ? 'topRight' : 'topLeft'
              )}
            </div>
          );
        },
        width,
        isPrimary,
        name: item.name,
        display: item.DisplayName || item.name,
        rowAlign: isIndicator ? 'right' : 'left',
        dowloadTitle: item.DisplayName || item.name,
        scalingFactor: item.ScalingFactor,
        weightedVariable: item.Weighing_Variable !== NOT_APPLICABLE ? item.Weighing_Variable : '',
        aggregationFunction: item.default_aggregation_location,
      };
    });
};

export const DatasetPreviewCoulms = [
  // WIP: temperary hold
  // {
  //   title: t('Key'),
  //   dataIndex: 'Key',
  //   render: (primaryKey = '') =>
  //     ((primaryKey || '').toLowerCase() === 'true' && <CustomIcon type={'PrimaryKey'} />) || '-',
  //   width: 80,
  //   key: 'key',
  // },
  {
    title: t('VariableName'),
    dataIndex: 'variableName',
    key: 'variableName',
    render: renderTextData,
    width: 250,
  },
  {
    title: t('VariableType'),
    dataIndex: 'variableType',
    key: 'variableType',
    render: renderTextData,
    width: 250,
  },
  {
    title: t('Dimension'),
    dataIndex: 'keyIdentifierType',
    key: 'keyIdentifierType',
    width: 250,
    render: (t) => (
      <div>
        <DimensionIcon type={t} />
        {renderTextWithToolTip(t)}
      </div>
    ),
  },

  {
    title: t('Unique'),
    dataIndex: 'unique',
    key: 'unique',
    render: (t) => renderTextWithToolTip(t),
    width: 120,
  },

  {
    title: t('Missing'),
    dataIndex: 'missing',
    key: 'missing',
    render: (text) => renderTextData(text, 0),
    width: 120,
  },

  {
    title: t('Minimum'),
    dataIndex: 'minimum',
    key: 'minimum',
    render: (t) => renderTextWithToolTip(t),
    width: 120,
  },

  {
    title: t('Maximum'),
    dataIndex: 'maximum',
    key: 'maximum',
    render: (t) => renderTextWithToolTip(t),
    width: 120,
  },

  {
    title: t('Mean'),
    dataIndex: 'mean',
    key: 'mean',
    render: (t) => renderTextWithToolTip(t),
    width: 120,
  },
  {
    title: t('Median'),
    dataIndex: 'median',
    key: 'median',
    render: (t) => renderTextWithToolTip(t),
    width: 120,
  },
  {
    title: t('WeightVariableName'),
    dataIndex: 'weighingVariableName',
    key: 'weighingVariableName',
    render: (t) => renderTextWithToolTip(t),
    width: 300,
  },
  {
    title: t('AggregationFunction'),
    dataIndex: 'aggregationFunction',
    key: 'aggregationFunction',
    render: (t) => renderTextWithToolTip(t),
    width: 300,
  },
];
