import React, { Fragment } from 'react';
import DatasetResults from '../Hoc/DatasetResults';
import DatasetList from './index';
import { voidFunction, scrollTop } from '../../utils';
import Filters from './Filters';
import { Divider, Input } from '../../components';
import { t } from 'i18next';

const ListDataset = (props) =>
  DatasetResults((props) => {
    return (
      <Fragment>
        <Filters
          {...props.dataFilters}
          onFilterSelect={props.handleFilterChange}
          appliedFilters={props.appliedFilters}
          excludeFilters={props.excludeFilters}
          keyName={'id'}
        />
        <Divider />
        <DatasetList
          {...(props.dataList || {})}
          isLoading={false}
          onPageChange={props.setPage}
          showInformation={props.showInformation !== false}
          highlight={false}
          divider
          page={props.page}
          paginationAlign={props.paginationAlign}
          onShowSizeChange={props.onShowSizeChange}
          limit={props.limit}
        />
      </Fragment>
    );
  }, props)();

const DatasetListVisualizer = (props) => {
  const [filters, setFilters] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [search, setSearch] = React.useState('');

  const sourceID = props?.match?.params?.id;
  const allowSearch = props.allowSearch === true;
  React.useEffect(() => {
    if (sourceID) {
      setPage(1);
      setFilters({});
      scrollTop();
    }
  }, [sourceID]);

  React.useEffect(() => {
    scrollTop();
  }, [page, limit]);

  const handleFilterChange = (filter, type) => {
    setFilters((previousState) => {
      const newFilterState = {
        ...previousState,
        [type]: filter,
      };

      (props.onFilterChange || voidFunction)(filter, type);

      return newFilterState;
    });
  };

  return (
    <div className="min-vh-50">
      {allowSearch ? (
        <div className="mb-2">
          <Input.Search
            role="searchbox"
            aria-label={t('SearchDatasets')}
            className={'font-16 collectionSearch'}
            placeholder={t('SearchDatasets')}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onSearch={(value) => {
              setSearch(value);
            }}
            allowClear
            autoComplete="off"
          />
        </div>
      ) : null}

      <ListDataset
        setPage={setPage}
        page={page}
        appliedFilters={filters}
        handleFilterChange={handleFilterChange}
        setFilters={setFilters}
        onShowSizeChange={(current, size) => {
          setPage(current);
          setLimit(size);
        }}
        limit={limit}
        search={search}
        paginationAlign={props.paginationAlign}
        apiParams={props.apiParams}
        activeService={props.activeService}
        productName={props.productName}
        mode={props.mode}
        loadFromAPI={props.loadFromAPI}
        onLoading={props.onLoading}
        onDataLoaded={props.onDataLoaded}
        excludeFilters={props.excludeFilters}
      />
    </div>
  );
};

export default DatasetListVisualizer;
