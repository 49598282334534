export const AriaLiveTag = ({ statusMessage }) => {
  if (!statusMessage) {
    return null;
  }
  return (
    <div aria-live="polite" aria-atomic="true">
      {statusMessage && <p style={{ position: 'absolute', left: '-9999px' }}>{statusMessage}</p>}
    </div>
  )
};
