import { createContext } from 'react';
import jwt from 'jsonwebtoken';
import { ANONYMOUS_USER, getDomainParameters } from './constants';

const localToken = sessionStorage.getItem('token') || null;
let isAwsCreds = true;
const localKeys = Object.keys(localStorage);
if (!localKeys.find((a) => a.includes('CognitoIdentityServiceProvider'))) isAwsCreds = false;
if (!localKeys.find((a) => a.includes('refreshToken'))) isAwsCreds = false;
if (!localKeys.find((a) => a.includes('accessToken'))) isAwsCreds = false;
if (!localKeys.find((a) => a.includes('idToken'))) isAwsCreds = false;

const profile = localToken ? jwt.decode(localToken) : null;

// gets set to initial state when token expires, beware of that
export const initialState = {
  isAuth: false,
  isLoading: false,
  profile: { name: ANONYMOUS_USER },
  fontSize: 'Normal',
  screenContext: '',
  preferences: { lang: localStorage.getItem('lang') || 'en' },
  showLogin: false,
  afterLoginRedirect: false,
  subscriptions: {},
  logoutFn: () => {},
  ...getDomainParameters(),
  devices: {
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true,
    xxl: true,
  },
};

export const state = {
  ...initialState,
  isAuth: localToken && isAwsCreds ? true : false,
  isLoading: false,
  profile,
};

const Context = createContext(state);

export default Context;
