import { RightOutlined } from '@ant-design/icons';
import { Row, Col, Checkbox, Typography, Badge } from '../../components';
import { GridFixedSpan, TYPE_INDICATORS } from '../../constants';
import DimensionIcon from '../CustomIcon/DimensionIcon';

const ColumnInput = ({
  name,
  onChange,
  checked = false,
  enableRadioSelect = false,
  active = false,
  showDimensionIcon = false,
  dimensionType = '',
  colorCode = -1,
  count = 0,
}) => {
  const showDimensionType = showDimensionIcon && dimensionType;
  return (
    <Row>
      <Col {...GridFixedSpan.size.full} className={`${active && 'border-info'} position-relative`}>
        {(enableRadioSelect && (
          <Row onClick={onChange} className="cursor-pointer">
            <Col {...GridFixedSpan.size.ten} xs={20}>
              {name}
            </Col>
            <Col {...GridFixedSpan.size.two} xs={4} className="text-right">
              {active && <RightOutlined />}
              <Badge count={count} showZero={false} style={{ backgroundColor: '#d9d9d9' }} className="mt-n1" />
            </Col>
          </Row>
        )) || (
            <Row>
              <Col span={showDimensionType ? 20 : 24}>
                <Checkbox aria-label={name} role="checkbox" onChange={onChange} checked={checked} className={'w-100'}>
                  {colorCode > -1 && (
                    <DimensionIcon merge={true} colorCode={colorCode} type={TYPE_INDICATORS} verticalAlign={3} />
                  )}
                  {name}
                </Checkbox>
              </Col>
              {(showDimensionType && (
                <Col span={4} className="text-right">
                  <Typography.Text className="text-capitalize font-12" type="secondary" style={{ right: 0, top: 2 }}>
                    <DimensionIcon type={dimensionType} verticalAlign={0.3} width={15} height={15} />
                    {dimensionType}
                  </Typography.Text>
                </Col>
              )) ||
                null}
            </Row>
          )}
      </Col>
    </Row>
  );
};

export default ColumnInput;
